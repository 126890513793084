import {cap} from './Character';

export function Spells(props) {

    let spellCategoriesHTML = [];
    Object.keys(spellData).forEach(category => {
        if(props.isCaster(category)) {
            let knownSpellsHTML = [];
            props.getSpellList(category).forEach(s => {
                knownSpellsHTML.push(<div key={s} className="known-spell">
                    {props.editMode && <button onClick={() => props.removeSpell(category,s)}>-</button>}
                    {!props.editMode && <div className="button-spacer"></div>}
                    <div className="spell-name tooltip-container">{cap(s)}
                        <div className="tooltip right">{spellData[category][s].shortDescription}</div>
                    </div>
                    <div className="spell-difficulty">{spellData[category][s].difficulty}</div>
                    {props.editMode && <div className="description">{spellData[category][s].shortDescription}</div>}
                    {!props.editMode && <button className="cast" onClick={() => props.castSpell(s,category)}>Cast Standard</button>}
                    {!props.editMode && <button className="ritual" onClick={() => props.drawRitualCircle(s,false)}>Draw Ritual Circle</button>}
                    {!props.editMode && <button className="ritual" onClick={() => props.drawRitualCircle(s,true)}>Check Ritual Circle</button>}
                    {!props.editMode && <button className="ritual" onClick={() => props.castRitual(s)}>Cast Ritual</button>}
                </div>)
            });
            let newSpellsHTML = [];
            Object.keys(spellData[category]).forEach(s => {
                if(!props.spells[category][s] && props.isAligned(spellData[category][s].aligned)) {
                    newSpellsHTML.push(
                        <option key={s} value={s} title={spellData[category][s].shortDescription + " (Diff:" + spellData[category][s].difficulty + ")"}>
                            {cap(s)} ({spellData[category][s].difficulty})
                        </option>);
                }
            });
            spellCategoriesHTML.push(
                <div key={category} className="spell-category container">
                    <div className="sub-header">---{cap(category)}---</div>
                    <div className="spell-name header">Spell Name</div>
                    <div className="spell-difficulty">Diff</div>
                    {props.editMode && <div className="description header">Description</div>}
                    {knownSpellsHTML}
                    {props.editMode && <div className="newSpell"> Add: 
                        <select className="spell-name" onChange={(e) => props.addSpell(category,e.target.value)}>
                            <option value="">Choose One</option>
                            {newSpellsHTML}    
                        </select>                
                    </div>}
                </div>
            );
        } else {
            spellCategoriesHTML.push(<div key={category} className="sub-header">You cannot cast {category} spells</div>);
        }
    });

    return(
        <div className="spells container">
            {spellCategoriesHTML}
        </div>
    );
}

export const spellData = {
    basic: {
        clear: {difficulty:9,castingTime:1,aligned:"",shortDescription:"Remove TAP",duration:"instant",range:"touch",damage:0,
          finesseOptions:["distantTarget","includeTarget","excludeTarget","rapidCast"],
          powerOptions:["areaOfEffect","addedRange","increasedRecovery"]},
        light: {difficulty:9,castingTime:1,aligned:"positive",shortDescription:"Create light",duration:"minutes",range:"AQR",damage:0,
          finesseOptions:["mobileTarget","adjustableBrightness","permanentColor","adjustableColor","sunlight"],
          powerOptions:["addedRange","increasedDuration","increasedBrightness"]},
        darkness: {difficulty:10,castingTime:1,aligned:"negative",shortDescription:"Create a cloud of darkness",duration:"minutes",range:"self",damage:0,
          finesseOptions:["distantTarget","shapedArea","includeTarget","excludeTarget"],
          powerOptions:["addedRange","increasedDarkness","increasedDuration","fearfulDarkness"]},
        enhanceSenses: {difficulty:10,castingTime:1,aligned:"",shortDescription:"Enhance one sense",duration:"minutes",range:"touch",damage:0,
          finesseOptions:["distantTarget","includeTarget","excludeTarget","lowLightVision","distantSight","soundFiltering","ultrasound",
            "vibrationReading","smellStatus","trackingBySmell","identifySubstances"],
          powerOptions:["increasedDuration","areaOfEffect","addedRange","increasedPotency"]},
        locate: {difficulty:10,castingTime:1,aligned:"positive",shortDescription:"Find an object",duration:"days",range:"AQRx100",damage:0,
          finesseOptions:["subtleLink","subtleSearch"],
          powerOptions:["increasedDuration","multipliedRange"]},
        message: {difficulty:10,castingTime:1,aligned:"",shortDescription:"Send a mental or audible message",duration:"minutes",range:"AQRx100",damage:0,
          finesseOptions:["nonlivingTarget","silentSending","delayedMessage"],
          powerOptions:["multipliedRange","increasedDelay"]},
        boost: {difficulty:11,castingTime:1,aligned:"",shortDescription:"Enhance an aspect or skill",duration:"minutes",range:"touch",damage:0,
          finesseOptions:["distantTarget","includeTarget","excludeTarget","multipleTypes"],
          powerOptions:["areaOfEffect","increasedDuration","increasedBoost","addedRange"]},
        disruption: {difficulty:10,castingTime:1,aligned:"negative",shortDescription:"Counter or prevent spellcasting",duration:"instant",range:"AQR",damage:0,
          finesseOptions:["rapidCast","includeTarget","excludeTarget"],
          powerOptions:["areaOfEffect","addedRange","strongerDisruption"]},
        sound: {difficulty:11,castingTime:1,aligned:"",shortDescription:"Generate sound",duration:"sustained",range:"AQR",damage:0,
          finesseOptions:["additionalSource","mobileSource","motionlessCasting"],
          powerOptions:["increasedRange","increasedVolume"]},
        ward: {difficulty:11,castingTime:10,aligned:"",shortDescription:"Alarm or protect an area",duration:"hours",range:"AQR",damage:0,
          finesseOptions:["intelligenceFilter","abyssalFilter","empyreanFilter","triggerException","additionalAwareness","triggerLocations","simpleBoundary","subtleBoundary"],
          powerOptions:["increasedDuration","multipliedRange","disorientingTrigger"]},
    },
    advanced: {
        burn: {difficulty:12,castingTime:1,aligned:"",shortDescription:"Release a burst of heat",duration:"instant",range:"AQR",damage:8,
          finesseOptions:["flameTools","includeTarget","excludeTarget"],
          powerOptions:["areaOfEffect","addedRange","increasedDamage"]},
        disenchant: {difficulty:12,castingTime:1,aligned:"negative",shortDescription:"Dispel an enchantment",duration:"instant",range:"AQR",damage:0,
          finesseOptions:["includeTarget","excludeTarget"],
          powerOptions:["areaOfEffect","addedRange","additionalAttempt","increasedPower"]},
        preservation: {difficulty:12,castingTime:1,aligned:"positive",shortDescription:"Survive underwater or in space",duration:"minutes",range:"touch",damage:0,
          finesseOptions:["distantTarget","includeTarget","excludeTarget"],
          powerOptions:["increasedDuration","areaOfEffect","addedRange","increasedProtection"]},
        pulse: {difficulty:12,castingTime:1,aligned:"",shortDescription:"Release a burst of force",duration:"instant",range:"AQR",damage:3,
          finesseOptions:["forcePull","includeTarget","excludeTarget"],
          powerOptions:["areaOfEffect","addedRange","increasedDamage","increasedPush"]},
        disguise: {difficulty:13,castingTime:1,aligned:"",shortDescription:"Change something's appearance",duration:"minutes",range:"AQR",damage:0,
          finesseOptions:["motionlessCasting","tangibleIllusion","livingIllusion"],
          powerOptions:["increasedDuration","addedRange","increasedSize"]},
        freeze: {difficulty:13,castingTime:1,aligned:"",shortDescription:"Drain heat from a target",duration:"instant",range:"AQR",damage:3,
          finesseOptions:["chillingTouch","includeTarget","excludeTarget"],
          powerOptions:["areaOfEffect","addedRange","increasedDamage"]},
        mageGlass: {difficulty:13,castingTime:1,aligned:"",shortDescription:"Create an invisible barrier of force",duration:"minutes",range:"AQR",damage:0,
          finesseOptions:["outerQi","bubbleShape"],
          powerOptions:["addedRange","strongerBarrier","increasedRadius"]},
        poison: {difficulty:13,castingTime:1,aligned:"",shortDescription:"Poison a target",duration:"rounds",range:"AQR",damage:0,
          finesseOptions:["includeTarget","excludeTarget"],
          powerOptions:["strongerPoison","areaOfEffect","addedRange"]},
        etherChains: {difficulty:14,castingTime:1,aligned:"",shortDescription:"Bind a target",duration:"minutes",range:"AQR",damage:0,
          finesseOptions:["fasterAttack","outerQi","flexibleForm","includeTarget","excludeTarget"],
          powerOptions:["increasedStrength","areaOfEffect","addedRange"]},
        scrye: {difficulty:14,castingTime:10,aligned:"positive",shortDescription:"View a remote location",duration:"rounds",range:"AQRx100",damage:0,
          finesseOptions:["mobileViewpoint","remoteHearing","remoteSmell"],
          powerOptions:["multipliedRange","increasedDuration"]},
        shock: {difficulty:14,castingTime:1,aligned:"",shortDescription:"Release a burst of electricity",duration:"instant",range:"AQR",damage:3,
          finesseOptions:["recharge","includeTarget","excludeTarget"],
          powerOptions:["areaOfEffect","addedRange","increasedDamage"]},
        wither: {difficulty:14,castingTime:1,aligned:"negative",shortDescription:"Drain life from a target",duration:"instant",range:"AQR",damage:0,
          finesseOptions:["includeTarget","excludeTarget"],
          powerOptions:["strongerWither","areaOfEffect","addedRange"]},
        animate: {difficulty:15,castingTime:1,aligned:"",shortDescription:"Object gains phantom operator",duration:"rounds",range:"touch",damage:0,
          finesseOptions: ["distantTarget","dualWielding"],
          powerOptions: ["increasedDuration","increasedStamina","largerAnimation","addedRange"]},
        flight: {difficulty:15,castingTime:1,aligned:"",shortDescription:"Target can fly",duration:"rounds",range:"touch",damage:0,
          finesseOptions:["distantTarget","includeTarget","excludeTarget"],
          powerOptions:["increasedDuration","increasedSpeed","areaOfEffect","addedRange"]},
        knockout: {difficulty:15,castingTime:1,aligned:"",shortDescription:"Render a target unconscious",duration:"instant",range:"AQR",damage:0,
          finesseOptions:["includeTarget","excludeTarget"],
          powerOptions:["areaOfEffect","addedRange","increasedPenalty"]},
        noticeNot: {difficulty:15,castingTime:1,aligned:"negative",shortDescription:"Make a target harder to notice",duration:"minutes",range:"touch",damage:0,
          finesseOptions:["motionlessCasting","includeTarget","excludeTarget"],
          powerOptions:["increasedDuration","areaOfEffect","addedRange","increasedStealth"]},
        ghost: {difficulty:16,castingTime:1,aligned:"",shortDescription:"Become a being of pure qi",duration:"rounds",range:"self",damage:0,
          finesseOptions:["exitSense","spellcasting"],
          powerOptions:["increasedDuration","increasedSpeed"]},
        heal: {difficulty:16,castingTime:1,aligned:"positive",shortDescription:"Mend wounds",duration:"instant",range:"touch",damage:0,
          finesseOptions:["higherMaximumRank","distantTarget","substancePurge"],
          powerOptions:["addedRange","increasedHealing"]},
        memoryBlock: {difficulty:16,castingTime:10,aligned:"negative",shortDescription:"Block recent memories",duration:"hours",range:"touch",damage:0,
          finesseOptions:["discontinuousMemories","distantMemories"],
          powerOptions:["increasedDuration","additionalMemories"]},
        objectHistory: {difficulty:16,castingTime:10,aligned:"positive",shortDescription:"See visions of an object's past",duration:"hours",range:"touch",damage:0,
          finesseOptions:["additionalQuestion","additionalCasting"],
          powerOptions:["increasedAgeLimit"]}
    },
    epic: {
        glyph: {difficulty:22,castingTime:10,aligned:"",shortDescription:"Store a spell in a tattoo",duration:"days",range:"touch",damage:0,
          finesseOptions:["distantTarget","includeTarget","excludeTarget","triggerCondition","multipleConditions"],
          powerOptions:["increasedDuration","areaOfEffect","addedRange"]},
        summonAgent: {difficulty:22,castingTime:1,aligned:"",shortDescription:"Create a temporary servant",duration:"rounds",range:"AQR",damage:0,
          finesseOptions:["mentalLink","alternateWeaponry"],
          powerOptions:["increasedDuration","additionalSummons","powerfulConstructs"]},
        disintegrate: {difficulty:23,castingTime:1,aligned:"negative",shortDescription:"Reduce a target to dust",duration:"instant",range:"AQR",damage:0,
          finesseOptions:["fasterAttack"],
          powerOptions:["largerTarget","addedRange"]},
        nightmare: {difficulty:23,castingTime:1,aligned:"negative",shortDescription:"Trap a target in their own nightmares",duration:"rounds",range:"touch",damage:0,
          finesseOptions:["distantTarget","includeTarget","excludeTarget"],
          powerOptions:["increasedDuration","areaOfEffect","addedRange"]},
        controlWeather: {difficulty:24,castingTime:100,aligned:"",shortDescription:"Alter local weather",duration:"hours",range:"AQRx100",damage:0,
          finesseOptions:["delayedEffect","gradualChanges"],
          powerOptions:["increasedDuration","multipliedRange","multipliedRadius","increasedSeverity"]},
        portal: {difficulty:25,castingTime:1,aligned:"positive",shortDescription:"Open a portal to a remote location",duration:"rounds",range:"AQRx100",damage:0,
          finesseOptions:["unseenTarget"],
          powerOptions:["increasedDuration","multipliedRange","largerPortal"]},
        yearning: {difficulty:26,castingTime:100,aligned:"",shortDescription:"Focus someone on a particular task",duration:"days",range:"touch",damage:0,
          finesseOptions:["distantTarget","includeTarget","excludeTarget","multipleTasks"],
          powerOptions:["increasedDuration","areaOfEffect","addedRange","entrenchedEnchantment"]},
        transformation: {difficulty:27,castingTime:1,aligned:"",shortDescription:"Change into another creature",duration:"minutes",range:"touch",damage:0,
          finesseOptions:["includeTarget","excludeTarget","distantTarget","multipleForms"],
          powerOptions:["increasedDuration","areaOfEffect","addedRange","extendedSizeRange"]},
        mirage: {difficulty:28,castingTime:1,aligned:"",shortDescription:"Full visual and audible illusion",duration:"sustained",range:"AQR",damage:0,
          finesseOptions:["audibleIllusion","motionlessCasting","olfactoryIllusion"],
          powerOptions:["addedRange"]},
        resurrection: {difficulty:30,castingTime:100,aligned:"positive",shortDescription:"Restore life to a recently deceased character",duration:"instant",range:"touch",damage:0,
          finesseOptions:["restoreLimbs","restoreCore"],
          powerOptions:["olderCorpse"]}
    }
}

export const finesseOptions = {
  abyssalFilter: {cost: 2, multiple: false},
  additionalAwareness: {cost: 1, multiple: true},
  additionalCasting: {cost: 2, multiple: true},
  additionalQuestion: {cost: 1, multiple: true},
  additionalSource: {cost: 1, multiple: true},
  adjustableBrightness: {cost: 1, multiple: false},
  adjustableColor: {cost: 2, multiple: false},
  alternateWeaponry: {cost: 1, multiple: false},
  audibleIllusion: {cost: 2, multiple: false},
  bubbleShape: {cost: 2, multiple: false},
  chillingTouch: {cost: 1, multiple: false},
  delayedEffect: {cost: 1, multiple: true},
  delayedMessage: {cost: 3, multiple: false},
  discontinuousMemories: {cost: 2, multiple: false},
  distantMemories: {cost: 1, multiple: true},
  distantSight: {cost: 1, multiple: true},
  distantTarget: {cost: 2, multiple: false},
  dualWielding: {cost: 2, multiple: false},
  empyreanFilter: {cost: 2, multiple: false},
  excludeTarget: {cost: 1, multiple: true},
  exitSense: {cost: 1, multiple: false},
  fasterAttack: {cost: 1, multiple: true},
  flameTools: {cost: 1, multiple: false},
  flexibleForm: {cost: 2, multiple: false},
  forcePull: {cost: 1, multiple: false},
  gradualChanges: {cost: 2, multiple: false},
  higherMaximumRank: {cost: 1, multiple: true},
  identifySubstances: {cost: 2, multiple: false},
  includeTarget: {cost: 1, multiple: true},
  intelligenceFilter: {cost: 2, multiple: false},
  livingIllusion: {cost: 4, multiple: false},
  lowLightVision: {cost: 1, multiple: true},
  mentalLink: {cost: 2, multiple: false},
  mobileSource: {cost: 2, multiple: false},
  mobileTarget: {cost: 2, multiple: false},
  mobileViewpoint: {cost: 2, multiple: false},
  motionlessCasting: {cost: 2, multiple: false},
  multipleConditions: {cost: 2, multiple: false},
  multipleForms: {cost: 2, multiple: false},
  multipleTasks: {cost: 2, multiple: false},
  multipleTypes: {cost: 2, multiple: false},
  nonlivingTarget: {cost: 1, multiple: false},
  olfactoryIllusion: {cost: 2, multiple: false},
  outerQi: {cost: 1, multiple: false},
  permanentColor: {cost: 1, multiple: false},
  rapidCast: {cost: 4, multiple: true},
  recharge: {cost: 1, multiple: false},
  remoteHearing: {cost: 1, multiple: false},
  remoteSmell: {cost: 1, multiple: false},
  restoreCore: {cost: 6, multiple: false},
  restoreLimbs: {cost: 2, multiple: false},
  shapedArea: {cost: 3, multiple: false},
  silentSending: {cost: 2, multiple: false},
  simpleBoundary: {cost: 1, multiple: false},
  smellStatus: {cost: 2,  multiple: false},
  soundFiltering: {cost: 1, multiple: true},
  spellcasting: {cost: 2, multiple: false},
  substancePurge: {cost: 3, multiple: false},
  subtleBoundary: {cost: 1, multiple: true},
  subtleLink: {cost: 3, multiple: false},
  subtleSearch: {cost: 4, multiple: false},
  sunlight: {cost: 2, multiple: false},
  tangibleIllusion: {cost: 2, multiple: false},
  trackingBySmell: {cost: 3, multiple: false},
  triggerCondition: {cost: 2, multiple: false},
  triggerException: {cost: 1, multiple: true},
  triggerLocations: {cost: 2, multiple: false},
  ultrasound: {cost: 3, multiple: false},
  unseenTarget: {cost: 2, multiple: false},
  vibrationReading: {cost: 2, multiple: false}
}

export const powerOptionsMax = {
  addedRange: 5,
  additionalAttempt: 5,
  additionalMemories: 5,
  additionalSummons: 4,
  areaOfEffect: 1,
  disorientingTrigger: 5,
  entrenchedEnchantment: 5,
  extendedSizeRange: 5,
  fearfulDarkness: 5,
  increasedAgeLimit: 5,
  increasedBoost: 4,
  increasedBrightness: 5,
  increasedDamage: 5,
  increasedDarkness: 5,
  increasedDelay: 5,
  increasedDuration: 5,
  increasedHealing: 5,
  increasedPenalty: 5,
  increasedPotency: 3,
  increasedPower: 4,
  increasedProtection: 2,
  increasedPush: 5,
  increasedRadius: 5,
  increasedRecovery: 5,
  increasedSeverity: 2,
  increasedSize: 5,
  increasedSpeed: 5,
  increasedStamina: 5,
  increasedStealth: 5,
  increasedStrength: 5,
  increasedVolume: 5,
  largerAnimation: 5,
  largerPortal: 5,
  largerTarget: 5,
  multipliedRange: 5,
  multipliedRadius: 5,
  olderCorpse: 5,
  powerfulConstructs: 5,
  strongerBarrier: 5,
  strongerDisruption: 5,
  strongerPoison: 5,
  strongerWither: 4
}

export const ritualDetails = {
  animate: {difficulty: 21, time: 4, qi: 25},
  boost: {difficulty: 19, time: 4, qi: 20},
  burn: {difficulty: 16, time: 3, qi: 10},
  clear: {difficulty: 14, time: 2, qi: 5},
  controlWeather: {difficulty: 32, time: 7, qi: 80},
  darkness: {difficulty: 14, time: 2, qi: 10},
  disguise: {difficulty: 21, time: 4, qi: 20},
  disintegrate: {difficulty: 32, time: 7, qi: 50},
  disruption: {difficulty: 20, time: 4, qi: 20},
  enhanceSenses: {difficulty: 16, time: 3, qi: 10},
  etherChains: {difficulty: 18, time: 3, qi: 15},
  flight: {difficulty: 21, time: 4, qi: 50},
  freeze: {difficulty: 16, time: 3, qi: 10},
  ghost: {difficulty: 20, time: 4, qi: 20},
  glyph: {difficulty: 30, time: 6, qi: 40},
  heal: {difficulty: 21, time: 4, qi: 20},
  knockout: {difficulty: 20, time: 4, qi: 15},
  light: {difficulty: 12, time: 2, qi: 5},
  locate: {difficulty: 20, time: 4, qi: 20},
  mageGlass: {difficulty: 18, time: 3, qi: 15},
  memoryBlock: {difficulty: 22, time: 4, qi: 20},
  message: {difficulty: 16, time: 3, qi: 10},
  mirage: {difficulty: 36, time: 8, qi: 50},
  nightmare: {difficulty: 29, time: 6, qi: 40},
  noticeNot: {difficulty: 20, time: 4, qi: 20},
  objectHistory: {difficulty: 21, time: 4, qi: 20},
  poison: {difficulty: 16, time: 3, qi: 10},
  portal: {difficulty: 34, time: 8, qi: 5},
  preservation: {difficulty: 16, time: 3, qi: 15},
  pulse: {difficulty: 17, time: 3, qi: 15},
  resurrection: {difficulty: 38, time: 9, qi: 120},
  scrye: {difficulty: 20, time: 4, qi: 20},
  shock: {difficulty: 18, time: 3, qi: 10},
  sound: {difficulty: 14, time: 2, qi: 10},
  summonAgent: {difficulty: 30, time: 6, qi: 50},
  transformation: {difficulty: 36, time: 8, qi: 100},
  ward: {difficulty: 16, time: 3, qi: 10},
  wither: {difficulty: 21, time: 4, qi: 25},
  yearning: {difficulty: 34, time: 8, qi: 50}
}

export const spellDurations = ["rounds","minutes","10minutes","hours","days","weeks","months","years","decades","centuries"];
import {cap} from './Character';

export function Traits(props) {

    function canTake(type,traitName) {
        let result = true;
        let reqsSet = traitData[type][traitName].reqs;
        reqsSet.forEach(req => {
            if(req.substr(0,1) === "_") {
                let myParts = req.split(":");
                if(myParts[0] === "_aspect") {
                    result = result && props.getAspect(myParts[1]) >= Number(myParts[2]);
                } else if(myParts[0] === "_not") {
                    result = result && !props.traits[myParts[1]][myParts[2]];
                } else if(myParts[0] === "_allegiance") {
                  result = result && props.allegiance === myParts[1];
                } else if(myParts[0] === "_timeWalker") {
                  result = result && props.timeWalker;
                }
            } else {
                let hasReq = false;
                let reqOpts = req.split("_OR_");
                reqOpts.forEach(opt => {
                    hasReq = hasReq || props.traits[type][opt];
                    if(type === "epic" || type === "bestowed") {
                        hasReq = hasReq || props.traits["natural"][opt] || props.traits["qi"][opt];
                    }
                });
                result = result && hasReq;
            }
        });
        return result;
    }

    function isPrereq(type,traitName) {
        let result = false;
        Object.keys(props.traits[type]).forEach(t => {
            traitData[type][t].reqs.forEach(r => {
                if(r===traitName) result = true;
                let rList = r.split("_OR_");
                if(rList.length > 1) {
                  let numSatisfied = 0;
                  let isRequired = false;
                    rList.forEach(jr => {
                        if(props.traits[type][jr]) numSatisfied++;
                        if(jr===traitName) isRequired = true;
                    });
                  if(isRequired) result = result || numSatisfied < 2;
                }
            });
        });
        if(result) return result;
        if(type === "natural" || type === "qi") {
            Object.keys(props.traits.epic).forEach(t => {
                traitData.epic[t].reqs.forEach(r => {
                    if(r===traitName) result = true;
                });
            });
            Object.keys(props.traits.bestowed).forEach(t => {
                traitData.bestowed[t].reqs.forEach(r => {
                    if(r===traitName) result = true;
                });
            });
        }
        if(traitName === "spellShaping") result = result || props.knowsSpells("basic");
        if(traitName === "greaterSpellShaping") result = result || props.knowsSpells("advanced");
        if(traitName === "epicSpellShaping") result = result || props.knowsSpells("epic");
        if(traitName === "positiveQi" || traitName === "negativeQi") result = result || props.knowsAlignedSpells();
        return result;
    }

    let traitsHTML = [];
    traitDisplayOrder.forEach((column,idx) => {
        let colHTML = [];
        column.forEach(type => {
            let typeHTML = [];
            let newTypeHTML = [];
            props.getTraitList(type).forEach(trait => {
                typeHTML.push(<div key={trait} className="trait">
                    {props.editMode && <button onClick={() => props.removeTrait(type,trait)} disabled={isPrereq(type,trait)}>-</button>}
                    <div className="trait-name tooltip-container">
                      {cap(trait)}
                      <div className="tooltip right">{traitData[type][trait].shortDescription}</div>
                    </div>
                  </div>);
            });        
            Object.keys(traitData[type]).forEach(trait => {
                if(canTake(type,trait) && !props.traits[type][trait]) {
                    newTypeHTML.push(<option key={trait} value={trait} title={traitData[type][trait].shortDescription}>{cap(trait)}</option>);
                }
            });
            colHTML.push(
            <div key={type} className={"trait-category container " + type}>
                <div className="sub-header">---{cap(type)} Traits---</div>
                {props.editMode && <div className="sub-header">{traitTypeDescriptions[type]}</div>}
                {typeHTML}
                {props.editMode && 
                    <div className={"new-trait " + type}> Add: 
                        <select onChange={(e) => props.addTrait(type,e.target.value)}>
                            <option value="">Choose One</option>
                            {newTypeHTML}
                        </select>
                    </div>}
            </div>);
        });
        traitsHTML.push(
            <div key={idx} className={"trait-col-" + idx}>{colHTML}</div>
        );
    });


    return(
        <div className="traits container">
            {traitsHTML}
        </div>
    );
}

const traitDisplayOrder = [["natural","roleplaying"],["qi"],["bestowed","epic"]];

const traitTypeDescriptions = {
  natural: "Training Available to Anyone",
  roleplaying: "Negative Traits for Extra IP",
  qi: "Abilities Available to Some",
  bestowed: "Requires GM Permission",
  epic: "Requires GM Permission"
}

export const traitData = {
    natural: {
        ambidextrous: {reqs:[],shortDescription:"Eliminates off-hand penalty"},
        bowmaster: {reqs:[],shortDescription:"Increaed Bow/Crossbow Damage"},
        brute: {reqs:[],shortDescription:"Increases brawn-based damage"},
        defensiveDisarm: {reqs:["defensiveGrab","deftDisarm"],shortDescription:"Unlocks Defensive Disarm action"},
        defensiveGrab: {reqs:["jointLock"],shortDescription:"Unlocks Defensive Grab action"},
        defensiveThrow: {reqs:["defensiveGrab"],shortDescription:"Unlocks Defensive Throw action"},
        deftDisarm: {reqs:[],shortDescription:"Improves Disarm option"},
        dervish: {reqs:[],shortDescription:"Reduces penalties from your movement"},
        dirtyFighting: {reqs:[],shortDescription:"Improves Debilitating Attack option"},
        endurance: {reqs:[],shortDescription:"Increased Stamina and faster recovery"},
        fortitude: {reqs:[],shortDescription:"Increased Pain Capacity"},
        gangFighter: {reqs:[],shortDescription:"Reduces multi-opponent advantage"},
        greatEndurance: {reqs:["endurance"],shortDescription:"Increased Stamina and faster recovery"},
        greatPolyglot: {reqs:["polyglot"],shortDescription:"Know even more languages"},
        jointLock: {reqs:[],shortDescription:"Improves grappling"},
        knockout: {reqs:[],shortDescription:"Unlocks Knockout option"},
        martialArtist: {reqs:[],shortDescription:"Increased Unarmed Damage"},
        polyglot: {reqs:[],shortDescription:"Know more languages"},
        quickDefense: {reqs:["quickness"],shortDescription:"One free Parry per round"},
        quickDraw: {reqs:["quickness"],shortDescription:"Draw weapons faster"},
        quickReload: {reqs:["quickDraw"],shortDescription:"Reload weapons faster"},
        quickShot: {reqs:["quickness"],shortDescription:"Reduces multi-target penalty for ranged attacks"},
        quickness: {reqs:[],shortDescription:"Increased Initiative"},
        sturdiness: {reqs:[],shortDescription:"Increased Damage Resistance"},
        swiftness: {reqs:[],shortDescription:"Increased Movement Range"},
        tenacity: {reqs:[],shortDescription:"Increased Blood Capacity"},
        tumble: {reqs:[],shortDescription:"Disengage from melee more safely"}
    },
    qi: {
        automaticDefense: {reqs:["qiHardening"],shortDescription:"Use Qi Hardening on surprise attacks"},
        bondLink: {reqs:["qiSharing"],shortDescription:"Bond with another qi user"},
        bondWeb: {reqs:["bondLink"],shortDescription:"Form multiple Bond Links"},
        breachingStrike: {reqs:["qiBreaking"],shortDescription:"Unarmed Qi Breaking double damage to objects"},
        clairvoyance: {reqs:["dreamSpeech"],shortDescription:"Gain visions of future events while dreaming"},
        combatMage: {reqs:["greaterPowerMage"],shortDescription:"Improves Increased Damage spell power option"},
        deadlyMissile: {reqs:["qiMissile"],shortDescription:"Increased damage for Qi Missiles"},
        deepEmpathy: {reqs:["qiEmpathy"],shortDescription:"Improved qi empathy"},
        deepMeditation: {reqs:["qiSensitivity"],shortDescription:"Recover qi faster with meditation"},
        distantQi: {reqs:["qiEmpathy_OR_qiHardening_OR_qiSharing_OR_qiStealth_OR_qiVigilance"],shortDescription:"Use qi abilities at a distance"},
        disruptingArmor: {reqs:["qiArmor"],shortDescription:"Qi Armor shocks opponents"},
        doubleJump: {reqs:["qiJump"],shortDescription:"Use Qi Jump twice per turn"},
        dreamSpeech: {reqs:["qiDreaming"],shortDescription:"Send messages to others who are sleeping"},
        dreamSustenance: {reqs:["qiDreaming"],shortDescription:"Dreaming satisfies need for food/water"},
        enduringQi: {reqs:["qiArmor_OR_qiCombat_OR_qiPresence_OR_qiShadowstalking_OR_qiStealth_OR_qiWeight"],shortDescription:"Qi abilities last longer"},
        extendedQi: {reqs:["qiForce_OR_qiJump_OR_qiSurvey_OR_qiVigilance"],shortDescription:"Increased range for qi abilities"},
        greaterExtendedQi: {reqs:["extendedQi"],shortDescription:"Increased range for qi abilities"},
        greaterPowerMage: {reqs:["powerMage"],shortDescription:"Can spend more qi on spell power options"},
        greaterQiReserves: {reqs:["qiReserves"],shortDescription:"Increased qi capacity"},
        greaterSignatureSpell: {reqs:["signatureSpell"],shortDescription:"More powerful Signature Spell"},
        greaterSpellShaping: {reqs:["spellShaping"],shortDescription:"Learn advanced spells"},
        greaterWeightControl: {reqs:["qiWeight"],shortDescription:"Improved weight control"},
        harmoniousArt: {reqs:["qiSensitivity"],shortDescription:"Improved zen art"},
        harmoniousPerformance: {reqs:["qiSensitivity"],shortDescription:"Improved zen performance"},
        imposingPresence: {reqs:["qiPresence"],shortDescription:"Qi presence manifests as penalty for enemies"},
        negativeQi: {reqs:["qiSensitivity","_not:qi:positiveQi"],shortDescription:"More negative qi, less positive qi"},
        perfectWeightControl: {reqs:["greaterWeightControl"],shortDescription:"Improved weight control"},
        philosophyOfAction: {reqs:["qiSensitivity","_not:qi:philosophyOfBalance"],shortDescription:"Channel qi for Brawn/Agility/Impression/Intelligence"},
        philosophyOfBalance: {reqs:["qiSensitivity","_not:qi:philosophyOfAction"],shortDescription:"Channel qi for Toughness/Reflex/Serenity/Awareness"},
        philosophyOfBody: {reqs:["qiSensitivity","_not:qi:philosophyOfMind"],shortDescription:"Channel qi for Brawn/Toughness/Agility/Reflex"},
        philosophyOfFinesse: {reqs:["qiSensitivity","_not:qi:philosophyOfStrength"],shortDescription:"Channel qi for Agility/Reflex/Intelligence/Awareness"},
        philosophyOfMind: {reqs:["qiSensitivity","_not:qi:philosophyOfBody"],shortDescription:"Channel qi for Impression/Serenity/Intelligence/Awareness"},
        philosophyOfStrength: {reqs:["qiSensitivity","_not:qi:philosophyOfFinesse"],shortDescription:"Channel qi for Brawn/Toughness/Impression/Serenity"},
        positiveQi: {reqs:["qiSensitivity","_not:qi:negativeQi"],shortDescription:"More positive qi, less negative qi"},
        powerMage: {reqs:["spellShaping"],shortDescription:"Can spend more qi on spell power options"},
        powerfulQi: {reqs:["qiArmor_OR_qiBreaking_OR_qiCombat_OR_qiForce_OR_qiHardening"],shortDescription:"Stronger qi abilities"},
        qiArmor: {reqs:["philosophyOfBalance_OR_philosophyOfBody"],shortDescription:"Form personal armor from qi"},
        qiBreaking: {reqs:["philosophyOfBody_OR_philosophyOfStrength"],shortDescription:"Powerful unarmed strike"},
        qiCombat: {reqs:["philosophyOfAction_OR_philosophyOfBody"],shortDescription:"Increased unarmed damage"},
        qiDisguise: {reqs:["qiStealth"],shortDescription:"Change the appearance of your qi"},
        qiDreaming: {reqs:["philosophyOfAction_OR_philosophyOfMind"],shortDescription:"Get full rest in only 1 hour"},
        qiEmpathy: {reqs:["philosophyOfFinesse_OR_philosophyOfMind"],shortDescription:"Sense emotions"},
        qiForce: {reqs:["philosophyOfMind_OR_philosophyOfStrength"],shortDescription:"Strong telekinetic force"},
        qiHardening: {reqs:["philosophyOfBalance_OR_philosophyOfStrength"],shortDescription:"Absorb incoming damage"},
        qiJump: {reqs:["philosophyOfAction_OR_philosophyOfFinesse"],shortDescription:"Teleport short distances"},
        qiMissile: {reqs:["philosophyOfAction_OR_philosophyOfStrength"],shortDescription:"Enhanced ranged attacks"},
        qiPresence: {reqs:["positiveQi"],shortDescription:"Inspire allies"},
        qiReserves: {reqs:["qiSensitivity"],shortDescription:"Increased qi capacity"},
        qiSensitivity: {reqs:[],shortDescription:"Sense qi; Increased qi capacity"},
        qiShadowstalking: {reqs:["negativeQi"],shortDescription:"Hide in shadows"},
        qiSharing: {reqs:["philosophyOfBalance_OR_philosophyOfMind"],shortDescription:"Share qi with others"},
        qiStealth: {reqs:["negativeQi"],shortDescription:"Hide your qi presence"},
        qiSurvey: {reqs:["positiveQi"],shortDescription:"Extend your qi senses"},
        qiVigilance: {reqs:["philosophyOfBalance_OR_philosophyOfFinesse"],shortDescription:"Sense incoming harm"},
        qiWeight: {reqs:["philosophyOfBody_OR_philosophyOfFinesse"],shortDescription:"Reduce your weight"},
        residueScrubbing: {reqs:["negativeQi"],shortDescription:"Obscure a qi residue"},
        residueTracking: {reqs:["positiveQi"],shortDescription:"Follow qi traits"},
        selectivePresence: {reqs:["qiPresence"],shortDescription:"Qi Presence bonus only for allies"},
        shadowClaws: {reqs:["qiShadowstalking"],shortDescription:"Form temporary claws of shadow"},
        shadowCloak: {reqs:["qiShadowstalking"],shortDescription:"Bonus defense in shadows"},
        signatureSpell: {reqs:["spellShaping"],shortDescription:"Bonus when casting your chosen spell"},
        soaringMissile: {reqs:["qiMissile"],shortDescription:"Increased range for qi missiles"},
        spellShaping: {reqs:["qiSensitivity"],shortDescription:"Learn basic spells"},
        stunningMissile: {reqs:["qiMissile"],shortDescription:"Non-lethal option for qi missiles"},
        subtleQi: {reqs:["qiEmpathy_OR_qiSurvey"],shortDescription:"Use qi abilities undetected"},
        weaponFocus: {reqs:["qiBreaking_OR_qiCombat"],shortDescription:"Use qi abilities with weapons"}
    },
    roleplaying: {
        activelyHunted: {reqs:["hunted"],shortDescription:"Your pursuers are hostile and resourceful"},
        addiction: {reqs:[],shortDescription:"Need regular doses to avoid withdrawls"},
        anemia: {reqs:["_aspect:brawn:3","_aspect:toughness:3"],shortDescription:"Half stamina capacity"},
        commonPhobia: {reqs:["phobia"],shortDescription:"Your phobia is frequently encountered"},
        discrimination: {reqs:[],shortDescription:"You are discriminated against"},
        enemy: {reqs:[],shortDescription:"You have an enemy who occasionally makes life difficult"},
        expensiveAddiction: {reqs:["addiction"],shortDescription:"Your addiction is expensive"},
        extremeFame: {ip:-10,reqs:["greaterFame"],shortDescription:"Enormous unwanted attention"},
        fame: {reqs:[],shortDescription:"Occasional unwanted attention"},
        frail: {reqs:["_aspect:toughness:3"],shortDescription:"Reduced damage reduction"},
        greaterEnemy: {reqs:["enemy"],shortDescription:"Your enemy frequently makes life very difficult"},
        greaterFame: {reqs:["fame"],shortDescription:"Significant unwanted attention"},
        hunted: {reqs:[],shortDescription:"An unfriendly organization hunts you"},
        illiterate: {reqs:[],shortDescription:"Cannot read in any language"},
        minor: {reqs:[],shortDescription:"Must obey your parents/guardians"},
        nemesis: {ip:-10,reqs:["greaterEnemy"],shortDescription:"Enemy is a constant threat to your life"},
        obligation: {reqs:[],shortDescription:"Extra obligation is a significant drain"},
        obviousMinority: {reqs:["discrimination"],shortDescription:"Discrimination is hard to avoid"},
        paranoia: {reqs:[],shortDescription:"Hard to trust others"},
        phobia: {reqs:[],shortDescription:"Unusually frightened by something"},
        physicalDisability: {reqs:[],shortDescription:"Some physical activities are difficult"},
        psychosis: {reqs:[],shortDescription:"Disconnected with reality"},
        severeAddiction: {reqs:["addiction"],shortDescription:"Withdrawl is severe or life threatening"},
        severeAnemia: {reqs:["anemia"],shortDescription:"Quarter stamina capacity"},
        severeDisability: {reqs:["disability"],shortDescription:"Some activities impossible"},
        severeDiscrimination: {reqs:["discrimination"],shortDescription:"Discrimination is severe"},
        severeParanoia: {reqs:["paranoia"],shortDescription:"Extreme paranoia toward almost everyone"},
        severePhobia: {reqs:["phobia"],shortDescription:"Paralyzed by fear"},
        severePsychosis: {reqs:["psychosis"],shortDescription:"Random wild ideas that are obviously false"},
        severelyHunted: {ip:-10,reqs:["activelyHunted"],shortDescription:"Your pursuers are very hostile and resourceful"},
        weightyObligation: {reqs:["obligation"],shortDescription:"You obligation requires enormous time/resources"}
    },
    bestowed: {
        additionalHealing: {reqs:["chosenOfTheArmOfMercy","healingMeditation"],shortDescription:"More healing meditations/day"},
        angel: {reqs:["fastHealing","additionalHealing"],shortDescription:"Greater Gift; More healing meditations/day"},
        avatarOfTheArmOfMercy: {reqs:["angel"],shortDescription:"Greater Gift; very fast healing meditation"},
        avatarOfTheChampionsOfNature: {reqs:["liberator"],shortDescription:"Greater Gift; Transformation; More bonds"},
        avatarOfTheCorruptedHeart: {reqs:["defiler"],shortDescription:"Greater Gift; Improved decay"},
        avatarOfTheFistOfOppression: {reqs:["tyrant"],shortDescription:"Greater Gift; Group Drain"},
        avatarOfTheGuardiansOfCreation: {reqs:["keeper"],shortDescription:"Greater Gift; Improved fortification"},
        avatarOfThePuppeteers: {reqs:["master"],shortDescription:"Greater Gift; Improved possession/subjugation"},
        beastBond: {reqs:["chosenOfTheChampionsOfNature","friendOfBeasts"],shortDescription:"Form permanent bonds with animals"},
        beastSight: {reqs:["chosenOfTheChampionsOfNature","friendOfBeasts"],shortDescription:"Share animal senses"},
        chosenOfTheArmOfMercy: {reqs:["initiateOfTheArmOfMercy"],shortDescription:"Lesser Gift"},
        chosenOfTheChampionsOfNature: {reqs:["initiateOfTheChampionsOfNature"],shortDescription:"Lesser Gift"},
        chosenOfTheCorruptedHeart: {reqs:["initiateOfTheCorruptedHeart"],shortDescription:"Lesser Gift"},
        chosenOfTheFistOfOppression: {reqs:["initiateOfTheFistOfOppression"],shortDescription:"Lesser Gift"},
        chosenOfTheGuardiansOfCreation: {reqs:["initiateOfTheGuardiansOfCreation"],shortDescription:"Lesser Gift"},
        chosenOfThePuppeteers: {reqs:["initiateOfThePuppeteers"],shortDescription:"Lesser Gift"},
        darkInvestment: {reqs:["chosenOfTheCorruptedHeart","decay"],shortDescription:"Invest an object with negative qi"},
        darkObjectMastery: {reqs:["chosenOfTheCorruptedHeart","decay"],shortDescription:"Use one qi ability with objects"},
        decay: {reqs:["initiateOfTheCorruptedHeart","negativeQi"],shortDescription:"Cause objects to decay"},
        defiler: {reqs:["darkInvestment","darkObjectMastery"],shortDescription:"Greater Gift; Qi anchor radiates"},
        disguisedDomination: {reqs:["chosenOfThePuppeteers","possession"],shortDescription:"Possession and Domination harder to detect"},
        distantDrain: {reqs:["chosenOfTheFistOfOppression","essenceDrain"],shortDescription:"Ranged Essence Drain"},
        drainingStrike: {reqs:["chosenOfTheFistOfOppression","essenceDrain"],shortDescription:"Essence Drain on melee hit"},
        epicTimeShift: {reqs:["timeShift","epicAdvancement"],shortDescription:"Rewind time longer"},
        essenceDrain: {reqs:["initiateOfTheFistOfOppression","negativeQi"],shortDescription:"Drain qi from others"},
        fastHealing: {reqs:["chosenOfTheArmOfMercy","healingMeditation"],shortDescription:"Faster healing meditation"},
        fortification: {reqs:["initiateOfTheGuardiansOfCreation","positiveQi"],shortDescription:"Fortify objects"},
        friendOfBeasts: {reqs:["initiateOfTheChampionsOfNature","positiveQi"],shortDescription:"Animal telepathy"},
        healingMeditation: {reqs:["initiateOfTheArmOfMercy","positiveQi"],shortDescription:"Meditate to heal others"},
        initiateOfTheArmOfMercy: {reqs:["_allegiance:mercy"],shortDescription:"Lesser Gift"},
        initiateOfTheChampionsOfNature: {reqs:["_allegiance:nature"],shortDescription:"Lesser Gift"},
        initiateOfTheCorruptedHeart: {reqs:["_allegiance:destruction"],shortDescription:"Lesser Gift"},
        initiateOfTheFistOfOppression: {reqs:["_allegiance:oppression"],shortDescription:"Lesser Gift"},
        initiateOfTheGuardiansOfCreation: {reqs:["_allegiance:creation"],shortDescription:"Lesser Gift"},
        initiateOfThePuppeteers: {reqs:["_allegiance:manipulation"],shortDescription:"Lesser Gift"},
        initiateOfTheTimeWalkers: {reqs:["_timeWalker"],shortDescription:"Rest for foresight"},
        keeper: {reqs:["lightInvestment","lightObjectMastery"],shortDescription:"Greater Gift; Qi anchor radiates"},
        liberator: {reqs:["beastBond","beastSight"],shortDescription:"Greater Gift; Improved beast bond"},
        lightInvestment: {reqs:["chosenOfTheGuardiansOfCreation","fortification"],shortDescription:"Invest an object with positive qi"},
        lightObjectMastery: {reqs:["chosenOfTheGuardiansOfCreation","fortification"],shortDescription:"Use one qi ability with objects"},
        master: {reqs:["disguisedDomination","subjugation"],shortDescription:"Greater Gift; Longer Subjugation"},
        mindLink: {reqs:["initiateOfTheTimeWalkers","qiSensitivity"],shortDescription:"Timeless telepathic link"},
        possession: {reqs:["initiateOfThePuppeteers","negativeQi"],shortDescription:"Take control of others"},
        subjugation: {reqs:["chosenOfThePuppeteers","possession"],shortDescription:"Lingering possession commands"},
        supremeTimeShift: {reqs:["epicTimeShift","supremeAdvancement"],shortDescription:"Rewind time a lot"},
        timeShift: {reqs:["mindLink"],shortDescription:"Reroll with foresight"},
        tyrant: {reqs:["distantDrain","drainingStrike"],shortDescription:"Greater Gift; More drains/day"},
    },
    epic: {
        airWalk: {reqs:["epicAdvancement","perfectWeightControl"],shortDescription:"Walk on air"},
        awesomePresence: {reqs:["imposingPresence","selectivePresence","supremeAdvancement"],shortDescription:"Benefit allies and penalize enemies"},
        dreamInvasion: {reqs:["dreamSpeech","epicAdvancement"],shortDescription:"Invade others' dreams"},
        dreamTransfer: {reqs:["dreamSustenance","supremeAdvancement"],shortDescription:"Hide an item in your dream"},
        dreamwalking: {reqs:["clairvoyance","epicAdvancement"],shortDescription:"Mind can leave body"},
        epicAdvancement: {reqs:[],shortDescription:"Aspects/skills max at rank 12"},
        epicBreachingStrike: {reqs:["breachingStrike","epicAdvancement"],shortDescription:"Improved Breaching Strike"},
        epicBrute: {reqs:["brute","epicAdvancement"],shortDescription:"Increased brawn-based damage"},
        epicCombatMage: {reqs:["combatMage","epicPowerMage"],shortDescription:"Improved Increased Damage spell power option"},
        epicDeadlyMissile: {reqs:["deadlyMissile","epicAdvancement"],shortDescription:"Improved Deadly Missile"},
        epicDistantQi: {reqs:["distantQi","epicAdvancement"],shortDescription:"Improved Distant Qi"},
        epicEndurance: {reqs:["epicAdvancement","greatEndurance"],shortDescription:"Increased Stamina and faster recovery"},
        epicEnduringQi: {reqs:["enduringQi","epicAdvancement"],shortDescription:"Increased duration of qi abilities"},
        epicExtendedQi: {reqs:["epicAdvancement","greaterExtendedQi"],shortDescription:"Increased range of qi abilities"},
        epicFortitude: {reqs:["epicAdvancement","fortitude"],shortDescription:"Increased Pain Capacity"},
        epicPowerMage: {reqs:["epicAdvancement","greaterPowerMage"],shortDescription:"Can spend more qi on spell power options"},
        epicPowerfulQi: {reqs:["epicAdvancement","powerfulQi"],shortDescription:"Improved Powerful Qi"},
        epicQiReserves: {reqs:["epicAdvancement","greaterQiReserves"],shortDescription:"Increased Qi Capacity"},
        epicQuickness: {reqs:["epicAdvancement","quickness"],shortDescription:"Increased Initiative"},
        epicSoaringMissile: {reqs:["epicAdvancement","soaringMissile"],shortDescription:"Improved Soaring Missile"},
        epicSpeed: {reqs:["epicQuickness"],shortDescription:"Additional major action every other turn"},
        epicSpellShaping: {reqs:["epicAdvancement","greaterSpellShaping"],shortDescription:"Learn epic spells"},
        epicSturdiness: {reqs:["epicAdvancement","sturdiness"],shortDescription:"Increased Damage Resistance"},
        epicSubtleQi: {reqs:["epicAdvancement","subtleQi"],shortDescription:"Improved Subtle Qi"},
        epicSwiftness: {reqs:["epicAdvancement","swiftness"],shortDescription:"Increased Movement Range"},
        epicTenacity: {reqs:["epicAdvancement","tenacity"],shortDescription:"Increased Blood and Wound Capacities"},
        groupJump: {reqs:["epicAdvancement","extendedQi","qiJump"],shortDescription:"Qi Jump with passengers"},
        objectPossession: {reqs:["dreamTransfer","dreamwalking"],shortDescription:"Attach mind to physical object"},
        perfectRegeneration: {reqs:["regeneration","supremeTenacity"],shortDescription:"Automatically use qi to self heal"},
        qiReceiving: {reqs:["bondLink","epicAdvancement"],shortDescription:"Transfer qi to self when Qi Sharing"},
        qiReturn: {reqs:["epicExtendedQi","qiJump","supremeAdvancement"],shortDescription:"Jump to special locations over any distance"},
        qiTracer: {reqs:["bondLink","supremeAdvancement"],shortDescription:"Place tracking beacon when using Qi Sharing"},
        regeneration: {reqs:["epicTenacity"],shortDescription:"Heal more quickly and easily"},
        shadowMeld: {reqs:["supremeAdvancement","shadowCloak"],shortDescription:"Become intangible in shadows"},
        shadowStep: {reqs:["epicAdvancement","shadowstalking"],shortDescription:"Move quickly and easily through shadows"},
        supremeAdvancement: {reqs:["epicAdvancement"],shortDescription:"Aspects/skills max at rank 16"},
        supremeBrute: {reqs:["epicBrute","supremeAdvancement"],shortDescription:"Improved brawn-based damage"},
        supremeCombatMage: {reqs:["epicCombatMage","supremePowerMage"],shortDescription:"More single target spell damage"},
        supremeDeadlyMissile: {reqs:["epicDeadlyMissile","supremeAdvancement"],shortDescription:"Improved Qi Missile"},
        supremeEmpathy: {reqs:["deepEmpathy","supremeAdvancement"],shortDescription:"Detect memories"},
        supremeEndurance: {reqs:["epicEndurance","supremeAdvancement"],shortDescription:"Increased Stamina and faster recovery"},
        supremeEnduringQi: {reqs:["epicEnduringQi","supremeAdvancement"],shortDescription:"Increased duration of qi abilities"},
        supremeExplosion: {reqs:["deadlyMissile","supremeAdvancement"],shortDescription:"Increased size of Qi Missile explosion"},
        supremeExtendedQi: {reqs:["epicDistantQi_OR_epicExtendedQi","supremeAdvancement"],shortDescription:"Double your Qi Range"},
        supremeFortitude: {reqs:["epicFortitude","supremeAdvancement"],shortDescription:"Recover from TAP faster"},
        supremePowerMage: {reqs:["epicPowerMage","supremeAdvancement"],shortDescription:"Can spend more qi on spell power options"},
        supremePowerfulQi: {reqs:["epicPowerfulQi","supremeAdvancement"],shortDescription:"Improved Powerful Qi"},
        supremeQiReserves: {reqs:["epicQiReserves","supremeAdvancement"],shortDescription:"Increased Qi Capacity"},
        supremeQuickness: {reqs:["epicQuickness","supremeAdvancement"],shortDescription:"Increased Initiative"},
        supremeSpeed: {reqs:["epicSpeed","supremeAdvancement"],shortDescription:"Extra major action on every turn"},
        supremeSturdiness: {reqs:["epicSturdiness","supremeAdvancement"],shortDescription:"Increased Damage Resistance"},
        supremeSurvey: {reqs:["supremeAdvancement","extendedQi","qiSurvey"],shortDescription:"Improved Qi Survey"},
        supremeSwiftness: {reqs:["epicSwiftness","supremeAdvancement"],shortDescription:"Increased Movement Range"},
        supremeTenacity: {reqs:["epicTenacity","supremeAdvancement"],shortDescription:"Increased Blood and Wound Capacities"},
        threatFinder: {reqs:["supremeAdvancement","greaterExtendedQi","qiVigilance"],shortDescription:"Qi Vigilance reveals threat source"},
        versatilePresence: {reqs:["epicAdvancement","qiPresence"],shortDescription:"Can use any philosophy with Qi Presence"}
    }
}
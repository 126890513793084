import './Game.css';
import React from 'react';
import Chat from './Chat';
import { Initiative } from './Initiative';
import { Character, cap } from './Character';
import Dialogue from './Dialogue';
import { Helmet } from 'react-helmet';

/* TODO
  DONE multiple games
  DONE delete player storage
  DONE export/import character
  DONE game notes
  DONE ability to cancel dialogues
       game passwords
  DONE game settings
  DONE more initiative controls (end initiative, remove single character from initiative, RollInitiative only appears if not in initiative)
  DONE show defaults on dialogue
  DONE have certain conditions for what questions are shown in the dialogue
       sunrise and sunset buttons
       account management page (show list of games)
 */

let params = new URLSearchParams(document.location.search);

const GameName = params.get("game") || 0;
console.log("Connecting to", GameName)


function fallbackCopyTextToClipboard(text) {
  var textArea = document.createElement("textarea");
  textArea.value = text;
  
  // Avoid scrolling to bottom
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    document.execCommand('copy');
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err);
  }

  document.body.removeChild(textArea);
}

export function copyTextToClipboard(text) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }
  navigator.clipboard.writeText(text).then(function() {
    // console.log('Async: Copying to clipboard was successful!');
  }, function(err) {
    console.error('Async: Could not copy text: ', err);
  });
}

// Recursively copy an object instead of just referencing the old object
export function copyObject (obj) {
  let newObj = {}
  if (Array.isArray(obj)) {
    newObj = []
    obj.forEach(value => {
      if (typeof value === "object") {
        newObj.push(copyObject(value))
      } else {
        newObj.push(value)
      }
    })
  } else {
    for (let key in obj) {
      if (typeof obj[key] === "object") {
        newObj[key] = copyObject(obj[key])
      } else {
        newObj[key] = obj[key]
      }
    }
  }
  return newObj
}

export default class Game extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      gameData: {
        chatHistory: [],
        characters: [],
        settings: ["Main Campaign", "All"],
        currentSetting: "Main Campaign",
        charTypeFilter: "both",
        gameNotes: {_Main_Campaign: "", _All: ""}
      },
      players:{}, // id-indexed object of player objects
      onlinePlayerIds:[],
      queuedDialogues: [],
      myCharacterStates: [] //objects {id,collapsed}
    }
  }

  isGM = false;
  myId = localStorage.getItem("splinteredSymmetryToken").slice(0,10);
  respondedPlayerIds = [] // used when a player leaves to figure out which players are still left
  responseTimerId

  componentDidMount = () => {
    document.body.classList.add("game")

    this.connectToSocket();

    let setStateObject = {}

    let darkMode = localStorage.getItem('darkMode');
    if(darkMode && darkMode !== 'false') {
      document.body.classList.add("darkmode");
      setStateObject.darkMode = true;
    }

    let whispering = localStorage.getItem('whispering');
    if(whispering && whispering !== 'false') {
      setStateObject.whispering = true;
    }

    this.setState(setStateObject);
  }

  componentWillUnmount = () => {
    this.socket?.close()
  }

  dialogueResultFunction
  componentDidUpdate = () => {
    //console.log("this.dialogueResultFunction", this.dialogueResultFunction)
    if (this.dialogueResultFunction) {
      this.dialogueResultFunction()
      this.dialogueResultFunction = undefined
    }
    let updatedCharacterStates = copyObject(this.state.myCharacterStates);
    this.state.gameData.characters.forEach((character) => {
      let isNew = true;
      this.state.myCharacterStates.forEach((characterState) => {
        if(characterState.id === character.id) isNew = false;
      });
      if(isNew) {
        updatedCharacterStates.push({id:character.id,collapsed:this.isGM});
      }
    });
    if(updatedCharacterStates.length !== this.state.myCharacterStates.length) this.setState({myCharacterStates:updatedCharacterStates});
  }

  socket = undefined
  receivedFirstSocketMessage = false

  connectToSocket = () => {
    if(!this.socket) {
      // fix using this answer: https://stackoverflow.com/questions/58432076/websockets-with-functional-components
      this.socket = new WebSocket('wss://warm-reef-77238.herokuapp.com/:35883');

      const myId = localStorage.getItem("splinteredSymmetryToken").slice(0, 10);

      this.socket.addEventListener('open', (event) => {
        this.socket.send(JSON.stringify({session:`splintered_symmetry_${GameName}`, gameName: 'splintered_symmetry', password: "0jf,Jg39*%gD9k", myId}));
      });

      this.socket.addEventListener('message', (event) => {
        let message = JSON.parse(event.data);
        this.handleSocketMessage(message);
      });

      this.socket.addEventListener('close', (event) => {
        this.socket = null;
        setTimeout(this.connectToSocket, 1000);
      });
    }
  }

  lastSize = 0

  handleSocketMessage = (message) => {
    if (message.message) {
      message = JSON.parse(message.message);
    }
    if (message.type === 'initialInfo') {
      this.handleInitialInfo(message.content)
    }
    if (message.type === 'gameData') {
      if (!this.isGM) {
        this.setState(prevState=>({gameData: {...prevState.gameData, ...message.content}}));
      }
    }
    if (message.type === 'newChat') {
      this.handleChatMessage(message.content.message, message.content.characterChanges, message.content.characterId)
    }
    if (message.type === 'editCharacters' && this.isGM) {
      this.editCharacters(...message.content)
    }
    if (message.type === 'passInitiative' && this.isGM) {
      this.passInitiative(message.content.characterChanges, message.content.characterId)
    }
    // if (message.type === 'addCharacter' && this.isGM) {
    //   this.addCharacter()
    // }
    // if (message.type === 'deleteCharacter' && this.isGM) {
    //   this.deleteCharacter(message.content)
    // }
    // if (message.type === 'duplicateCharacter' && this.isGM) {
    //   this.duplicateCharacter(message.content)
    // }
    if (message.size) {
      if (message.size > this.lastSize) {
        this.lastSize = message.size
        // Send your own id so the new player gets a list of everyone 
        this.sendSocketMessage({type: 'stillOnline', content: this.myId})
        if (this.isGM) {
          this.sendSocketMessage({type: 'gameData', content: this.state.gameData})
        }
      } else {
        if (message.size === 1) {
          this.setState({onlinePlayerIds: [this.myId]})
        } else {
          this.sendSocketMessage({type: 'stillOnline', content: this.myId})
          this.respondedPlayerIds = [this.myId]
          if (isFinite(this.responseTimerId)) clearTimeout(this.responseTimerId)
          this.responseTimerId = setTimeout(()=>{
            this.checkRespondedPlayerIds('force');
            this.responseTimerId = undefined;
          }, 5000)
        }
      }
    }
    if (message.type === 'stillOnline') {
      if (!this.respondedPlayerIds.includes(message.content)) {
        this.respondedPlayerIds.push(message.content)
        this.checkRespondedPlayerIds()
      }
    }
    if (!this.receivedFirstSocketMessage) {
      this.receivedFirstSocketMessage = true
      this.sendSocketMessage({type: 'joinWithId', content: this.myId})
    }
    if (message.type === 'joinWithId') {
      if (!this.state.onlinePlayerIds.includes(message.content)) {
        // show player as "online"
        this.setState({onlinePlayerIds: [...this.state.onlinePlayerIds, message.content]})
      }
    }
  }

  handleInitialInfo = (info) => {
    this.isGM = info.isGM
    let setStateObject = {}

    setStateObject.gameData = {...this.state.gameData, ...JSON.parse(info.gameData)}
    console.log(`info:`, info)
    setStateObject.players = {...this.state.players, ...info.players}
    
    setStateObject.onlinePlayerIds = [this.myId]

    this.setState(setStateObject)
    this.respondedPlayerIds = [this.myId]
  }

  checkRespondedPlayerIds = (force) => {
    if (force || this.respondedPlayerIds.length >= this.lastSize || !isFinite(this.responseTimerId)) {
      // All remaining players have responded
      this.setState({onlinePlayerIds: this.respondedPlayerIds})
    }
  }

  closeSocket = () => {
    this.socket?.close();
    this.socket = null;
  }

  sendSocketMessage = (message) => {
    //console.log("SENDING", message)
    this.socket?.send(JSON.stringify({
      session: `splintered_symmetry_${GameName}`,
      gameName: 'splintered_symmetry',
      password: "0jf,Jg39*%gD9k",
      message: JSON.stringify(message)
    }));
  }


  sendChat = (message, characterChanges, characterId) => {
    let secret = !!this.state.whispering;
    if(characterChanges || characterId) {
      let characters = this.state.gameData.characters
      let index = characters.findIndex(c=>c.id===characterId)
      secret = secret || (!characters[index].isPC && characters[index].NPCData.hide);
    }
    let chatMessage = {
      from: this.myId,
      time: Date.now(),
      content: message,
      secret: secret,
      gmOnly: false
    }
    console.log("sendChat: " + message);
    console.log(chatMessage);
    this.sendSocketMessage({type: "newChat", content: {message:chatMessage, characterChanges, characterId}})
    this.handleChatMessage(chatMessage, characterChanges, characterId)
  }

  sendGMChat = (message, characterChanges, characterId) => {
    let chatMessage = {
      from: this.myId,
      time: Date.now(),
      content: message,
      secret: true,
      gmOnly: true
    }
    this.sendSocketMessage({type: "newChat", content: {message:chatMessage, characterChanges, characterId}})
    this.handleChatMessage(chatMessage, characterChanges, characterId)
  }

  handleChatMessage = (message, characterChanges, characterId) => {
    // The sender sends the message to every player, who adds it to their chat. The GM also edits the character, if applicable, and sends the changes to everyone
    let changes = {chatHistory: this.state.gameData.chatHistory.concat(message)}
    if (this.isGM) {
      if (characterChanges) {
        let characters = this.state.gameData.characters
        let index = characters.findIndex(c=>c.id===characterId)
        characters[index] = {...characters[index], ...characterChanges, versionNumber: characters[index].versionNumber+1}
        changes.characters = characters
        this.sendSocketMessage({type: 'gameData', content: {characters}})
      }
      this.saveChanges(changes)
    }
    this.setState(prevState=>({
      gameData: {
        ...prevState.gameData,
        ...changes
      }
    }))
  }

  clearChatHistory = () => {
    if(this.isGM) {
      this.newDialogue({
        title: "Confirm Chat Clear",
        questions: {
          confirm: {
            name:"Are You Sure?",
            inputType:"dropdown",
            default:false,
            options:[{name:"No",value:false},{name:"Yes",value:true}]
          }
        },
        resultFunction: (result) => {
          if(result.confirm) this.confirmClearChatHistory();
        }
      });
    }
  }

  confirmClearChatHistory = () => {
    let changes = {chatHistory: []};
    if(this.isGM) {
      this.saveChanges(changes);
      this.setState(prevState=>({gameData:{...prevState.gameData,...changes}}));
    }
  }

  changeInitiative = (charID) => {
    let index = this.state.gameData.characters.findIndex(c=>c.id===charID);
    if(index !== -1) {
      this.newDialogue({
        title: "Change Initiative: " + this.state.gameData.characters[index].name,
        questions: {
          newInitiative: {
            name:"New Initiative:",
            inputType:"number",
            default:Math.floor(this.state.gameData.characters[index].currentInitiative)
          }
        },
        resultFunction: (result) => {
          let newInitiative = result.newInitiative + this.state.gameData.characters[index].currentInitiative - Math.floor(this.state.gameData.characters[index].currentInitiative);
          let editCharactersArray = [];
          editCharactersArray.push({...this.state.gameData.characters[index], currentInitiative:newInitiative, versionNumber: this.state.gameData.characters[index].versionNumber+1});
          this.editCharacters(...editCharactersArray);
        }
      });
    }
  }

  bumpInitiative = (charID,higher) => {
    let index = this.state.gameData.characters.findIndex(c=>c.id===charID);
    let initiativeGroup = this.state.gameData.characters.filter(c=>isFinite(c.currentInitiative));
    let sortedCharacters = initiativeGroup.sort((a,b) => b.currentInitiative - a.currentInitiative)
    let sortIndex = sortedCharacters.findIndex(c=>c.id===charID);
    if(higher && sortIndex === 0) return;
    if(!higher && sortIndex + 1 === sortedCharacters.length) return;
    let nextIndex = higher ? sortIndex - 1 : sortIndex + 1;
    let nextInitiative = sortedCharacters[nextIndex].currentInitiative;
    let farIndex = higher ? nextIndex - 1 : nextIndex + 1;
    let farInitiative;
    if(higher) {
      if(farIndex < 0) farInitiative = Math.floor(sortedCharacters[0].currentInitiative) + 1;
      else farInitiative = Math.min(Math.floor(nextInitiative) + 1, sortedCharacters[farIndex].currentInitiative);
    } else {
      if(farIndex >= sortedCharacters.length) farInitiative = Math.floor(sortedCharacters[nextIndex].currentInitiative);
      else farInitiative = Math.max(Math.floor(nextInitiative), sortedCharacters[farIndex].currentInitiative);
    }
    let newInitiative = (nextInitiative + farInitiative) / 2;
    let editCharactersArray = [];
    editCharactersArray.push({...this.state.gameData.characters[index], currentInitiative: newInitiative, versionNumber: this.state.gameData.characters[index].versionNumber+1});
    this.editCharacters(...editCharactersArray);
  }

  setCurrentInitiative = (charID) => {
    let oldIndex = this.state.gameData.characters.findIndex(c=>c.isCurrentInitiative)
    let newIndex = this.state.gameData.characters.findIndex(c=>c.id === charID)
    let editCharactersArray = []
    if (oldIndex !== -1) editCharactersArray.push({...this.state.gameData.characters[oldIndex], isCurrentInitiative: false, versionNumber: this.state.gameData.characters[oldIndex].versionNumber+1})
    if (newIndex !== -1) editCharactersArray.push({...this.state.gameData.characters[newIndex], isCurrentInitiative: true, versionNumber: this.state.gameData.characters[newIndex].versionNumber+1})
    if (editCharactersArray.length) this.editCharacters(...editCharactersArray)
  }

  passInitiative = (characterChanges, characterId) => {
    if (!this.isGM) {
      this.sendSocketMessage({type: "passInitiative", content: {characterChanges, characterId}})
      return
    }
    let currentCharacters = this.state.gameData.characters.filter(c=>isFinite(c.currentInitiative)) 
    let sortedCharacters = currentCharacters.sort((a,b) => b.currentInitiative - a.currentInitiative)
    let oldIndex = sortedCharacters.findIndex(c=>c.id===characterId)
    let newIndex = oldIndex+1
    if (newIndex >= sortedCharacters.length) newIndex = 0
    let editCharactersArray = []
    if (oldIndex !== -1) editCharactersArray.push({...sortedCharacters[oldIndex], ...characterChanges, isCurrentInitiative: false, versionNumber: sortedCharacters[oldIndex].versionNumber+1})
    if (newIndex !== -1) editCharactersArray.push({...sortedCharacters[newIndex], isCurrentInitiative: true, versionNumber: sortedCharacters[newIndex].versionNumber+1})
    if (editCharactersArray.length) this.editCharacters(...editCharactersArray)
  }

  removeFromInitiative = (charName) => { // GM only. Used manually when an npc dies or a player leaves combat, etc
    // Remove the character from the initiative. 
    let currentCharacters = this.state.gameData.characters.filter(c=>isFinite(c.currentInitiative)) 
    let sortedCharacters = currentCharacters.sort((a,b) => b.currentInitiative - a.currentInitiative)
    let oldIndex = sortedCharacters.findIndex(c=>c.name===charName)
    let editCharactersArray = []
    if (oldIndex !== -1) {
      editCharactersArray.push({...sortedCharacters[oldIndex], currentInitiative: undefined, versionNumber: sortedCharacters[oldIndex].versionNumber+1})
      if (sortedCharacters[oldIndex].isCurrentInitiative) {
        // If it was this character's turn, pass initiative to the next character
        editCharactersArray[0].isCurrentInitiative = false
        let newIndex = oldIndex+1
        if (newIndex >= sortedCharacters.length) newIndex = 0
        if (newIndex !== -1) editCharactersArray.push({...sortedCharacters[newIndex], isCurrentInitiative: true, versionNumber: sortedCharacters[newIndex].versionNumber+1})
      }
    }
    if (editCharactersArray.length) this.editCharacters(...editCharactersArray)
  }

  removeAllInitiative = () => { // GM only. Used manually to end combat
    // Remove all characters from the initiative. 
    let currentCharacters = this.state.gameData.characters.filter(c=>isFinite(c.currentInitiative)) 
    let editCharactersArray = []
    currentCharacters.forEach(character=>{
      editCharactersArray.push({...character, currentInitiative: undefined, isCurrentInitiative: false, versionNumber: character.versionNumber+1})
    })
    if (editCharactersArray.length) this.editCharacters(...editCharactersArray)
  }

  editCharacters = (...args) => {
    if (!this.isGM) {
      this.sendSocketMessage({type: "editCharacters", content: args})
      return
    }
    let newCharactersArray = this.state.gameData.characters
    args.forEach(newCharacter=>{
      let index = this.state.gameData.characters.findIndex(c=>c.id===newCharacter.id)
      if (index !== -1 && newCharacter.versionNumber > newCharactersArray[index].versionNumber) {
        newCharactersArray[index] = newCharacter
      }
    })
    this.propagateChanges({...this.state.gameData, characters: newCharactersArray});
  }

  exportCharactersDialogue = () => {
    let questions = {all:{name: "All in Current Setting", inputType: "checkbox", default: false}}
    this.state.gameData.characters.forEach(character=>{
      if(character.settingName === this.state.gameData.currentSetting) {
        questions[character.id] = {name: character.name, inputType: "checkbox", default: false}
      }
    })
    this.newDialogue({questions, resultFunction:(result)=>this.exportCharacters(result)})
  }

  exportCharacters = (characterIds) => {
    let characters = []
    for (const id in characterIds) {
      if (characterIds[id] || characterIds.all) {
        if(id !== "all") {
          let index = this.state.gameData.characters.findIndex(c=>c.id===parseInt(id))
          characters.push(this.state.gameData.characters[index])
        }
      }
    }
    copyTextToClipboard(JSON.stringify(characters))
  }

  addCharacter = () => {
    if (!this.isGM) {
      // this.sendSocketMessage({type: "addCharacter", content: ""})
      return
    }
    let newCharactersArray = this.state.gameData.characters
    let highestId = newCharactersArray.length ? newCharactersArray.sort((a,b)=>b.id-a.id)[0].id : 0
    newCharactersArray.push({id: highestId+1, versionNumber:0, name:"Character Name", owner: this.myId, settingName:this.state.gameData.currentSetting, editMode: true, needsReset: true})
    this.propagateChanges({...this.state.gameData, characters: newCharactersArray});
  }

  importCharactersDialogue = () => {
    this.newDialogue({
      questions:{
        charText: {name:"Paste your character data here: ", inputType: "text", default: ""}
      }, 
      resultFunction:(result)=>this.importCharacters(result.charText)
    })
  }

  importCharacters = (text) => {
    if (!this.isGM) {
      return
    }
    if (text.length===0) {
      return
    }
    try {
      // Gets a JSON string containing an array of character objects
      let importedCharacters = JSON.parse(text)
      let newCharactersArray = this.state.gameData.characters
      let highestId = newCharactersArray.length ? newCharactersArray.sort((a,b)=>b.id-a.id)[0].id : 0
      importedCharacters.forEach(character=>{
        newCharactersArray.push({...character, id: highestId+1, versionNumber:0, owner: this.myId})
        highestId++
      })
      this.propagateChanges({...this.state.gameData, characters: newCharactersArray});
    } catch (e) {
      console.error(e)
    }
  }

  deleteCharacter = (id) => {
    let index = this.state.gameData.characters.findIndex(c=>c.id===id);
    if(index !== -1) {
      this.newDialogue({
        title: "Confirm Character Delete: " + this.state.gameData.characters[index].name,
        questions: {
          confirm: {
            name:"Are You Sure?",
            inputType:"dropdown",
            default:false,
            options:[{name:"No",value:false},{name:"Yes",value:true}]
          }
        },
        resultFunction: (result) => {
          if(result.confirm) this.confirmDeleteCharacter(id);
        }
      });
    }
  }

  confirmDeleteCharacter = (id) => {
    if (!this.isGM) {
      // this.sendSocketMessage({type: "deleteCharacter", content: id})
      return
    }
    let newCharactersArray = this.state.gameData.characters
    let index = this.state.gameData.characters.findIndex(c=>c.id===id)
    if (index !== -1) {
      newCharactersArray.splice(index, 1)
      this.propagateChanges({...this.state.gameData, characters: newCharactersArray});
    }
  }

  duplicateCharacter = (id) => {
    if (!this.isGM) {
      // this.sendSocketMessage({type: "duplicateCharacter", content: id})
      return
    }
    let oldIndex = this.state.gameData.characters.findIndex(c=>c.id===id)
    if (oldIndex !== -1) {
      let oldCharacter = this.state.gameData.characters[oldIndex]
      let highestId = this.state.gameData.characters.sort((a,b)=>b.id-a.id)[0].id
      let listOfNames = this.state.gameData.characters.map(c=>c.name)
      let newName = oldCharacter.name
      do {
        if (/\d+$/.test(newName)) {
          let oldNumber = parseInt(/\d+$/.exec(newName)[0])
          newName = newName.replace(/\d+$/, oldNumber+1)
        } else {
          newName = newName + " 2"
        }
      } while (listOfNames.includes(newName))
      let newCharactersArray = this.state.gameData.characters
      newCharactersArray.push({...copyObject(oldCharacter), id: highestId+1, versionNumber:0, name:newName, owner: this.myId, editMode: true})
      this.propagateChanges({...this.state.gameData, characters: newCharactersArray});
    }
  }

  // deletePlayerData = (index) => {
  //   this.newDialogue({
  //     title: "Confirm Player Delete: " + this.state.globalData.players[index].name,
  //     questions: {
  //       confirm: {
  //         name:"Are You Sure?",
  //         inputType:"dropdown",
  //         default:false,
  //         options:[{name:"No",value:false},{name:"Yes",value:true}]
  //       }
  //     },
  //     resultFunction: (result) => {
  //       if(result.confirm) this.confirmDeletePlayerData(index);
  //     }
  //   });
  // }

  // confirmDeletePlayerData = (index) => {
  //   let newPlayers = [...this.state.globalData.players.slice(0, index), ...this.state.globalData.players.slice(index+1)]
  //   this.setState({globalData: {...this.state.globalData, players: newPlayers}})
  //   localStorage.setItem("globalData", JSON.stringify({...this.state.globalData, players: newPlayers}))
  // }

  toggleForcePlayMode = () => {
    let newForcePlayMode = !this.state.gameData.forcePlayMode
    if (newForcePlayMode) {
      let editCharactersArray = []
      this.state.gameData.characters.forEach(character=>{
        editCharactersArray.push({...character, editMode:false, versionNumber: character.versionNumber+1})
      })
      this.editCharacters(...editCharactersArray)
    }
    this.propagateChanges({forcePlayMode: newForcePlayMode})
  }

  toVariableName = (textName) => {
    return "_" + textName?.replace(/ /g, "_");
  }

  addSettingDialogue = () => {
    this.newDialogue({
      questions:{
        settingName: {name:"Setting Name: ", inputType: "text", default: ""}
      }, 
      resultFunction:(result)=>{
        if (result.settingName.length===0) return;
        if (this.state.gameData.gameNotes[this.toVariableName(result.settingName)]) this.sendGMChat("Error Creating Setting: Setting already exists");
        else {
          let newNotes = this.state.gameData.gameNotes;
          newNotes[this.toVariableName(result.settingName)] = "";
          this.propagateChanges({settings: [...this.state.gameData.settings, result.settingName], gameNotes: newNotes});
        }
      }
    })
  }

  renameSettingDialogue = () => {
    this.newDialogue({
      questions:{
        settingName: {name:"New Name: ", inputType: "text", default: this.state.gameData.currentSetting}
      },
      resultFunction:(result)=>{
        if (result.settingName.length===0 || result.settingName === this.state.gameData.currentSetting) return;
        if (this.state.gameData.gameNotes[this.toVariableName(result.settingName)]) this.sendGMChat("Error Renaming Setting: New name already in use");
        else {
          let newSettings = [...this.state.gameData.settings, result.settingName];
          newSettings.splice(newSettings.indexOf(this.state.gameData.currentSetting),1);
          console.log(newSettings);
          let newGameNotes = this.state.gameData.gameNotes;
          console.log(newGameNotes);
          newGameNotes[this.toVariableName(result.settingName)] = this.state.gameData.gameNotes[this.toVariableName(this.state.gameData.currentSetting)];
          delete newGameNotes[this.toVariableName(this.state.gameData.currentSetting)];
          let newCharactersArray = this.state.gameData.characters;
          newCharactersArray.forEach((character,charIndex) => {
            if(character.settingName === this.state.gameData.currentSetting) {
              newCharactersArray[charIndex].settingName = result.settingName;
            }
          })
          this.propagateChanges({...this.state.gameData, settings: newSettings, currentSetting: result.settingName, gameNotes: newGameNotes, characters: newCharactersArray});
        }
      }
    })
  }

  deleteSettingDialogue = () => {
    let questions = {}
    this.state.gameData.settings.forEach(settingName=>{
      if (settingName !== "All" && settingName !== "Main Campaign") {
        // Can't delete the All and the Main Campaign settings
        questions[settingName] = {name:settingName, inputType: "checkbox", default: false}
      }
    })
    // Shows a list of checkboxes, so the gm can delete multiple settings
    this.newDialogue({
      questions: {
        ...questions, 
        deleteCharacters: {name:"Delete characters from these settings?", inputType: "checkbox", default: false},
      },
      resultFunction:(result)=>{
        let deleteCharacters = result.deleteCharacters
        delete result.deleteCharacters
        if (Object.keys(result).length===0) return
        let changes = {settings: this.state.gameData.settings, characters: this.state.gameData.characters, gameNotes: this.state.gameData.gameNotes}
        for (const settingName in result) {
          if(result[settingName]) {
            changes.settings.splice(changes.settings.indexOf(settingName), 1)
            delete changes.gameNotes[this.toVariableName(settingName)]
            if (this.state.gameData.currentSetting === settingName) changes.currentSetting = "Main Campaign"
            // Go through array backwards so deleting items doesn't cause index issues
            for (let i = changes.characters.length-1; i >= 0; i--) {
              let character = changes.characters[i]
              if (character.settingName===settingName) {
                if (deleteCharacters) {
                  changes.characters.splice(i, 1)
                } else {
                  character.versionNumber++
                  character.settingName = "Main Campaign"
                }
              }
            }
          }
        }
        this.propagateChanges(changes)
      }
    })
  }

  propagateChanges = (changes) => {
    if (!this.isGM) return
    this.saveChanges(changes)
    this.sendSocketMessage({type: 'gameData', content: {
        ...changes
    }})
    this.setState(prevState=>({
      gameData: {
        ...prevState.gameData,
        ...changes
      }
    }))
  }

  saveChanges = (changes) => {
    if (!this.isGM) return
    this.sendSocketMessage({type: 'databaseSave', content: JSON.stringify({
      ...this.state.gameData,
      ...changes
    })})
  }

  newDialogue = (dialogue) => {
    this.setState({queuedDialogues: [...this.state.queuedDialogues, dialogue]})
  }

  dialogueResult = (result) => {
    let resultFunction = this.state.queuedDialogues[0]?.resultFunction
    this.dialogueResultFunction = ()=>resultFunction(result) // this gets called on the next state update
    this.setState({queuedDialogues: this.state.queuedDialogues.slice(1)})
  }

  cancelDialogue = (result) => {
    this.setState({queuedDialogues: this.state.queuedDialogues.slice(1)})
  }

  displayHelp = (pageName) => {
    this.newDialogue({
      title: cap(pageName),
      text: helpContents[pageName],
      questions: {},
      resultFunction: (result) => {}
    })
  }

  updateGameNotes = (currentNotes) => {
    let newNotes = this.state.gameData.gameNotes;
    newNotes[this.toVariableName(this.state.gameData.currentSetting)] = currentNotes;
    this.propagateChanges({gameNotes:newNotes});
  }

  collapseAllCharacters = () => {
    this.setState((prevState) => {
      let updatedCharacterStates = copyObject(prevState.myCharacterStates);
      updatedCharacterStates.forEach(characterState => {
        characterState.collapsed = true;
      });
      return {myCharacterStates:updatedCharacterStates};
    })
  }

  render () {
    let settingSelectHTML = this.state.gameData.settings.map(settingName=><option key={settingName} value={settingName}>{settingName}</option>)
    let charactersHTML = []
    this.state.gameData.characters.sort((a,b)=>a.id-b.id).forEach(character => {
      if (
        (this.isGM || (character.owner === this.myId)) && 
        ((character.settingName === "All") || (character.settingName === this.state.gameData.currentSetting) || (this.state.gameData.currentSetting === "All")) &&
        ((!this.isGM) || (this.state.gameData.charTypeFilter === "both") || ((this.state.gameData.charTypeFilter === "pc") && (character.isPC)) || ((this.state.gameData.charTypeFilter === "npc") && (!character.isPC)))
      ) {
        // only display the character if the player owns it (or is the gm)
        // only display the character if it belongs to the current settingName
        // for the GM only: only display if it passes the charTypeFilter (PC/NPC)
        let myCharacterState = {};
        let myCharacterStateIndex = 0;
        this.state.myCharacterStates.forEach((characterState,index) => {
          if(characterState.id === character.id) {
            myCharacterState = copyObject(characterState);
            myCharacterStateIndex = index;
          }
        });
        charactersHTML.push(<Character 
          key={character.id} 
          data={character} 
          editCharacters={this.editCharacters} 
          deleteCharacter={()=>this.deleteCharacter(character.id)} 
          duplicateCharacter={()=>this.duplicateCharacter(character.id)} 
          sendChat={(message, characterChanges)=>this.sendChat(message, characterChanges, character.id)} 
          sendGMChat={(message, characterChanges)=>this.sendGMChat(message, characterChanges, character.id)} 
          isGM={this.isGM} 
          ownedByGM={this.isGM && (character.owner === this.myId)} // also returns false for players that aren't gms
          players={this.isGM ? this.state.players : {}}
          settingsList={this.isGM ? this.state.gameData.settings : []}
          currentSetting={this.state.gameData.currentSetting}
          forcePlayMode={this.state.gameData.forcePlayMode}
          passInitiative={(characterChanges)=>this.passInitiative(characterChanges, character.id)}
          newDialogue={this.newDialogue}
          myCharacterState={myCharacterState}
          updateCharacterState={(changes)=>{this.setState((prevState) => {
            let updatedCharacterStates = copyObject(prevState.myCharacterStates);
            updatedCharacterStates[myCharacterStateIndex] = {...updatedCharacterStates[myCharacterStateIndex], ...changes};
            return {myCharacterStates:updatedCharacterStates};
          })}}
        />)
      }
    });
    let onlinePlayerIdsHTML = []
    this.state.onlinePlayerIds.forEach(playerId=>{
      onlinePlayerIdsHTML.push(
        <div key={playerId} className='online player-status'>
          <b>{this.state.players[playerId]?.name}</b>
          <i> online</i>
        </div>
      )
    })
    let offlinePlayersHTML = []
    if (this.isGM) {
      let offlinePlayers = Object.values(this.state.players).filter(p=>!this.state.onlinePlayerIds.includes(p.id))
      offlinePlayers.forEach(player=>{
        offlinePlayersHTML.push(
          <div key={player.id} className='offline player-status'>
            <b>{player.name}</b>
            <i> offline</i>
          </div>
        )
      })
    }
    let helpButtonsHTML = []
    Object.keys(helpContents).forEach((buttonName) => {
      helpButtonsHTML.push(
        <button key={buttonName} onClick={(e)=>{this.displayHelp(buttonName)}}>{cap(buttonName)}</button>
      );
    });
    // let editPlayerDataHTML = []
    // if (this.isGM) {
    //  this.state.globalData.players.sort((a,b)=>a.id-b.id).forEach((player, index)=>{
    //   editPlayerDataHTML.push(
    //     <div key={player.id+player.name}>
    //       <span>{player.id}</span>
    //       <span> {player.name} </span>
    //       <button onClick={()=>this.deletePlayerData(index)}>x</button>
    //     </div>
    //   )
    //  })
    // }
    return (
      <div className="game">
        <Helmet>
          <title>{GameName || ""} | Splintered Symmetry</title>
        </Helmet>
        <div className='column left chat-column'>
          <Chat 
            chatHistory={this.state.gameData.chatHistory}
            sendChat={this.sendChat}
            isGM={this.isGM}
            myId={this.myId}
            players={this.state.players}
          />
        </div>
        <div className='column center players-column'>
          <div className='settings header'>Settings</div>
          <button onClick={(e)=>{
            window.location.replace("/dashboard?test=1");
          }}>My Dashboard</button>
          <span>
            <label htmlFor='darkMode'>Dark Mode</label>
            <input type='checkbox' name='darkMode' checked={!!this.state.darkMode} onChange={(e)=>{
              this.setState({darkMode:e.target.checked})
              document.body.classList.toggle("darkmode",e.target.checked)
              localStorage.setItem('darkMode',e.target.checked);
            }} />
          </span>
          <span>
            <label htmlFor='whispering'>Whispering</label>
            <input type='checkbox' name='whispering' checked={!!this.state.whispering} onChange={(e)=>{
              this.setState({whispering:e.target.checked})
              document.body.classList.toggle("whispering",e.target.checked)
              localStorage.setItem('whispering',e.target.checked);
            }} />
          </span>
          <div className='players header'>Players</div>
          {onlinePlayerIdsHTML}
          {this.isGM && (offlinePlayersHTML)}
          {/* {this.isGM && (
            <span>
              <label htmlFor='showEditPlayerData'>Advanced</label>
              <input type='checkbox' name='showEditPlayerData' value={!!this.state.showEditPlayerData} onChange={(e)=>this.setState({showEditPlayerData:e.target.checked})} />
            </span>
          )} */}
          {/* {this.isGM && this.state.showEditPlayerData && (editPlayerDataHTML)} */}
          <br />
          <div className='initiative header'>
            Initiative
            {this.isGM && <button onClick={this.removeAllInitiative}>-</button>}
          </div>
          <Initiative 
            characters={this.state.gameData.characters}
            isGM={this.isGM}
            setCurrentInitiative={this.isGM && this.setCurrentInitiative}
            removeFromInitiative={this.isGM && this.removeFromInitiative}
            myID={this.myId}
            changeInitiative={this.changeInitiative}
            bumpInitiative={this.bumpInitiative}
          />
        </div>
        <div className='column right characters-column'>
          {this.isGM && (
            <div className="row">
              <button className="force-play toggle" onClick={this.toggleForcePlayMode}>{this.state.gameData.forcePlayMode ? "Force Play Mode: ON" : "Force Play Mode: OFF"}</button>
              <button className="clear-chat warning" onClick={this.clearChatHistory}>Clear Chat History</button>
            </div>
          )}
          {this.isGM && (
            <div className="row">
              <label htmlFor="current-setting">Current Setting: </label>
              <select name="current-setting" value={this.state.gameData.currentSetting} onChange={(e) => {this.propagateChanges({currentSetting: e.target.value})}}>{settingSelectHTML}</select>
              <button onClick={this.addSettingDialogue}>Add Setting</button>
              {this.state.gameData.currentSetting !== "All" && this.state.gameData.currentSetting !== "Main Campaign" && <button onClick={this.renameSettingDialogue}>Rename Current Setting</button>}
              <button className="warning" onClick={this.deleteSettingDialogue}>Delete Setting</button>
              <select value={this.state.gameData.charTypeFilter} onChange={(e) => {this.propagateChanges({charTypeFilter:e.target.value})}}>
                <option value="both">Show PCs & NPCs</option>
                <option value="pc">Show PCs Only</option>
                <option value="npc">Show NPCs Only</option>
              </select>
            </div>
          )}
          {this.isGM && (<h6>Setting Notes</h6>)}
          {this.isGM && (<textarea className="game-notes" rows="10" value={this.state.gameData.gameNotes[this.toVariableName(this.state.gameData.currentSetting)] || ""}
            onChange={(e)=>this.updateGameNotes(e.target.value)}/>)}
          <h6>Help</h6>
          <div className="row">
            {helpButtonsHTML}
          </div>
          <h6>Characters</h6>
          {this.isGM && <button className="collapser" onClick={this.collapseAllCharacters}>Collapse All</button>}
          {charactersHTML}
          {this.isGM && (
            <div className='row'>
              <button onClick={this.exportCharactersDialogue}>Export Characters</button>
            </div>
          )}
          {this.isGM && (
            <div className='row'>
              <button onClick={this.addCharacter}>Add Character</button>
              <span> or </span>
              <button onClick={this.importCharactersDialogue}>Import Characters</button>
            </div>
          )}
        </div>
        <Dialogue 
          title={this.state.queuedDialogues[0]?.title || ""}
          text={this.state.queuedDialogues[0]?.text || []}
          questions={this.state.queuedDialogues[0]?.questions} 
          resultFunction={this.dialogueResult} 
          cancelDialogue={this.cancelDialogue} 
        />
      </div>
    );
  }
}

const helpContents = {
  majorActions: ["Defend: Gain a bonus to PD and future defensive response actions",
                 "Grapple: Put an opponent in a hold or escape from a hold",
                 "Ready: Be ready to interrupt a specifid trigger condition",
                 "Recover: Reduce your TAP at the end of your turn",
                 "Shoot: Attack with a ranged weapon such as a bow or firearm",
                 "Strike: Attack with a melee weapon (or unarmed)",
                 "Suppressive Fire: Threaten a target with a ranged attack",
                 "Throw: Attack by throwing a weapon or object",
                 "Wait: Hold your major action for later"],
  directMinorActions: ["Activate Qi Ability: Use a qi ability that you have learned",
                      "Change Hands: Change which hands are wielding an object (or drop it)",
                      "Disengage (Move): Exit melee range with an opponent",
                      "Dismiss: End your ongoing spell or qi ability",
                      "Engage (Move): Enter melee range with an opponent",
                      "Focus: Gain a +1 bonus on your next action (restricted)",
                      "Maneuver (Move): Gain a tactical advantage",
                      "Observe: Make an Awareness test to gain information",
                      "Speak: Speak, shout, signal, or whisper up to 3 seconds",
                      "Stand (Move): Get up from a prone position",
                      "Sustain: Maintain your sustained spell/ability",
                      "Switch Firing Mode: Change the mode of your automatic weapon",
                      "Take Cover (Move): Gain a situational defensive bonus",
                      "Travel (Move): Go to a new location"],
  responseActions: ["Avoid (Move): Prevent someone from engaging you",
                    "Defensive Disarm*: Disarm an opponent attempting to attack you",
                    "Defensive Grab*: Grab an opponent attempting to attack you",
                    "Defensive Throw*: Throw an opponent attempting to attack you (1 stam)",
                    "Dodge (Move): Evade an attack or effect",
                    "Free Attack: Make an attack of opportunity (1 stam)",
                    "Intercept (Move): Oppose someone's move by getting in their way",
                    "Parry: Deflect an incoming attack",
                    "Pursue: Follow someone attempting to disengage or travel",
                    "* Requires Trait"],
  otherActions: ["Bind Wounds: Reduce a wound's bleed rate",
                 "Cast: Cast a spell",
                 "Draw: Draw a ready weapon",
                 "Pilot: Pilot a vehicle or ride an animal",
                 "Reload: Reload a weapon",
                 "Retrieve: Retrieve an item from a bag or pack"],
  rangedDifficulties: ["15 Mouse","14 Cat","13 Large Dog","12 Human","11 Horse","9 Elephant"]
};

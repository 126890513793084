import {cap} from './Character';
import {copyObject} from './Game';

export function Resources(props) {

    function setIncomeRank(val) {
        let newResources = copyObject(props.resources);
        newResources.incomeRank = Number(val);
        props.updateResources(newResources);
    }

    function setLifestyleRank(val) {
        let newResources = copyObject(props.resources);
        newResources.lifestyleRank = Number(val);
        props.updateResources(newResources);
    }

    function setNotes(val) {
        let newResources = copyObject(props.resources);
        newResources.notes = val;
        props.updateResources(newResources);
    }

    function updateMeleeWeaponProp(idx,p,value) {
        let newResources = copyObject(props.resources);
        if(p === "weightRank") value = Math.max(1,Math.min(3,value));
        newResources.meleeWeapons[idx][p] = value;
        props.updateResources(newResources);
    }

    function updateRangedWeaponProp(idx,p,value) {
        let newResources = copyObject(props.resources);
        if(p === "weightRank") value = Math.max(1,Math.min(3,value));
        newResources.rangedWeapons[idx][p] = value;
        props.updateResources(newResources);
    }

  function addMeleeWeapon(idx) {
        let newResources = copyObject(props.resources);
        newResources.meleeWeapons.push(copyObject(meleeWeaponData[idx]));
        props.updateResources(newResources);
    }

    function addRangedWeapon(idx) {
        let newResources = copyObject(props.resources);
        let newWeapon = {};
        newWeapon = copyObject(rangedWeaponData[idx]);
        newWeapon.ammo = "none";
        newResources.rangedWeapons.push(newWeapon);
        props.updateResources(newResources);
    }

    function removeMeleeWeapon(idx) {
        let newResources = copyObject(props.resources);
        newResources.meleeWeapons.splice(idx,1);
        props.updateResources(newResources);
    }

    function removeRangedWeapon(idx) {
        let newResources = copyObject(props.resources);
        newResources.rangedWeapons.splice(idx,1);
        props.updateResources(newResources);
    }

    function updateAmmoProp(idx,p,value) {
        let newResources = copyObject(props.resources);
        newResources.ammo[idx][p] = value;
        props.updateResources(newResources);
    }

    function addAmmo(idx) {
        let newResources = copyObject(props.resources);
        newResources.ammo.push(copyObject(ammoData[idx]));
        props.updateResources(newResources);
    }

    function removeAmmo(idx) {
        let newResources = copyObject(props.resources);
        newResources.ammo.splice(idx,1);
        newResources.rangedWeapons.forEach((w,wIndex) => {
          if(w.ammo >= idx) newResources.rangedWeapons[wIndex].ammo--;
          if(w.ammo === idx - 1) newResources.rangedWeapons[wIndex].ammo = "none";
        });
        props.updateResources(newResources);
    }

    function updateArmorProp(idx,p,value) {
        let newResources = copyObject(props.resources);
        newResources.armor[idx][p] = value;
        props.updateResources(newResources);
    }

    function addArmor(idx) {
        let newResources = copyObject(props.resources);
        newResources.armor.push(copyObject(armorData[idx]));
        props.updateResources(newResources);
    }

    function removeArmor(idx) {
        let newResources = copyObject(props.resources);
        newResources.armor.splice(idx,1);
        props.updateResources(newResources);
    }

    let ammoSelectHTML = [<option key="none" value="none">None</option>];
    props.resources.ammo.forEach((a,idx) => {
      ammoSelectHTML.push(
        <option key={idx} value={idx}>{a.name}</option>
      );
    });

    let meleeTypeOptionsHTML = [<option key="unknown" value="unknown">Unknown</option>];
    meleeTypes.forEach(w => {
      meleeTypeOptionsHTML.push(<option key={w} value={w}>{cap(w)}</option>)
    });
    let rangedTypeOptionsHTML = [<option key="unknown" value="unknown">Unknown</option>];
    rangedTypes.forEach(w => {
      rangedTypeOptionsHTML.push(<option key={w} value={w}>{cap(w)}</option>)
    });

    let meleeWeaponsHTML = [];
    props.resources.meleeWeapons.forEach((w,idx) => {
        meleeWeaponsHTML.push(
            <div key={idx}>
                <button onClick={() => removeMeleeWeapon(idx)}>-</button>
                <input type="text" className="entry name" value={w.name} onChange={(e) => updateMeleeWeaponProp(idx,"name",e.target.value)}/>
                <input type="number" className="entry base" value={w.damage} onChange={(e) => updateMeleeWeaponProp(idx,"damage",Number(e.target.value))} />
                <input type="number" className="entry ap" value={w.ap} onChange={(e) => updateMeleeWeaponProp(idx,"ap",Number(e.target.value))}/>
                <select className="entry type" value={w.type} onChange={(e) => updateMeleeWeaponProp(idx,"type",e.target.value)}>
                    {meleeTypeOptionsHTML}
                </select>
                <input type="checkbox" checked={w.twoHanded} onChange={(e) => updateMeleeWeaponProp(idx,"twoHanded",e.target.checked)}/>
                <select className="weightRank" value={w.weightRank} onChange={(e) => updateMeleeWeaponProp(idx,"weightRank",Number(e.target.value))}>
                  <option value="light">Light</option>
                  <option value="medium">Medium</option>
                  <option value="heavy">Heavy</option>
                </select>
                {!props.editMode && <button className="attack strike" onClick={() => props.strike(w)}>Strike</button>}
            </div>
        );        
    });

    let rangedWeaponsHTML = [];
    props.resources.rangedWeapons.forEach((w,idx) => {
        rangedWeaponsHTML.push(
            <div key={idx}>
                <button onClick={() => removeRangedWeapon(idx)}>-</button>
                <input type="text" className="entry name" value={w.name} onChange={(e) => updateRangedWeaponProp(idx,"name",e.target.value)}/>
                <input type="number" className="entry base" value={w.damage} onChange={(e) => updateRangedWeaponProp(idx,"damage",Number(e.target.value))} />
                <input type="number" className="entry ap" value={w.ap} onChange={(e) => updateRangedWeaponProp(idx,"ap",Number(e.target.value))}/>
                <select className="entry type" value={w.type} onChange={(e) => updateRangedWeaponProp(idx,"type",e.target.value)}>
                    {rangedTypeOptionsHTML}
                </select>
                <input type="checkbox" checked={w.thrown} onChange={(e) => updateRangedWeaponProp(idx,"thrown",e.target.checked)}/>
                <input type="checkbox" checked={w.brawn} onChange={(e) => updateRangedWeaponProp(idx,"brawn",e.target.checked)}/>
                <input type="checkbox" checked={w.twoHanded} onChange={(e) => updateRangedWeaponProp(idx,"twoHanded",e.target.checked)}/>
                <select className="wt select" value={w.weightRank} onChange={(e) => updateRangedWeaponProp(idx,"reload",e.target.value)}>
                  <option value="light">L</option>
                  <option value="medium">M</option>
                  <option value="heavy">H</option>
                </select>
                <select className="reload select" value={w.reload} onChange={(e) => updateRangedWeaponProp(idx,"reload",e.target.value)}>
                  <option value="manual">Manual</option>
                  <option value="lever">Lever</option>
                  <option value="pump">Pump</option>
                  <option value="auto">Auto</option>
                  <option value="multi">Multi</option>
                </select>
                <input type="number" className="entry range" value={w.range} onChange={(e) => updateRangedWeaponProp(idx,"range",e.target.value)}/>
                <select className="ammo select" value={w.ammo} onChange={(e) => updateRangedWeaponProp(idx,"ammo",e.target.value)}>
                  {ammoSelectHTML}
                </select>
                {!props.editMode && !(w.thrown) && <button className="attack shoot" onClick={() => props.shoot(w)}>Shoot</button>}
                {!props.editMode && w.thrown && <button className="attack throw" onClick={() => props.throw(w)}>Throw</button>}
            </div>
        );        
    });

    let ammoHTML = [];
    props.resources.ammo.forEach((a,idx) => {
      ammoHTML.push(
        <div key={idx}>
          <button onClick={() => removeAmmo(idx)}>-</button>
          <input type="text" className="entry name" value={a.name} onChange={(e) => updateAmmoProp(idx,"name",e.target.value)}/>
          <input type="number" className="entry amount" value={a.amount} onChange={(e) => updateAmmoProp(idx,"amount",Number(e.target.value))} />
          <input type="number" className="entry bonus-damage" value={a.bonusDamage} onChange={(e) => updateAmmoProp(idx,"bonusDamage",Number(e.target.value))} />
          <input type="number" className="entry bonus-ap" value={a.bonusAP} onChange={(e) => updateAmmoProp(idx,"bonusAP",Number(e.target.value))} />
          <input type="number" className="entry bonus-range" value={a.bonusRange} onChange={(e) => updateAmmoProp(idx,"bonusRange",Number(e.target.value))} />
          <input type="text" className="entry notes" value={a.notes} onChange={(e) => updateAmmoProp(idx,"notes",e.target.value)}/>
        </div>
      );
    });

    let armorHTML = [];
    props.resources.armor.forEach((a,idx) => {
        armorHTML.push(
            <div key={idx}>
                <button onClick={() => removeArmor(idx)}>-</button>
                <input type="text" className="entry name" value={a.name} onChange={(e) => updateArmorProp(idx,"name",e.target.value)}/>
                <input type="number" className="entry value" value={a.value} onChange={(e) => updateArmorProp(idx,"value",Number(e.target.value))} />
                <input type="number" className="entry cover" value={a.cover} onChange={(e) => updateArmorProp(idx,"cover",Number(e.target.value))} />
                <select type="text" className="entry location" value={a.location} onChange={(e) => updateArmorProp(idx,"location",e.target.value)}>
                    <option value="torso">Torso</option>
                    <option value="head">Head</option>
                    <option value="arms">Arms</option>
                    <option value="legs">Legs</option>
                </select>
                <input type="checkbox" checked={a.equipped} onChange={(e) => updateArmorProp(idx,"equipped",e.target.checked)}/>
            </div>
        );        
    });

    let addMeleeHTML = [<option key="choose" disabled value="none">Choose One</option>];
    meleeWeaponData.forEach((w,idx) => {
      addMeleeHTML.push(<option key={idx} value={idx} title={"type: " + w.type}>{w.name}</option>);
    });

    let addRangedHTML = [<option key="choose" disabled value="none">Choose One</option>];
    rangedWeaponData.forEach((w,idx) => {
      addRangedHTML.push(<option key={idx} value={idx} title={"type: " + w.type}>{w.name}</option>);
    });

    let addAmmoHTML = [<option key="choose" disabled value="none">Choose One</option>];
    ammoData.forEach((a,idx) => {
      addAmmoHTML.push(<option key={idx} value={idx}>{a.name}</option>);
    });

    let addArmorHTML = [<option key="choose" disabled value="none">Choose One</option>];
    armorData.forEach((a,idx) => {
      addArmorHTML.push(<option key={idx} value={idx}>{a.name}</option>);
    });

    return(
        <div className="resources container">
            <div className="weapons container">
                <div className="sub-header">---Melee Weapons---</div>
                <div>
                    <div className="header delete"></div>
                    <div className="header name">Name</div>
                    <div className="header base">Base</div>
                    <div className="header ap">AP</div>
                    <div className="header type">Type</div>
                    <div className="header two-handed">2H</div>
                    <div className="header weightRank">Wt Rank</div>
                    {!props.editMode && <div className="header attack">Attack</div>}
                </div>
                {meleeWeaponsHTML}
                Add Melee Weapon: 
                <select className="add" defaultValue="none" onInput={(e) => {addMeleeWeapon(Number(e.target.value));e.target.value = "none";}}>
                  {addMeleeHTML}
                </select>
            </div>
            <div className="weapons container">
                <div className="sub-header">---Ranged Weapons---</div>
                <div>
                    <div className="header delete"></div>
                    <div className="header name">Name</div>
                    <div className="header base">Base</div>
                    <div className="header ap">AP</div>
                    <div className="header type">Type</div>
                    <div className="header thrown">Thr</div>
                    <div className="header brawn icon"></div>
                    <div className="header two-handed">2H</div>
                    <div className="header wt">Wt</div>
                    <div className="header reload">Reload</div>
                    <div className="header range">Range</div>
                    <div className="header ammo">Ammo</div>
                    {!props.editMode && <div className="header attack">Attack</div>}
                </div>
                {rangedWeaponsHTML}
                Add Ranged Weapon: 
                <select className="add" defaultValue="none" onInput={(e) => {addRangedWeapon(Number(e.target.value));e.target.value="none";}}>
                  {addRangedHTML}
                </select>
            </div>
            <div className="ammo container">
              <div className="sub-header">---Ammo---</div>
                <div>
                    <div className="header delete"></div>
                    <div className="header name">Name</div>
                    <div className="header amount">Amount</div>
                    <div className="header bonus-damage">Dmg Adj</div>
                    <div className="header bonus-ap">AP Adj</div>
                    <div className="header bonus-range">Range Adj</div>
                    <div className="header notes">Notes</div>
              </div>
              {ammoHTML}
              Add Ammo: 
                <select className="add" defaultValue="none" onInput={(e) => {addAmmo(Number(e.target.value));e.target.value = "none";}}>
                  {addAmmoHTML}
                </select>
            </div>
            <div className="armor container">
                <div className="sub-header">---Armor---</div>
                <div>
                    <div className="header delete"></div>
                    <div className="header name">Name</div>
                    <div className="header value">Value</div>
                    <div className="header cover">Cover</div>
                    <div className="header location">Location</div>
                    <div className="header equipped">Equipped</div>
                </div>
                {armorHTML}
                Add Armor: 
                <select className="add" defaultValue="none" onInput={(e) => {addArmor(Number(e.target.value));e.target.value = "none";}}>
                  {addArmorHTML}
                </select>
            </div>
            <div className="other container">
              <div className="sub-header">---Other---</div>
              <div className="col-1">
                  <div>
                      <label className="resources rank">Income Rank:</label>
                      <input type="number" value={props.resources.incomeRank} onChange={(e) => setIncomeRank(e.target.value)} />
                  </div>
                  <div>
                      <label className="resources rank">Lifestyle Rank:</label>
                      <input type="number" value={props.resources.lifestyleRank} onChange={(e) => setLifestyleRank(e.target.value)} />
                  </div>
              </div>
              <div className="col-2">
                  <textarea value={props.resources.notes} onChange={(e) => setNotes(e.target.value)} />
              </div>
            </div>
        </div>
    );
}

const meleeTypes = ["axe","chainWeapon","club","hammer","knife","shield","spear","staff","sword","unarmed"];
const rangedTypes = ["artillery","automatics","axe","bow","chainWeapon","crossbow","explosives","hammer","handgun","knife","longarms","sling","spear"];

const meleeWeaponData = [
  {name:"Punch/Elbow",damage:0,ap:0,type:"unarmed",twoHanded:false,weightRank:"light"},
  {name:"Kick/Knee",damage:2,ap:0,type:"unarmed",twoHanded:false,weightRank:"medium"},
  {name:"Hand Axe",damage:9,ap:2,type:"axe",twoHanded:false,weightRank:"medium"},
  {name:"Battle Axe",damage:12,ap:2,type:"axe",twoHanded:false,weightRank:"heavy"},
  {name:"Great Axe",damage:14,ap:2,type:"axe",twoHanded:true,weightRank:"heavy"},
  {name:"Whip",damage:1,ap:0,type:"chainWeapon",twoHanded:false,weightRank:"light"},
  {name:"Mace & Chain",damage:8,ap:1,type:"chainWeapon",twoHanded:false,weightRank:"medium"},
  {name:"Club",damage:3,ap:0,type:"club",twoHanded:false,weightRank:"medium"},
  {name:"Spiked Mace",damage:7,ap:1,type:"club",twoHanded:false,weightRank:"medium"},
  {name:"Great Club",damage:10,ap:0,type:"club",twoHanded:true,weightRank:"heavy"},
  {name:"Hammer",damage:5,ap:2,type:"hammer",twoHanded:false,weightRank:"light"},
  {name:"Maul",damage:12,ap:2,type:"hammer",twoHanded:true,weightRank:"heavy"},
  {name:"Knife",damage:6,ap:1,type:"knife",twoHanded:false,weightRank:"light"},
  {name:"Small Shield",damage:1,ap:0,type:"shield",twoHanded:false,weightRank:"light"},
  {name:"Large Shield",damage:1,ap:0,type:"shield",twoHanded:false,weightRank:"medium"},
  {name:"Tower Shield",damage:1,ap:0,type:"shield",twoHanded:false,weightRank:"heavy"},
  {name:"Short Spear",damage:8,ap:1,type:"spear",twoHanded:false,weightRank:"light"},
  {name:"Long Spear",damage:10,ap:1,type:"spear",twoHanded:true,weightRank:"medium"},
  {name:"Polearm",damage:12,ap:1,type:"spear",twoHanded:true,weightRank:"heavy"},
  {name:"Staff",damage:2,ap:0,type:"staff",twoHanded:true,weightRank:"light"},
  {name:"Short Sword",damage:8,ap:1,type:"sword",twoHanded:false,weightRank:"medium"},
  {name:"Long Sword",damage:11,ap:1,type:"sword",twoHanded:false,weightRank:"heavy"},
  {name:"Great Sword",damage:13,ap:1,type:"sword",twoHanded:true,weightRank:"heavy"},
]

const rangedWeaponData = [
  {name:"Sling",damage:4,ap:0,type:"sling",thrown:true,weightRank:"light",brawn:true,twoHanded:false,reload:"manual",range:20},
  {name:"Short Bow",damage:15,ap:4,type:"bow",thrown:false,weightRank:"light",brawn:false,twoHanded:true,reload:"manual",range:25},
  {name:"Long Bow",damage:20,ap:6,type:"bow",thrown:false,weightRank:"medium",brawn:false,twoHanded:true,reload:"manual",range:30},
  {name:"Great Bow",damage:25,ap:6,type:"bow",thrown:false,weightRank:"heavy",brawn:false,twoHanded:true,reload:"manual",range:35},
  {name:"Light Crossbow",damage:20,ap:8,type:"crossbow",thrown:false,weightRank:"light",brawn:false,twoHanded:true,reload:"manual",range:25},
  {name:"Heavy Crossbow",damage:25,ap:10,type:"crossbow",thrown:false,weightRank:"medium",brawn:false,twoHanded:true,reload:"manual",range:30},
  {name:"Light Handgun",damage:20,ap:10,type:"handgun",thrown:false,weightRank:"light",brawn:false,twoHanded:false,reload:"auto",range:20},
  {name:"Medium Handgun",damage:25,ap:10,type:"handgun",thrown:false,weightRank:"light",brawn:false,twoHanded:false,reload:"auto",range:30},
  {name:"Heavy Handgun",damage:30,ap:10,type:"handgun",thrown:false,weightRank:"light",brawn:false,twoHanded:false,reload:"auto",range:40},
  {name:"Light Rifle",damage:25,ap:15,type:"longarms",thrown:false,weightRank:"medium",brawn:false,twoHanded:true,reload:"lever",range:100},
  {name:"Heavy Rifle",damage:30,ap:15,type:"longarms",thrown:false,weightRank:"medium",brawn:false,twoHanded:true,reload:"lever",range:500},
  {name:"Shotgun",damage:35,ap:15,type:"longarms",thrown:false,weightRank:"medium",brawn:false,twoHanded:true,reload:"pump",range:30},
  {name:"Snper Rifle",damage:40,ap:20,type:"longarms",thrown:false,weightRank:"heavy",brawn:false,twoHanded:true,reload:"auto",range:1000},
  {name:"Submachine Gun",damage:25,ap:10,type:"automatics",thrown:false,weightRank:"medium",brawn:false,twoHanded:true,reload:"multi",range:500},
  {name:"Automatic Rifle",damage:30,ap:15,type:"automatics",thrown:false,weightRank:"medium",brawn:false,twoHanded:true,reload:"multi",range:700},
  {name:"Machine Gun",damage:35,ap:15,type:"automatics",thrown:false,weightRank:"heavy",brawn:false,twoHanded:true,reload:"multi",range:1000},
  {name:"Hand Grenade",damage:35,ap:5,type:"explosives",thrown:true,weightRank:"light",brawn:false,twoHanded:false,reload:"manual",range:10},
  {name:"Throwing Axe",damage:5,ap:2,type:"axe",thrown:true,weightRank:"light",brawn:true,twoHanded:false,reload:"manual",range:10},
  {name:"Throwing Knife",damage:4,ap:1,type:"knife",thrown:true,weightRank:"light",brawn:true,twoHanded:false,reload:"manual",range:10},
  {name:"Throwing Spear",damage:5,ap:1,type:"spear",thrown:true,weightRank:"light",brawn:true,twoHanded:false,reload:"manual",range:15}
]

const ammoData = [
  {name:"Sling Bullet",amount:12,bonusDamage:0,bonusAP:0,bonusRange:0,notes:""},
  {name:"Arrows",amount:20,bonusDamage:0,bonusAP:0,bonusRange:0,notes:""},
  {name:"Bolts",amount:20,bonusDamage:0,bonusAP:0,bonusRange:0,notes:""},
  {name:"L Handgun Mag",amount:30,bonusDamage:0,bonusAP:0,bonusRange:0,notes:""},
  {name:"M Handgun Mag",amount:21,bonusDamage:0,bonusAP:0,bonusRange:0,notes:""},
  {name:"H Handgun Mag",amount:13,bonusDamage:0,bonusAP:0,bonusRange:0,notes:""},
  {name:"Revolver Ammo",amount:6,bonusDamage:0,bonusAP:0,bonusRange:0,notes:""},
  {name:"L Rifle Mag",amount:5,bonusDamage:0,bonusAP:0,bonusRange:0,notes:""},
  {name:"H Rifle Mag",amount:10,bonusDamage:0,bonusAP:0,bonusRange:0,notes:""},
  {name:"Shotgun Slugs",amount:8,bonusDamage:0,bonusAP:0,bonusRange:0,notes:""},
  {name:"Shotgun Shot",amount:8,bonusDamage:0,bonusAP:-15,bonusRange:-5,notes:"+1 to Attack"},
  {name:"Sniper Rifle Mag",amount:10,bonusDamage:0,bonusAP:0,bonusRange:0,notes:""},
  {name:"Sub Gun Mag",amount:30,bonusDamage:0,bonusAP:0,bonusRange:0,notes:""},
  {name:"Auto Rifle Mag",amount:30,bonusDamage:0,bonusAP:0,bonusRange:0,notes:""},
  {name:"Machine Gun Belt",amount:50,bonusDamage:0,bonusAP:0,bonusRange:0,notes:""},
  {name:"Flashbang",amount:1,bonusDamage:-35,bonusAP:0,bonusRange:0,notes:""},
  {name:"Frag Grenade",amount:1,bonusDamage:0,bonusAP:0,bonusRange:0,notes:""},
  {name:"Throwing Axe",amount:1,bonusDamage:0,bonusAP:0,bonusRange:0,notes:""},
  {name:"Throwing Knife",amount:1,bonusDamage:0,bonusAP:0,bonusRange:0,notes:""},
  {name:"Throwing Spear",amount:1,bonusDamage:0,bonusAP:0,bonusRange:0,notes:""},
]

const armorData = [
  {name:"Chain mail",location:"torso",value:10,cover:3,equipped:false},
  {name:"Chain hood",location:"head",value:10,cover:2,equipped:false},
  {name:"Plate mail",location:"torso",value:18,cover:3,equipped:false},
  {name:"Plate helm",location:"head",value:18,cover:3,equipped:false},
  {name:"Kevlar Vest",location:"torso",value:25,cover:2,equipped:false},
  {name:"Kevlar Helmet",location:"head",value:25,cover:2,equipped:false}
]
export function IP(props) {

    function setTotalIP(val) {
        let newIP = props.ip;
        newIP.total = val;
        props.updateIP(newIP);
    }

    function getRemainingIP() {
        return props.ip.total - props.ip.aspects - props.ip.skills - 
            props.ip.traits - props.ip.spells - props.ip.resources;
    }

    return <div className="ip container">
        <div className="sub-header">---IP---</div>
        <div>
            <label className="short-label">Total:</label>
            {props.isGM && props.isPC && <input type="number" value={props.ip.total} onChange={(e) => setTotalIP(e.target.value)}/>}
            {!props.isGM && props.isPC && <span>{props.ip.total}</span>}
            {!props.isPC && <span>{props.ip.aspects + props.ip.skills + props.ip.traits + props.ip.spells + props.ip.resources}</span>}
        </div>
        {props.isPC && <div>
            <label>Remaining:</label>
            <span>{getRemainingIP()}</span>
        </div>}
        <div className="sub-header">---Spent---</div>
        <div className="ip-category">
            <label>Aspects:</label>
            <span>{props.ip.aspects}</span>
        </div>
        <div className="ip-category">
            <label>Skills:</label>
            <span>{props.ip.skills}</span>
        </div>
        <div className="ip-category">
            <label>Traits:</label>
            <span>{props.ip.traits}</span>
        </div>
        <div className="ip-category">
            <label>Spells:</label>
            <span>{props.ip.spells}</span>
        </div>
        <div className="ip-category">
            <label>Resources:</label>
            <span>{props.ip.resources}</span>
        </div>
    </div>
}
import React from 'react';
import Footer from './Footer';
import Nav from './Nav';
import './main.css';
import wasp from './images/PW0002_Wasp.png';
import { Helmet } from 'react-helmet';


export default class Events extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render () {
    return (
        <>
            <Nav/>
            <Helmet>
                <title>Events | Splintered Symmetry</title>
                <link rel="shortcut icon" href="./images/SS Icon.png" type="image/x-icon" />
                <meta name="description" content="We were at SaltCon in March. Thomas ran a handful of playtest sessions to allow players to
                experience the game first-hand and provide feedback." />
            </Helmet>
            <main>
              <div className="rotating-banner">
                <div className="banner-panel">
                  <div className="banner-image" style={{backgroundImage: `url(${wasp})`}}></div>
                  <div className="banner-text">Events</div>
                </div>
              </div>
              <p>We were at <a href="https://saltcon.com/">SaltCon</a> in March 2022. Thomas ran a handful of playtest sessions to allow players to
                experience the game first-hand and provide feedback. Thomas also ran some games at <a href="https://strategicon.net">Strategicon</a> Gateway 2023
                over Labor Day weekend.</p>
            </main>
            <Footer />
        </>
    )
  }
}
import './Character.css';
import React from 'react';
import {Status} from './Status';
import {Aspects} from './Aspects';
import {Skills} from './Skills';
import {Actions} from './Actions';
import {Traits} from './Traits';
import {Dependents} from './Dependents';
import {Spells, spellData, finesseOptions, powerOptionsMax, ritualDetails, spellDurations} from './Spells';
import {Resources} from './Resources';
import {IP} from './IP';
import {Notes} from './Notes';
import {NPCTools} from './NPCTools';
import {copyObject} from './Game';

export class Character extends React.Component{
  constructor(props){
    super(props);
    this.state = props.data;
    //now validate the data, making sure all required parts are there
    if(!this.state.name) this.state.name = "Character Name";
    if(!this.state.settingName) this.state.settingName = "Main Campaign";
    if(!this.state.editMode) this.state.editMode = false;
    if(!this.state.selectedTab) this.state.selectedTab = "main";
    if(this.state.status && this.state.status.physicalDefense) { // if using old version that wasn't an array
      this.state.status = [{
        physicalDefense:this.state.status.physicalDefense,
        positiveQi:this.state.status.positiveQi,
        negativeQi:this.state.status.negativeQi,
        stamina:this.state.status.stamina,
        foresight:0,
        bloodLoss:this.state.status.bloodLoss,
        tap:this.state.status.tap,
        exhaustion:this.state.status.exhaustion,
        wounds:this.state.status.wounds,
        bleedRate:this.state.status.bleedRate,
        totalPainPenalty:this.state.status.totalPainPenalty
      }];
    }
    if(!this.state.status) this.state.status = [{}];
    if(!this.state.status[0].physicalDefense) this.state.status[0].physicalDefense = 0;
    if(!this.state.status[0].positiveQi) this.state.status[0].positiveQi = 0;
    if(!this.state.status[0].negativeQi) this.state.status[0].negativeQi = 0;
    if(!this.state.status[0].stamina) this.state.status[0].stamina = 0;
    if(!this.state.status[0].foresight) this.state.status[0].foresight = 0;
    if(!this.state.status[0].bloodLoss) this.state.status[0].bloodLoss = 0;
    if(!this.state.status[0].tap) this.state.status[0].tap = 0;
    if(!this.state.status[0].exhaustion) this.state.status[0].exhaustion = 0;
    if(!this.state.status[0].wounds) this.state.status[0].wounds = [];
    if(!this.state.status[0].bleedRate) this.state.status[0].bleedRate = 0;
    if(!this.state.status[0].totalPainPenalty) this.state.status[0].totalPainPenalty = 0;
    if(!this.state.status[0].movement) this.state.status[0].movement = 6;
    if(!this.state.status[0].overallStatus) this.state.status[0].overallStatus = "good";
    if(!this.state.aspects) this.state.aspects = {};
    if(!this.state.aspects.brawn) this.state.aspects.brawn = {base:3,bonus:0};
    if(!this.state.aspects.toughness) this.state.aspects.toughness = {base:3,bonus:0};
    if(!this.state.aspects.agility) this.state.aspects.agility = {base:3,bonus:0};
    if(!this.state.aspects.reflex) this.state.aspects.reflex = {base:3,bonus:0};
    if(!this.state.aspects.awareness) this.state.aspects.awareness = {base:3,bonus:0};
    if(this.state.aspects.cleverness) {
      this.state.aspects.intelligence = this.state.aspects.cleverness;
      delete this.state.aspects.cleverness;
    }
    if(!this.state.aspects.intelligence) this.state.aspects.intelligence = {base:3,bonus:0};
    if(!this.state.aspects.serenity) this.state.aspects.serenity = {base:3,bonus:0};
    if(!this.state.aspects.impression) this.state.aspects.impression = {base:3,bonus:0};
    if(!this.state.skills) this.state.skills = {};
    if(!this.state.skills.trickster) this.state.skills.trickster = {base:3,bonus:0};
    if(!this.state.skills.artist) this.state.skills.artist = {base:3,bonus:0};
    if(!this.state.skills.athlete) this.state.skills.athlete = {base:3,bonus:0};
    if(!this.state.skills.burglar) this.state.skills.burglar = {base:3,bonus:0};
    if(!this.state.skills.diplomat) this.state.skills.diplomat = {base:3,bonus:0};
    if(!this.state.skills.engineer) this.state.skills.engineer = {base:3,bonus:0};
    if(!this.state.skills.fighter) this.state.skills.fighter = {base:3,bonus:0};
    if(!this.state.skills.investigator) this.state.skills.investigator = {base:3,bonus:0};
    if(!this.state.skills.mage) this.state.skills.mage = {base:3,bonus:0};
    if(!this.state.skills.medic) this.state.skills.medic = {base:3,bonus:0};
    if(!this.state.skills.performer) this.state.skills.performer = {base:3,bonus:0};
    if(!this.state.skills.pilot) this.state.skills.pilot = {base:3,bonus:0};
    if(!this.state.skills.programmer) this.state.skills.programmer = {base:3,bonus:0};
    if(!this.state.skills.scholar) this.state.skills.scholar = {base:3,bonus:0};
    if(!this.state.skills.sharpshooter) this.state.skills.sharpshooter = {base:3,bonus:0};
    if(!this.state.skills.survivalist) this.state.skills.survivalist = {base:3,bonus:0};
    if(this.state.skills.linguist) delete this.state.skills.linguist;
    if(this.state.specialties) delete this.state.specialties
    if(!this.state.resources) this.state.resources = {};
    if(!this.state.resources.incomeRank) this.state.resources.incomeRank = 0;
    if(!this.state.resources.lifestyleRank) this.state.resources.lifestyleRank = 0;
    if(!this.state.resources.meleeWeapons) this.state.resources.meleeWeapons = [];
    if(!this.state.resources.rangedWeapons) this.state.resources.rangedWeapons = [];
    if(!this.state.resources.ammo) this.state.resources.ammo = [];
    if(!this.state.resources.armor) this.state.resources.armor = [];
    if(!this.state.resources.notes) this.state.resources.notes = "";
    if(!this.state.spells) this.state.spells = {};
    if(!this.state.spells.basic) this.state.spells.basic = {};
    if(!this.state.spells.advanced) this.state.spells.advanced = {};
    if(!this.state.spells.epic) this.state.spells.epic = {};
    if(!this.state.traits) this.state.traits = {};
    if(!this.state.traits.natural) this.state.traits.natural = {};
    if(!this.state.traits.qi) this.state.traits.qi = {};
    if(!this.state.traits.epic) this.state.traits.epic = {};
    if(!this.state.traits.roleplaying) this.state.traits.roleplaying = {};
    if(!this.state.traits.bestowed) this.state.traits.bestowed = {};
    if(this.state.traits.qi.qiWriting) delete this.state.traits.qi.qiWriting; // remove obsolete trait
    if(this.state.traits.natural.eagleEye) delete this.state.traits.natural.eagleEye; // remove obsolete trait
    if(this.state.traits.natural.perfectAmbidexterity) delete this.state.traits.natural.perfectAmbidexterity; // remove obsolete trait
    if(this.state.traits.natural.greatBrute) delete this.state.traits.natural.greatBrute; // remove obsolete trait
    if(this.state.traits.natural.greatQuickness) delete this.state.traits.natural.greatQuickness; // remove obsolete trait
    if(this.state.traits.natural.greatFortitude) delete this.state.traits.natural.greatFortitude; // remove obsolete trait
    if(this.state.traits.natural.greatTenacity) delete this.state.traits.natural.greatTenacity; // remove obsolete trait
    if(this.state.traits.natural.greatSturdiness) delete this.state.traits.natural.greatSturdiness; // remove obsolete trait
    if(this.state.traits.natural.greatSwiftness) delete this.state.traits.natural.greatSwiftness; // remove obsolete trait
    if(this.state.traits.qi.mageHunter) delete this.state.traits.qi.mageHunter; // remove obsolete trait
    if(this.state.traits.qi.greaterMageHunter) delete this.state.traits.qi.greaterMageHunter; // remove obsolete trait
    if(this.state.traits.qi.spellDominance) delete this.state.traits.qi.spellDominance; // remove obsolete trait
    if(this.state.traits.qi.greaterSpellDominance) delete this.state.traits.qi.greaterSpellDominance; // remove obsolete trait
    if(this.state.traits.epic.epicMageHunter) delete this.state.traits.epic.epicMageHunter; // remove obsolete trait
    if(this.state.traits.epic.supremeMageHunter) delete this.state.traits.epic.supremeMageHunter; // remove obsolete trait
    if(this.state.traits.epic.epicSpellDominance) delete this.state.traits.epic.epicSpellDominance; // remove obsolete trait
    if(this.state.traits.epic.supremeSpellDominance) delete this.state.traits.epic.supremeSpellDominance; // remove obsolete trait
    if(this.state.traits.qi.shadowShards) { //replace deprecated ShadowShards trait with ShadowClaws
      delete this.state.traits.qi.shadowShards;
      this.state.traits.qi.shadowClaws = true;
    }
    if(this.state.traits.bestowed.greaterTimeShift) { //replace renamed trait
      delete this.state.traits.bestowed.greaterTimeShift;
      this.state.traits.bestowed.epicTimeShift = true;
    }
    if(this.state.traits.bestowed.lesserTimeShift) { //replace renamed trait
      delete this.state.traits.bestowed.lesserTimeShift;
      this.state.traits.bestowed.timeShift = true;
    }
    if(this.state.traits.epic.detailedSurvey) { //replace renamed trait
      delete this.state.traits.epic.detailedSurvey;
      this.state.traits.epic.supremeSurvey = true;
    }
    if(this.state.traits.epic.epicMissileExplosion) { //replace renamed trait
      delete this.state.traits.epic.epicMissileExplosion;
      this.state.traits.epic.supremeExplosion = true;
    }
    if(this.state.traits.epic.epicDeepEmpathy) { //replace renamed trait
      delete this.state.traits.epic.epicDeepEmpathy;
      this.state.traits.epic.supremeEmpathy = true;
    }
    if(!this.state.dependents) this.state.dependents = {};
    if(!this.state.dependents.basePhysicalDefense) this.state.dependents.basePhysicalDefense = 0;
    if(!this.state.dependents.positiveQiCapacity) this.state.dependents.positiveQiCapacity = 0;
    if(!this.state.dependents.negativeQiCapacity) this.state.dependents.negativeQiCapacity = 0;
    if(!this.state.dependents.staminaCapacity) this.state.dependents.staminaCapacity = 0;
    if(!this.state.dependents.baseInitiative) this.state.dependents.baseInitiative = 0;
    if(!this.state.dependents.damageResistance) this.state.dependents.damageResistance = 0;
    if(!this.state.dependents.woundCapacity) this.state.dependents.woundCapacity = 0;
    if(!this.state.dependents.bloodCapacity) this.state.dependents.bloodCapacity = 0;
    if(!this.state.dependents.painCapacity) this.state.dependents.painCapacity = 0;
    if(!this.state.dependents.staminaRecoveryTime) this.state.dependents.staminaRecoveryTime = 0;
    if(!this.state.dependents.liftingLimit) this.state.dependents.liftingLimit = 0;
    if(!this.state.dependents.movementRange) this.state.dependents.movementRange = 0;
    if(!this.state.dependents.activeQiRange) this.state.dependents.activeQiRange = 0;
    if(!this.state.dependents.passiveQiRange) this.state.dependents.passiveQiRange = 0;
    if(!this.state.dependents.positiveQiMultiplier) this.state.dependents.positiveQiMultiplier = 0;
    if(!this.state.dependents.negativeQiMultiplier) this.state.dependents.negativeQiMultiplier = 0;
    if(!this.state.dependents.staminaMultiplier) this.state.dependents.staminaMultiplier = 0;
    if(!this.state.dependents.spellResistanceThreshold) this.state.dependents.spellResistanceThreshold = 0;
    if(!this.state.dependents.illusionDetectionThreshold) this.state.dependents.illusionDetectionThreshold = 0;
    if(!this.state.ip) this.state.ip = {};
    if(!this.state.ip.total) this.state.ip.total = 0;
    if(!this.state.ip.aspects) this.state.ip.aspects = 0;
    if(!this.state.ip.skills) this.state.ip.skills = 0;
    if(this.state.ip.specialties) delete this.state.ip.specialties;
    if(!this.state.ip.traits) this.state.ip.traits = 0;
    if(!this.state.ip.spells) this.state.ip.spells = 0;
    if(!this.state.ip.resources) this.state.ip.resources = 0;
    if(!this.state.notes) this.state.notes = "";
    if(!this.state.lastTest) this.state.lastTest = "";
    if(!this.state.lastRoll) this.state.lastRoll = 0;
    if(!this.state.isPC) this.state.isPC = false;
    if(!this.state.allegiance) this.state.allegiance = "none";
    if(this.state.allegiance === "renner") {
      this.state.timeWalker = true;
      this.state.allegiance = "none";
    }
    if(!this.state.timeWalker) this.state.timeWalker = false;
    if(!this.state.signatureSpell) this.state.signatureSpell = "none";
    if(!this.state.gifts || this.state.gifts.length !== 2 || !this.state.gifts[0].aspect) this.state.gifts = [{aspect:"none",greater:false},{aspect:"none",greater:false}];
    if(!this.state.NPCData) this.state.NPCData = {isDisguised:false,disguiseName:"???",groupSize:this.state.status.length,actTogether:false,hide:false,publicDescription:""};
    if(!this.state.NPCData.actTogether) this.state.NPCData.actTogether = true;
  }

  componentDidMount() {
      this.componentDidUpdate();
  }

  componentDidUpdate = () => { //change the character data if this is a new version
      if (this.props.data.versionNumber > this.state.versionNumber) {
          this.setState(this.props.data)
      }
      if(this.props.data.needsReset) { //needsReset is true if it's a brand new character or the Reset button was pressed
          this.resetCharacter();
      }
  }

  componentWillUnmount() {}

  currentGroupMember = 0;
  setCurrentGroupMember = (index) => {
    this.currentGroupMember = index;
    this.forceUpdate();
  }

  resetCharacter = () => { //reset the character to default data
    this.props.editCharacters({
      ...this.state,
      name:"Character Name",
      settingName:this.props.currentSetting === "All" ? "Main Campaign" : this.props.currentSetting,
      editMode:true,
      selectedTab:"main",
      ip: {total:150,aspects:48,skills:48,traits:0,spells:0,resources:24},
      aspects:{brawn:{base:3,bonus:0},toughness:{base:3,bonus:0},
          agility:{base:3,bonus:0},reflex:{base:3,bonus:0},
          awareness:{base:3,bonus:0},intelligence:{base:3,bonus:0},
          serenity:{base:3,bonus:0},impression:{base:3,bonus:0}},
      skills:{artist:{base:3,bonus:0},athlete:{base:3,bonus:0},
          burglar:{base:3,bonus:0},diplomat:{base:3,bonus:0},engineer:{base:3,bonus:0},
          fighter:{base:3,bonus:0},investigator:{base:3,bonus:0},mage:{base:3,bonus:0},
          medic:{base:3,bonus:0},performer:{base:3,bonus:0},pilot:{base:3,bonus:0},programmer:{base:3,bonus:0},
          scholar:{base:3,bonus:0},sharpshooter:{base:3,bonus:0},survivalist:{base:3,bonus:0},trickster:{base:3,bonus:0}},
      traits:{natural:{},qi:{},roleplaying:{},bestowed:{},epic:{}},
      spells:{basic:{},advanced:{},epic:{}},
      resources:{incomeRank:3,lifestyleRank:3,meleeWeapons:[],rangedWeapons:[],ammo:[],armor:[],notes:""},
      status:[{physicalDefense:11,positiveQi:6,negativeQi:0,stamina:6,foresight:0,
          bloodLoss:0,tap:0,exhaustion:0,wounds:[],overallStatus:"good",
          bleedRate:0,totalPainPenalty:0,movement:6}],
      dependents:{basePhysicalDefense:11,positiveQiCapacity:3,negativeQiCapacity:3,
          staminaCapacity:6,baseInitiative:6,damageResistance:9,woundCapacity:4,
          painCapacity:6,staminaRecoveryTime:20,liftingLimit:150,
          movementRange:6,activeQiRange:0,passiveQiRange:0,positiveQiMultiplier:1,
          negativeQiMultiplier:1,staminaMultiplier:1,spellResistanceThreshold:0,
          illusionDetectionThreshold:0,bloodCapacity:6},
      versionNumber: (this.state.versionNumber || 0)+1,
      needsReset: false,
      notes:"",
      lastTest:"",
      lastRoll:0,
      isPC:false,
      allegiance:"none",
      timeWalker: false,
      signatureSpell:"none",
      gifts:[{aspect:"none",greater:false},{aspect:"none",greater:false}],
      NPCData:{isDisguised:false,disguiseName:"???",groupSize:1,actTogether:true,hide:false,publicDescription:""}
    });
  }

  resetCharacterStatus = () => { //reset the character's status
    let newStatus = copyObject(this.state.status);
    let newNPCData = copyObject(this.state.NPCData);
    newNPCData.groupSize = newStatus.length; // just in case these got out of sync somehow
    newStatus.forEach(member => {
      member.positiveQi = this.state.traits.qi.negativeQi ? 0 : 999;
      member.negativeQi = this.usesNegativeQi() ? 999 : 0;
      member.stamina = 999;
      member.bloodLoss = 0;
      member.tap = 0;
      member.exhaustion = 0;
      member.wounds = [];  
    })
    newStatus = this.validateStatus(newStatus,this.state.dependents,this.state.traits); // send newStatus along with sendChat to avoid double changes
    let message = this.getPublicName() + "'s status has been reset";
    this.props.sendChat(message,{status:newStatus,NPCData:newNPCData},this.state.id);
  }

  getPublicName = (index) => { // returns the name that everyone should see for chat messages and initiative order
    let result = (!this.state.isPC && this.state.NPCData.isDisguised) ? this.state.NPCData.disguiseName : this.state.name;
    if(index > 0) result += (index + 1).toString();
    return result;
  }

  isQiSensitive = () => { //allows a component to check this without access to all trait data
    return (this.state.traits.qi.qiSensitivity) ? true : false;
  }

  isCaster = (level) => { //level should be "basic"(default), "advanced", or "epic"
    let cat = "qi";
    let trait = "spellShaping";
    if(level === "advanced") trait = "greaterSpellShaping";
    if(level === "epic") {cat = "epic"; trait = "epicSpellShaping";}        
    return (this.state.traits[cat][trait]) ? true : false;
  }

  getEditString = () => { //helper function to get button label
    if(this.state.editMode) return "Editing";
    return "Playing";
  }

  getTraitList = (type) => { // returns alphabetically sorted list of known traits of specified type
    let result = [];
    Object.keys(this.state.traits[type]).forEach(t => {
      result.push(t);
    });
    return result.sort();
  }

  hasTrait = (type,trait) => { //allows component to check this without access to all trait data
    return this.state.traits[type][trait] ? true : false;
  }

  getSpellList = (type) => { //returns alphabetically sorted list of known spells of specified type ("basic","advanced","epic")
    let result = [];
    Object.keys(this.state.spells[type]).forEach(s => {
      result.push(s);
    });
    return result.sort();
  }

  isBound = () => {
    switch(this.state.allegiance) {
      case "creation":
        return this.state.traits.bestowed.initiateOfTheGuardiansOfCreation ? true : false;
      case "destruction":
        return this.state.traits.bestowed.initiateOfTheCorruptedHeart ? true : false;
      case "mercy":
        return this.state.traits.bestowed.initiateOfTheArmOfMercy ? true : false;
      case "oppression":
        return this.state.traits.bestowed.initiateOfTheFistOfOppression ? true : false;
      case "nature":
        return this.state.traits.bestowed.initiateOfTheChampionsOfNature ? true : false;
      case "manipulation":
        return this.state.traits.bestowed.initiateOfThePuppeteers ? true : false;
      default:
        return false;                              
    }
  }

  isTimeWalkerInitiate = () => {
    return this.state.traits.bestowed.initiateOfTheTimeWalkers ? true : false;
  }

  localSelectedTab = this.props.data.selectedTab;
  selectTab = (tab) => {
    if (this.props.isGM) {
      // Use a local selected tab in this mode. This way, a GM can browse a character's tabs without the player knowing.
      this.localSelectedTab = tab;
      this.forceUpdate();
    } else {
      this.updateElements({selectedTab:tab});
    }
  }

  toggleMinimized = () => {
    this.props.updateCharacterState({collapsed:!this.props.myCharacterState.collapsed});
  }

  updateElements = (elements) => { //common helper function - use this instead of setState on character data
    let temp = {...this.state, ...elements, versionNumber: this.state.versionNumber + 1}; //increment character version number
//    this.setState(temp);
    this.props.editCharacters(temp);
  }

  validateStatus = (newStatus,newDependents,newTraits) => { //helper function with validation
    newStatus.forEach((member,memberIndex) => {
      let oStat = "good";
      member.positiveQi = Math.min(Math.max(0,member.positiveQi),newDependents.positiveQiCapacity);
      member.negativeQi = Math.min(Math.max(0,member.negativeQi),newDependents.negativeQiCapacity);
      if(member.bloodLoss > newDependents.bloodCapacity) {
        member.stamina -= (member.bloodLoss - newDependents.bloodCapacity);
        member.bloodLoss = newDependents.bloodCapacity;
      }
      if(member.stamina < 0) {
        member.exhaustion -= member.stamina;
        member.stamina = 0;
      }
      member.stamina = Math.min(member.stamina,newDependents.staminaCapacity);
      member.foresight = Math.min(3,Math.max(0,member.foresight));
      member.bloodLoss = Math.max(0,member.bloodLoss);
      member.tap = Math.max(0,member.tap);
      member.exhaustion = Math.max(0,member.exhaustion);
      let totalHeadWounds = 0;
      let totalLegWounds = 0;
      member.wounds.forEach((wound) => {
        wound.rank = Math.max(0,wound.rank);
        wound.bleed = Math.max(0,wound.bleed);
        if(wound.location === "torso" && wound.rank > newDependents.woundCapacity) oStat = "dead";
        if(wound.location === "head") {
          totalHeadWounds += wound.rank;
          if(wound.rank >= newDependents.woundCapacity) oStat = "dead";
        }
        if(wound.location.indexOf("Leg") > 0) totalLegWounds += wound.rank;
      });
      if(totalHeadWounds >= 2 * newDependents.woundCapacity) oStat = "dead";
      let tpp = Number(member.tap) + Number(member.exhaustion);
      let br = 0;
      member.wounds.forEach((wound) => {
        tpp += Number(wound.rank);
        br += Number(wound.bleed);
      });
      if(newTraits.qi.positiveQi) tpp += member.negativeQi;
      if(newTraits.qi.negativeQi) tpp += member.positiveQi;
      member.totalPainPenalty = tpp;
      member.physicalDefense = newDependents.basePhysicalDefense - tpp;
      member.movement = newDependents.movementRange - (0.5 * (tpp + 3 * totalLegWounds));
      if(member.exhaustion >= 2 * newDependents.woundCapacity) oStat = "dead";
      if(oStat !== "dead") {
        if(!this.state.isPC && tpp >= Math.floor(newDependents.painCapacity / 2)) oStat = "bad";
        if(tpp > newDependents.painCapacity) oStat = "incapacitated";
        if(member.exhaustion >= newDependents.woundCapacity) oStat = "unconscious";
      }
      if(oStat !== member.overallStatus) {
        let msg = "";
        if(oStat === "dead") msg = "dies!";
        else if(oStat === "unconscious") msg = "falls unconscious!";
        else if(oStat === "incapacitated") msg = "is incapacitated!";
        else if(oStat === "bad" && member.overallStatus === "good") msg = "loses the will to fight";
        else if(member.overallStatus === "incapacitated" && oStat === "good") msg = "is no longer incapacitated";
        else if(member.overallStatus === "unconscious" && oStat === "good") msg = "regains consciousness";
        if(msg !== "") setTimeout(() => this.props.sendChat(this.getPublicName(memberIndex) + " " + msg,{},this.state.id),100);
      }
      member.overallStatus = oStat;
      member.bleedRate = br;  
    });
    return newStatus;
  }

  updateStatus = (newStatus) => { //helper function with validation and propagating consequences - ONLY USE FOR DIRECT STATUS CHANGES!!!
    this.updateElements({status:this.validateStatus(newStatus,this.state.dependents,this.state.traits)});
  }

  validateAspects = (newAspects) => {
    Object.keys(newAspects).forEach(a => {
      newAspects[a].bonus = Math.max(newAspects[a].bonus,this.giftBonus(a) + this.philosophyBonus(a));
    });
    return newAspects;
  }

  updateAspects = (newAspects) => { //updates state and propagates consequences - ONLY USE FOR DIRECT ASPECT CHANGES!!!
    newAspects = this.validateAspects(newAspects);
    let newDependents = this.validateDependents(newAspects,this.state.skills,this.state.traits);
    let newStatus = this.validateStatus(this.state.status,newDependents,this.state.traits);
    let newIP = this.state.ip;
    let aspectIP = 0;
    Object.keys(newAspects).forEach(a => {
      aspectIP += 2 * Number(newAspects[a].base);
    });
    newIP.aspects = aspectIP;
    this.updateElements({aspects:newAspects,ip:newIP,dependents:newDependents,status:newStatus});
  }

  updateSkills = (newSkills) => { //updates state and propagates consequences - ONLY USE FOR DIRECT SKILL CHANGES!!!
    let newDependents = this.validateDependents(this.state.aspects,newSkills,this.state.traits);
    let newStatus = this.validateStatus(this.state.status,newDependents,this.state.traits);
    let newIP = this.state.ip;
    let skillIP = 0;
    Object.keys(newSkills).forEach(s => {
      skillIP += Number(newSkills[s].base);
    });
    newIP.skills = skillIP;
    this.updateElements({skills:newSkills,ip:newIP,dependents:newDependents,status:newStatus});
  }

  updateTraits = (newTraits,newAspects,newGifts) => { //updates state and propagates consequences
    newAspects = this.validateAspects(newAspects);
    let newDependents = this.validateDependents(newAspects,this.state.skills,newTraits);
    let newStatus = this.validateStatus(this.state.status,newDependents,newTraits);
    let newIP = this.state.ip;
    let traitIP = 0;
    Object.keys(newTraits).forEach(cat => {
      Object.keys(newTraits[cat]).forEach(t => {
        traitIP += this.getTraitIP(cat);
      });
    });
    newIP.traits = traitIP;
    this.updateElements({traits:newTraits,ip:newIP,dependents:newDependents,status:newStatus,aspects:newAspects,gifts:newGifts});
  }

  updateSpells = (newSpells) => { //updates state and propagates consequences
    let newIP = this.state.ip;
    let spellIP = 0;
    Object.keys(newSpells).forEach(cat => {
      Object.keys(newSpells[cat]).forEach(s => {
        spellIP += this.getSpellIP(cat);
      });
    });
    newIP.spells = spellIP;
    this.updateElements({spells:newSpells,ip:newIP});
  }

  updateResources = (newResources) => { //updates state and propagates consequences
    let newIP = this.state.ip;
    newIP.resources = 3 * (newResources.incomeRank + newResources.lifestyleRank);
    this.updateElements({resources:newResources,ip:newIP});
  }

  updateIP = (newIP) => {
    this.updateElements({ip:newIP});
  }

  updateNPCData = (newData,groupSizeChange) => {
    let newStatus = copyObject(this.state.status);
    let sizeChange = groupSizeChange || 0;
    if(sizeChange > 0) {
      for(let i=0;i<sizeChange;i++) {newStatus.push(copyObject(newStatus[0]));}
    } else if(sizeChange < 0) {
      for(let i=0;i<-sizeChange;i++) {newStatus.pop();}
      if(this.currentGroupMember >= newStatus.length) this.currentGroupMember = 0;
    }
    newStatus = this.validateStatus(newStatus,this.state.dependents,this.state.traits);
    newData.groupSize = newStatus.length;
    this.updateElements({NPCData:newData,status:newStatus});
  }

  getSpellIP = (category) => {
    if(category === "epic") return 3;
    if(category === "advanced") return 2;
    return 1;   
  }

  getTraitIP = (category) => {
    if(category === "epic") return 3;
    if(category === "natural") return 1;
    if(category === "roleplaying") return -2;
    return 2;
  }

  validateDependents = (newAspects,newSkills,newTraits) => { //consequences of other changes
    let newDependents = this.state.dependents;
    let brawn = Number(newAspects.brawn.base) + Number(newAspects.brawn.bonus);
    let toughness = Number(newAspects.toughness.base) + Number(newAspects.toughness.bonus);
    let reflex = Number(newAspects.reflex.base) + Number(newAspects.reflex.bonus);
    let impression = Number(newAspects.impression.base) + Number(newAspects.impression.bonus);
    let serenity = Number(newAspects.serenity.base) + Number(newAspects.serenity.bonus);
    let intelligence = Number(newAspects.intelligence.base) + Number(newAspects.intelligence.bonus);
    let awareness = Number(newAspects.awareness.base) + Number(newAspects.awareness.bonus);
    let athlete = Number(newSkills.athlete.base) + Number(newSkills.athlete.bonus);
    let fighter = Number(newSkills.fighter.base) + Number(newSkills.fighter.bonus)
    let mage = Number(newSkills.mage.base) + Number(newSkills.mage.bonus);
    // update all qi specific dependent attributes
    let baseQiMult = 1;
    let posQiMult = 1;
    let negQiMult = 1;
    let aqr = 0;
    let pqr = 0;
    if(newTraits.qi.qiSensitivity) { 
      baseQiMult++;
      aqr = 2 * impression;
      pqr = awareness + mage;
      if(newTraits.qi.qiReserves) baseQiMult++;
      if(newTraits.qi.greaterQiReserves) baseQiMult++;
      if(newTraits.epic.epicQiReserves) baseQiMult += 2;
      if(newTraits.epic.supremeQiReserves) baseQiMult += 2;
      posQiMult = baseQiMult;
      negQiMult = baseQiMult;
      if(newTraits.qi.positiveQi) {
        posQiMult++;
        negQiMult = 0.5;
      }
      if(newTraits.qi.negativeQi) {
        negQiMult++;
        posQiMult = 0.5;
      }
      if(newTraits.epic.supremeExtendedQi) {
        aqr *= 2;
        pqr *= 2;
      }
    }
    newDependents.positiveQiMultiplier = posQiMult;
    newDependents.negativeQiMultiplier = negQiMult;
    newDependents.positiveQiCapacity = Math.floor(serenity * newDependents.positiveQiMultiplier);
    newDependents.negativeQiCapacity = Math.floor(serenity * newDependents.negativeQiMultiplier);
    newDependents.activeQiRange = aqr;
    newDependents.passiveQiRange = pqr;
    // update stamina related dependent attributes
    newDependents.staminaMultiplier = 1;
    newDependents.staminaRecoveryTime = 20;
    if(newTraits.natural.endurance) {
      newDependents.staminaMultiplier++;
      newDependents.staminaRecoveryTime = 15;
    }
    if(newTraits.natural.greatEndurance) {
      newDependents.staminaMultiplier++;
      newDependents.staminaRecoveryTime = 10;
    }
    if(newTraits.epic.epicEndurance) {
      newDependents.staminaMultiplier += 2;
      newDependents.staminaRecoveryTime = 5;
    }
    if(newTraits.epic.supremeEndurance) {
      newDependents.staminaMultiplier += 2;
      newDependents.staminaRecoveryTime = 2;
    }
    newDependents.staminaCapacity = toughness * newDependents.staminaMultiplier;
    // physical defense
    newDependents.basePhysicalDefense = reflex + fighter + 4;
    // initiative
    newDependents.baseInitiative = reflex + awareness;
    if(newTraits.natural.quickness) newDependents.baseInitiative += 2;
    if(newTraits.epic.epicQuickness) newDependents.baseInitiative += 4;
    if(newTraits.epic.supremeQuickness) newDependents.baseInitiative += 4;
    // damage resistance
    let dr = 2*toughness + 3;
    if(newTraits.natural.sturdiness) dr += 3;
    if(newTraits.epic.epicSturdiness) dr += toughness;
    if(newTraits.epic.supremeSturdiness) dr += toughness;
    newDependents.damageResistance = dr;
    // wound capacity
    let wc = 3;
    if(toughness > 1) wc++;
    if(toughness > 4) wc++;
    if(toughness > 8) wc++;
    if(toughness > 13) wc++;
    if(toughness > 19) wc++;
    if(newTraits.epic.epicTenacity) wc++;
    if(newTraits.epic.supremeTenacity) wc++;
    newDependents.woundCapacity = wc;
    // pain capacity
    let pc = 2*serenity;
    if(newTraits.natural.fortitude) pc += 3;
    if(newTraits.epic.epicFortitude) pc += 10;
    newDependents.painCapacity = pc;
    // blood capacity
    let bc = 2*toughness;
    if(newTraits.natural.tenacity) bc += 4;
    if(newTraits.epic.epicTenacity) bc += 10;
    if(newTraits.epic.supremeTenacity) bc += 10;
    newDependents.bloodCapacity = bc;
    // lifting limit
    let lltable = [0,30,80,150,250,400,600,800,1100,1500];
    if(brawn < 10) newDependents.liftingLimit = lltable[brawn];
    else newDependents.liftingLimit = 500 * (brawn - 6);
    // movement range
    let mr = 0;
    if(brawn > 0 && athlete > 0) {
      mr = brawn + athlete;
      if(newTraits.natural.swiftness) mr += 2;
      if(newTraits.epic.epicSwiftness) mr += 6;
      if(newTraits.epic.supremeSwiftness) mr += 6;
    }
    newDependents.movementRange = mr;
    // spellcasting dependent attributes
    newDependents.illusionDetectionThreshold = 4 + intelligence + mage;
    newDependents.spellResistanceThreshold = 4 + impression + mage;
    // finish setting new values and update the Status component
    return newDependents;
  }

  getSpentIP = () => { // return the amount of IP a character has remaining to spend
    return this.state.ip.aspects + this.state.ip.skills +
        this.state.ip.traits + this.state.ip.spells + this.state.ip.resources;
  }

  getRemainingIP = () => { // return the amount of IP a character has remaining to spend
    return this.state.ip.total - this.getSpentIP();
  }

  getAspect = (aspect) => { // helper function for most aspect applications (except ip costs)
      return Number(this.state.aspects[aspect].base) + Number(this.state.aspects[aspect].bonus);
  }

  getSkill = (skill) => { // helper function for most skill applications (except ip costs)
    let result = 99;    // can also request "min" skill which will give the minimum skill rank of all skills
    let myList = [];
    if(skill==="min") myList = Object.keys(this.state.skills)
    else myList.push(skill);
    myList.forEach(s => {
      result = Math.min(result,Number(this.state.skills[s].base) + Number(this.state.skills[s].bonus));
    })
    return result;
  }

  getMaxMeleeAttacks = () => { // helper function returns the maximum number of attacks for a particular strike action
    let result = 0;
    let fi = this.getSkill("fighter");
    if(fi > 0) result = 1;
    if(fi > 2) result++;
    if(fi > 5) result++;
    if(fi > 9) result++;
    if(fi > 14) result++;
    if(fi > 20) result++;
    return result;
  }

  getMaxShootingAttacks = (reload) => { // helper function returns the maximum number of attacks for a particular shoot action
    let result = 1;
    if(reload === "lever") result = 2;
    else if(reload === "pump") result = 3;
    else if(reload === "auto" || reload === "multi") result = 6;
    return result;
  }

  testAspect = (aspect) => { // make a pure aspect test and post to chat and update last test
    let base = this.getAspect(aspect) * 2;
    let roll = this.roll();
    let crit = null;
    if(roll === 1) crit = "Possible Critical Fail";
    if(roll === 8) crit = "Possible Critical Success";
    let tpp = this.state.status[this.currentGroupMember].totalPainPenalty;
    let res = base + roll - tpp;
    if(this.channelAmountForAspect(aspect) > 0) {
      res = res.toString() + " (+" + this.channelAmountForAspect(aspect).toString() + ")"; 
    }
    this.props.sendChat({
      type:"test",
      name:this.getPublicName(this.currentGroupMember),
      charID: this.state.id,
      aspect:cap(aspect),
      result:res,
      critical:crit,
      base:base,
      roll:roll,
      tpp:tpp
    },{
      lastTest:aspect,lastRoll:roll // update the lastTest as part of sendChat to avoid double changes
    },this.state.id);
  }

  testSkill = (skill, aspect) => { // make a skill test and post to chat and update last test
    let base = this.getAspect(aspect) + this.getSkill(skill);
    let roll = this.roll();
    let crit = null;
    if(roll === 1) crit = "Possible Critical Fail";
    if(roll === 8) crit = "Possible Critical Success";
    let tpp = this.state.status[this.currentGroupMember].totalPainPenalty;
    let res = base + roll - tpp;
    if(this.channelAmountForAspect(aspect) > 0) {
      res = res.toString() + " (+" + this.channelAmountForAspect(aspect).toString() + ")"; 
    }
    this.props.sendChat({
      type:"test",
      name:this.getPublicName(this.currentGroupMember),
      charID: this.state.id,
      skill:cap(skill),
      aspect:cap(aspect),
      result:res,
      critical:crit,
      base:base,
      roll:roll,
      tpp:tpp
    },{
      lastTest:aspect,lastRoll:roll // update the lastTest as part of sendChat to avoid double changes
    },this.state.id);
  }

  getTargetingQuestion = () => { // helper function to return targeting question object
    return {name:"Target: ",inputType:"dropdown",default:"torso",
            options:[{name:"Head",value:"head"},{name:"Torso",value:"torso"},
                     {name:"Left Arm",value:"leftArm"},{name:"Right Arm",value:"rightArm"},
                     {name:"Left Leg",value:"leftLeg"},{name:"Right Leg",value:"rightLeg"}]};
  }

  shoot = (weapon) => { // make attack test (including damage) and post to chat
    let attackOptions = [];
    let i=0;
    for(i=0; i<=6; i++) {
      attackOptions.push({name:i.toString(),value:i.toString()});
    };
    let defaultIndex = 0;
    let weaponOptions = [];
    this.state.resources.rangedWeapons.forEach((wpn,idx) => {
      if(!wpn.thrown) weaponOptions.push({name:cap(wpn.name),value:idx});
      if(JSON.stringify(wpn) === JSON.stringify(weapon)) defaultIndex = idx;
    });
    this.props.newDialogue({
      title: "Number of Attacks",
      questions: {
        mainHand: {name:"Main-Hand Trigger Pulls",inputType:"dropdown",default:"1",options:attackOptions},
        offHand: {name:"Off-Hand Trigger Pulls",inputType:"dropdown",default:"0",options:attackOptions},
        offHandWeapon: {name:"Off-Hand Weapon",inputType:"dropdown",default:defaultIndex,options:weaponOptions}
      },
     resultFunction:(result1) => {
        let offHandWeapon = this.state.resources.rangedWeapons[result1.offHandWeapon];
        let mainHandAttacks = Math.min(Number(result1.mainHand),this.getMaxShootingAttacks(weapon));
        let offHandAttacks = Math.min(Number(result1.offHand),this.getMaxShootingAttacks(offHandWeapon.reload));
        if(mainHandAttacks + offHandAttacks === 0) return;
        let myQuestions = {};
        if(mainHandAttacks + offHandAttacks === 1) {
          myQuestions.type = {name:"Type: ",inputType:"dropdown",default:"standardAttack",
            options:[{name:"Standard Attack",value:"standardAttack"},{name:"Debilitating Attack",value:"debilitatingAttack"},
              {name:"Disarm",value:"disarm"}]};
        }
        if(offHandAttacks === 0) myQuestions.twoHanded = {name:"Two Handed?",inputType:"checkbox",default:weapon.twoHanded};
        myQuestions.recoilSuppression = {name:"Recoil Suppression?",inputType:"checkbox",default:false};
        let targetQuestion = this.getTargetingQuestion();
        if(mainHandAttacks > 0 && weapon.reload === "multi") {
          myQuestions.mainHandFiringMode = {name:"Main-Hand Firing Mode: ",inputType:"dropdown",default:"singleShot",
            options:[{name:"Single Shot",value:"singleShot"},{name:"3 Round Burst",value:"burst"},{name:"Full Auto",value:"fullAuto"}]};
        }
        if(offHandAttacks > 0 && offHandWeapon.reload === "multi") {
          myQuestions.offHandFiringMode = {name:"Off-Hand Firing Mode: ",inputType:"dropdown",default:"singleShot",
            options:[{name:"Single Shot",value:"singleShot"},{name:"3 Round Burst",value:"burst"},{name:"Full Auto",value:"fullAuto"}]};
        }
        for(i=0;i<Math.max(mainHandAttacks,offHandAttacks);i++) {
          if(i<mainHandAttacks) {
            targetQuestion.name = "Main-Hand Shot " + (i+1) + " Target: ";
            if(i>0) myQuestions["mainHandNewTarget"+i] = {name:"Main-Hand Shot " + (i+1) + " New Target?",inputType:"checkbox",default:false};
            myQuestions["mainHandTarget"+i] = {...targetQuestion};
            myQuestions["mainHandRange"+i] = {name:"Main-Hand Shot " + (i+1) + " Range: ",inputType:"number",default:5};
          }
          if(i<offHandAttacks) {
            targetQuestion.name = "Off-Hand Shot " + (i+1) + " Target: ";
            if(i>0 || mainHandAttacks>0) myQuestions["offHandNewTarget"+i] = {name:"Off-Hand Shot " + (i+1) + " New Target?",inputType:"checkbox",default:false};
            myQuestions["offHandTarget"+i] = {...targetQuestion};
            myQuestions["offHandRange"+i] = {name:"Off-Hand Shot " + (i+1) + " Range: ",inputType:"number",default:5};  
          }
        }
        this.props.newDialogue({
          title: "Shooting Options",
          questions: myQuestions,
          resultFunction:(result) => {
            let abort = false;
            let notes = [];
            let mainHandFiringMode = mainHandAttacks > 0 ? "singleShot" : null;
            if(result.mainHandFiringMode) mainHandFiringMode = result.mainHandFiringMode;
            let offHandFiringMode = offHandAttacks > 0 ? "singleShot" : null;
            if(result.offHandFiringMode) offHandFiringMode = result.offHandFiringMode;
            if(!result.type) result.type = "multipleAttacks"; //only way this didn't get asked
            let attackType = cap(result.type);
            let hands = "Main-Hand";
            if(result.twoHanded) hands = "Two-Handed";
            if(mainHandAttacks === 0) hands = "Off-Hand";
            let paired = mainHandAttacks > 0 && offHandAttacks > 0;
            if(paired) hands = "Paired Attack";
            if(paired && defaultIndex === result1.offHandWeapon) {
              if(!abort) {
                abort = true;
                notes.push("Aborting Attack:");
              }
              notes.push("Paired Attack can't use the same weapon twice. Add a second weapon with a different name.");
            }
            if(paired && weapon.ammo === offHandWeapon.ammo) {
              if(!abort) {
                notes.push("Aborting Attack:");
                abort = true;
              }
              notes.push("Paired Weapons must use different ammo. Add another ammo entry with a different name.");
            }
            let offHandPrefix = paired ? "(OH) " : "";
            let mainHandPrefix = paired ? "(MH) " : "";
            let base = this.getAspect("agility") + this.getSkill("sharpshooter"); // get base attack result
            let roll = this.roll();
            let crit = null;
            if(roll === 1) crit = "Possible Critical Fail";
            if(roll === 8) crit = "Possible Critical Success";
            let offHandBase = base;
            let baseDamage = Number(weapon.damage); // start calculating damage
            if(weapon.type === "bow" || weapon.type === "crossbow") {
              if(this.hasTrait("natural","bowmaster")) baseDamage += 5;
            }
            let offHandBaseDamage = Number(offHandWeapon.damage);
            
            if(offHandAttacks > 0) {
              offHandBase += -5; // includes one-handed shooting penalty
              if(this.state.traits.natural.ambidextrous) offHandBase += 4;
            }
            if(!result.twoHanded) base += -1; // one-handed penalty for main hand
            let range = weapon.range;
            if(weapon.ammo !== "none") range += this.state.resources.ammo[weapon.ammo].bonusRange;
            let offHandRange = offHandWeapon.range;
            if(offHandWeapon.ammo !== "none") offHandRange += this.state.resources.ammo[offHandWeapon.ammo].bonusRange;
            let dmgRoll = this.roll();
            let effect = null;
            if(result.type === "debilitatingAttack") {
              effect = "causes TAP";
            } else if(result.type === "disarm") {
              effect = "possible disarm"
            }
            let newResources = {...this.state.resources};
            // calculate number of rounds required for each hand
            let roundsPerAttack = 1;
            if(result.mainHandFiringMode) {
              if(result.mainHandFiringMode === "burst") roundsPerAttack = 3;
              if(result.mainHandFiringMode === "fullAuto") roundsPerAttack = 12;
            } 
            let offHandRoundsPerAttack = 1;
            if(result.offHandFiringMode) {
              if(result.offHandFiringMode === "burst") offHandRoundsPerAttack = 3;
              if(result.offHandFiringMode === "fullAuto") offHandRoundsPerAttack = 12;
            } 
            if(mainHandAttacks > 0) {
              if(weapon.ammo === "none" || newResources.ammo[weapon.ammo].amount < 1) {
                if(!abort) {
                  notes.push("Aborting Attack:");
                  abort = true;
                }
                notes.push("Main Hand Out of Ammo");
              } 
            }
            if(offHandAttacks > 0) {
              if(offHandWeapon.ammo === "none" || newResources.ammo[offHandWeapon.ammo].amount < 1) {
                if(!abort) {
                  notes.push("Aborting Attack:");
                  abort = true;
                }
                notes.push("Off-Hand Out of Ammo");
              } 
            }
            let tpp = this.state.status[this.currentGroupMember].totalPainPenalty;
            let attackResult = [];
            let att = "";
            let dmg = 0;
            let ap = 0;
            let rangePen = 0;
            let rangePenMin = 9999;
            let rangePenMax = 0;
            let recoil = result.recoilSuppression ? 1 : 2;
            let retarget = this.hasTrait("natural","quickShot") ? 1 : 2;
            let cumulativePen = 0;
            let j=0;
            let mainHandAmmoRemaining = newResources.ammo[weapon.ammo].amount;
            let offHandAmmoRemaining = newResources.ammo[offHandWeapon.ammo].amount;
            let ammoUsed = false;
            let targetingPenalty = 0;
            let channel = "";
            if(this.channelAmountForAspect("agility")>0) {
              channel = " (+" + this.channelAmountForAspect("agility") + ")";
            }
            for(i = 0; i < Math.max(mainHandAttacks,offHandAttacks); i++) {
              if(i<mainHandAttacks) {
                if(i>0 && result["mainHandNewTarget"+i]) {
                  cumulativePen += retarget;
                  attackResult.push("<New Target>");
                }
                rangePen = Math.floor(result["mainHandRange"+i]/range);
                rangePenMin = Math.min(rangePen,rangePenMin);
                rangePenMax = Math.max(rangePen,rangePenMax);
                dmg = baseDamage + dmgRoll - rangePen;
                ap = weapon.ap - rangePen;
                att = "";
                ammoUsed = false;
                for(j=0;j<roundsPerAttack;j++) {
                  if(mainHandAmmoRemaining > 0) {
                    targetingPenalty = (result["mainHandTarget"+i] === "torso" ? 0 : 2);
                    att = att + (j>0?", ":"") + (base + roll - tpp - targetingPenalty - rangePen - cumulativePen);
                    cumulativePen += recoil;
                    mainHandAmmoRemaining--;
                    ammoUsed = true;
                  } else {
                    att = att + (j>0?", ":"") + "Click";
                  }
                }
                attackResult.push(mainHandPrefix + cap(result["mainHandTarget"+i]) + ": " + att + channel);
                if(ammoUsed) attackResult.push("(" + (dmg + targetingPenalty) + " Dmg, AP:" + ap + ")");
                else attackResult.push("(Out of Ammo)");
              }
              if(i<offHandAttacks) {
                if((i>0 || mainHandAttacks > 0) && result["offHandNewTarget"+i]) {
                  cumulativePen += retarget;
                  attackResult.push("<New Target>");
                }
                rangePen = Math.floor(result["offHandRange"+i]/offHandRange);
                rangePenMin = Math.min(rangePen,rangePenMin);
                rangePenMax = Math.max(rangePen,rangePenMax);
                dmg = offHandBaseDamage + dmgRoll - rangePen;
                ap = offHandWeapon.ap - rangePen;
                att="";
                ammoUsed = false;
                for(j=0;j<offHandRoundsPerAttack;j++) {
                  if(offHandAmmoRemaining > 0) {
                    targetingPenalty = (result["offHandTarget"+i] === "torso" ? 0 : 2);
                    att = att + (j>0?", ":"") + (offHandBase + roll - tpp - targetingPenalty - rangePen - cumulativePen);
                    cumulativePen += recoil;
                    offHandAmmoRemaining--;
                    ammoUsed = true;
                  } else {
                    att = att + (j>0?", ":"") + "Click";
                  }
                }
                attackResult.push(offHandPrefix + cap(result["offHandTarget"+i]) + ": " + att + channel);
                if(ammoUsed) attackResult.push("(" + (dmg + targetingPenalty) + " Dmg, AP:" + ap + ")");
                else attackResult.push("(Out of Ammo)");
              }
            }
            newResources.ammo[weapon.ammo].amount = mainHandAmmoRemaining;
            if(offHandAttacks > 0) newResources.ammo[offHandWeapon.ammo].amount = offHandAmmoRemaining; //have to check if off hand was used, otherwise could overwrite main hand
            rangePenMin = -rangePenMin;
            rangePenMax = -rangePenMax;
            if(rangePenMin !== rangePenMax) notes.push("Range Penalty: " + rangePenMin + " to " + rangePenMax);
            else if(rangePenMin < 0) notes.push("Range Penalty: " + rangePenMin);
            if(abort) {
              this.props.sendChat({
                type: "messages",
                messages: notes
              },{},this.state.id);  // nothing to update if we aborted
            } else {
              this.props.sendChat({
                type: "attack",
                charID: this.state.id,
                name: this.getPublicName(this.currentGroupMember),
                action: "Shoot",
                attackType: attackType,
                hands: hands,
                weapon: mainHandAttacks > 0 ? weapon.name : null,
                firingMode: cap(mainHandFiringMode),
                ammo: mainHandAttacks > 0 ? this.state.resources.ammo[weapon.ammo].name : null,
                paired: paired,
                offHandWeapon: offHandAttacks > 0 ? offHandWeapon.name : null,
                offHandFiringMode: offHandFiringMode ? cap(offHandFiringMode) : null,
                offHandAmmo: offHandAttacks > 0 ? this.state.resources.ammo[offHandWeapon.ammo].name : null,
                resultArray: attackResult,
                base: mainHandAttacks > 0 ? base : offHandBase,
                roll:roll,
                tpp:tpp,
                critical:crit,
                note: notes,
                effect: effect
              },{
                lastTest:"agility", // update these as part of sendChat to avoid double changes
                resources:newResources,
                lastRoll:roll
              },this.state.id);  
            }
          } //end inner resultFunction
        }); //end inner newDialogue
      } //end outer resultFunction
    }); //end outer newDialogue
  }

  throw = (weapon) => { // make attack test (including damage) and post to chat
    this.props.newDialogue({
      title: "Number of Attacks",
      questions: {
        primary: {name:"Main-Hand?",inputType:"checkbox",default:true},
        offHand: {name:"Off-Hand?",inputType:"checkbox",default:false},
        twoHanded: {name:"Two-Handed?",inputType:"checkbox",default:false}
      },
      resultFunction:(result1) => {
        let primaryAttacks = (result1.primary || result1.twoHanded) ? 1 : 0;
        let offHandAttacks = (result1.offHand && !result1.twoHanded) ? 1 : 0;
        if(primaryAttacks + offHandAttacks === 0) return;
        let usesTwoWeapons = (primaryAttacks === 1 && offHandAttacks === 1);
        let myQuestions = {};
        if(primaryAttacks + offHandAttacks === 1) {
          myQuestions.type = {name:"Type: ",inputType:"dropdown",default:"standardAttack",
            options:[{name:"Standard Attack",value:"standardAttack"},{name:"Debilitating Attack",value:"debilitatingAttack"},
              {name:"Disarm",value:"disarm"},{name:"Knockout",value:"knockout"}]};
          myQuestions.power = {name: "Power?",inputType:"checkbox",default:false};
        }
        if(usesTwoWeapons) {
          let defaultIndex = 0;
          let weaponOptions = [];
          this.state.resources.rangedWeapons.forEach((wpn,idx) => {
            if(wpn.thrown) weaponOptions.push({name:cap(wpn.name),value:idx});
            if(JSON.stringify(wpn) === JSON.stringify(weapon)) defaultIndex = idx;
          });
          myQuestions.offHandWeapon = {name:"Off-hand Weapon",inputType:"dropdown",default:defaultIndex,options:weaponOptions}
        }
        let targetQuestion = this.getTargetingQuestion();
        if(primaryAttacks === 1) {
          targetQuestion.name = "Main-Hand Attack Target: ";
          myQuestions.mainHandTarget = {...targetQuestion};
          myQuestions.mainHandRange = {name:"Range: ",inputType:"number",default:5};
        }
        if(offHandAttacks === 1) {
          targetQuestion.name = "Off-Hand Attack Target: ";
          myQuestions.offHandTarget = {...targetQuestion};
          if(primaryAttacks === 1) myQuestions.switchTargets = {name:"New Target?",inputType:"checkbox",default:false};
          myQuestions.offHandRange = {name:"Range: ",inputType:"number",default:5};
        }
        this.props.newDialogue({
          title: "Throwing Options",
          questions: myQuestions,
          resultFunction:(result) => {
            let abort = false;
            let notes = [];
            let bra = this.getAspect("brawn");
            let currentStaminaCost = 0; 
            if(!result.type) result.type = "standardAttack";
            let attackType = cap(result.type);
            let pdPen = 0; //pdPen = physical defense penalty
            if(result.power) {
              notes.push("Power Attack");
              pdPen++;
            }
            if(pdPen > 0) notes.push("(-" + pdPen + " to your Phys Def)");
            if(result.type === "knockout") currentStaminaCost = 1;
            if(result.power) currentStaminaCost++;
            let offHandWeapon = null;
            if(usesTwoWeapons) offHandWeapon = this.state.resources.rangedWeapons[result.offHandWeapon];
            if(primaryAttacks === 0) offHandWeapon = weapon;
            let weaponWeight = 1;
            let offHandWeaponWeight = 1;
            if(weapon.weightRank === "medium") weaponWeight = 2;
            if(weapon.weightRank === "heavy") weaponWeight = 3;
            if(offHandWeapon) {
              if(offHandWeapon.weightRank === "medium") offHandWeaponWeight = 2;
              if(offHandWeapon.weightRank === "heavy") offHandWeaponWeight = 3;
            }
            currentStaminaCost += weaponWeight - 1; // first throw costs 1 less
            if(usesTwoWeapons) currentStaminaCost += offHandWeaponWeight; // paired attacks costs full weight
            if(currentStaminaCost > bra) {
              if(!abort){
                notes.push("Aborting Attack:");
                abort = true;
              }
              notes.push("Insufficient Brawn");
            } 
            if(currentStaminaCost > this.state.status[this.currentGroupMember].stamina + 1) {
              if(!abort) {
                notes.push("Aborting Attack:");
                abort = true;
              }
              notes.push("Insufficient Stamina");
            }
            let base = this.getAspect("agility") + this.getSkill("athlete"); // get base attack result
            let roll = this.roll();
            let crit = null;
            if(roll === 1) crit = "Possible Critical Fail";
            if(roll === 8) crit = "Possible Critical Success";
            let tpp = this.state.status[this.currentGroupMember].totalPainPenalty;
            let baseDamage = Number(weapon.damage); // start calculating damage
            let offHandBaseDamage = 0;
            if(result.power) { // apply power attack effects if applicable
              base += -1;
              baseDamage += 4;
              offHandBaseDamage += 4;
            }
            let offHandBase = base;
            if(offHandAttacks > 0) {
              offHandBaseDamage = Number(offHandWeapon.damage);
              offHandBase += -4;
              if(this.state.traits.natural.ambidextrous) offHandBase += 4;
            }
            let rangePen = 0;
            let offHandRangePen = 0;
            let range = weapon.range;
            if(weapon.ammo !== "none") range += this.state.resources.ammo[weapon.ammo].bonusRange;
            if(result.mainHandRange >= range) rangePen[0] = Math.floor(result.mainHandRange / range);
            if(offHandWeapon) {
              let offHandRange = offHandWeapon.range;
              if(offHandWeapon.ammo !== "none") offHandRange += this.state.resources.ammo[offHandWeapon.ammo].bonusRange;
              if(result.offHandRange >= offHandRange) offHandRangePen[0] = Math.floor(result.offHandRange / offHandRange);
            }
            if(rangePen) base -= rangePen;
            if(offHandRangePen) offHandBase -= offHandRangePen;
            let brawnBonus = 0;
            if(weapon.brawn) {
              brawnBonus = 2 * bra;
              if(result1.twoHanded) brawnBonus += 2;
            }
            let offHandBrawnBonus = 0;
            if(offHandWeapon && offHandWeapon.brawn) offHandBrawnBonus = 2 * bra;
            let brute = 0
            if(this.hasTrait("natural","brute")) {
              brute = 3;
              if(this.hasTrait("epic","epicBrute")) brute+= bra;
              if(this.hasTrait("epic","supremeBrute")) brute+= bra;
            }
            if(weapon.brawn) baseDamage += brute;
            if(offHandWeapon && offHandWeapon.brawn) offHandBaseDamage += brute;
            let dmgRoll = this.roll();
            let dmg = baseDamage + brawnBonus + dmgRoll;
            if(rangePen && weapon.brawn) dmg -= rangePen;
            let offHandDmg = offHandBaseDamage + offHandBrawnBonus + dmgRoll;
            if(offHandRangePen && offHandWeapon.brawn) offHandDmg -= offHandRangePen;
            let ap = weapon.ap;
            let offHandAP = ap;
            if(offHandAttacks > 0) offHandAP = offHandWeapon.ap;
            let effect = null;
            if(result.type === "debilitatingAttack") {
              dmg = null;
              effect = "causes TAP";
            } else if(result.type === "disarm") {
              effect = "possible disarm"
            } else if(result.type === "knockout") {
              effect = "possible knockout"
            }
            let newResources = {...this.state.resources};
            let newStatus = this.state.status;
            newStatus[this.currentGroupMember].stamina = newStatus[this.currentGroupMember].stamina - currentStaminaCost;
            if(primaryAttacks > 0) {
              if(weapon.ammo === "none" || newResources.ammo[weapon.ammo].amount < 1) {
                if(!abort) {
                  notes.push("Aborting Attack:");
                  abort = true;
                }
                notes.push("Main Hand Out of Ammo");
              } else {
                newResources.ammo[weapon.ammo].amount += -1;
              }
            }
            if(offHandAttacks > 0) {
              if(offHandWeapon.ammo === "none" || newResources.ammo[offHandWeapon.ammo].amount < 1) {
                if(!abort) {
                  notes.push("Aborting Attack:");
                  abort = true;
                }
                notes.push("Off-Hand Out of Ammo");
              } else {
                newResources.ammo[offHandWeapon.ammo].amount += -1;
              }
            }
            let mainHandPrefix = usesTwoWeapons ? "(MH) " : "";
            let offHandPrefix = usesTwoWeapons ? "(OH) " : "";
            let attackResult = [];
            let targetingPenalty = 0;
            let channel = "";
            if(this.channelAmountForAspect("agility")>0) {
              channel = " (+" + this.channelAmountForAspect("agility") + ")";
            }
            if(primaryAttacks === 1) {
              targetingPenalty = (result.mainHandTarget === "torso" ? 0 : 2);
              attackResult.push(mainHandPrefix + cap(result.mainHandTarget) + ": " +
                (base + roll - tpp - targetingPenalty) + channel);
              attackResult.push("(" + (dmg + targetingPenalty) + " Dmg, AP:" + ap + ")");
            }
            if(offHandAttacks === 1) {
              let targetSwitchPen = 0;
              if(result.switchTargets) {
                targetSwitchPen = this.hasTrait("natural","quickShot") ? 1 : 2;
              }
              targetingPenalty = (result.offHandTarget === "torso" ? 0 : 2);
              attackResult.push(offHandPrefix + cap(result.offHandTarget) + ": " + 
                (offHandBase + roll - tpp - targetingPenalty - targetSwitchPen) + channel);
              attackResult.push("(" + (offHandDmg + targetingPenalty) + " Dmg, AP:" + offHandAP + ")");
            }
            newStatus = this.validateStatus(newStatus,this.state.dependents,this.state.traits);
            if(rangePen > 0) notes.push(mainHandPrefix + "Range Penalty: " + (-rangePen));
            if(offHandRangePen > 0) notes.push(offHandPrefix + "Range Penalty: " + (-offHandRangePen));
            let hands = "Main-Hand";
            if(result.twoHanded) hands = "Two-Handed";
            else if(usesTwoWeapons) hands = "Paired Attack";
            else if(offHandAttacks === 1) hands = "Off-Hand";
            if(abort) {
              this.props.sendChat({
                type: "messages",
                messages: notes
              },{},this.state.id);  // nothing to update if we aborted
            } else {
              this.props.sendChat({
                type: "attack",
                charID: this.state.id,
                name: this.getPublicName(this.currentGroupMember),
                action: "Throw",
                attackType: attackType,
                hands: hands,
                weapon: primaryAttacks === 1 ? mainHandPrefix + weapon.name : null,
                offHandWeapon: offHandAttacks === 1 ? offHandPrefix + offHandWeapon.name : null,
                resultArray: attackResult,
                critical:crit,
                base:base,
                roll:roll,
                tpp:tpp,
                effect: effect,
                note: notes
              },{
                lastTest:"agility", // update these as part of sendChat to avoid double changes
                status:newStatus,
                resources:newResources,
                lastRoll:roll
              },this.state.id);  
            }
          } //end inner resultFunction
        }); //end inner newDialogue
      } //end outer resultFunction
    }); //end outer newDialogue
  }
  
  strike = (weapon) => { // make attack test (including damage) and post to chat
    let attackOptions = [];
    let i=0;
    for(i=0; i<=this.getMaxMeleeAttacks(); i++) {
      attackOptions.push({name:i.toString(),value:i.toString()});
    };
    this.props.newDialogue({
      title: "Number of Attacks",
      questions: {
        primary: {name:"Number of Primary Attacks",inputType:"dropdown",default:"1",options:attackOptions},
        offHand: {name:"Number of Off-hand Attacks",inputType:"dropdown",default:"0",options:attackOptions}
      },
      resultFunction:(result1) => {
        let primaryAttacks = Number(result1.primary);
        let offHandAttacks = Number(result1.offHand);
        let usesTwoWeapons = false;
        let myQuestions = {};
        if(primaryAttacks + offHandAttacks === 0) return;
        if(primaryAttacks + offHandAttacks === 1) {
          myQuestions.type = {name:"Type: ",inputType:"dropdown",default:"standardAttack",
            options:[{name:"Standard Attack",value:"standardAttack"},{name:"Debilitating Attack",value:"debilitatingAttack"},
              {name:"Disarm",value:"disarm"},{name:"Grab",value:"grab"},{name:"Knockout",value:"knockout"}]};
          myQuestions.charge = {name: "Charge?",inputType:"checkbox",default:false};
          myQuestions.power = {name: "Power?",inputType:"checkbox",default:false};
        }
        if(offHandAttacks === 0) myQuestions.twoHanded = {name:"Two Handed?",inputType:"checkbox",default:weapon.twoHanded};
        if(primaryAttacks > 0 && offHandAttacks > 0) {
          usesTwoWeapons = true;
          let defaultIndex = 0;
          let weaponOptions = [];
          this.state.resources.meleeWeapons.forEach((wpn,idx) => {
            weaponOptions.push({name:cap(wpn.name),value:idx});
            if(JSON.stringify(wpn) === JSON.stringify(weapon)) defaultIndex = idx;
          });
          myQuestions.offHandWeapon = {name:"Off-hand Weapon",inputType:"dropdown",default:defaultIndex,options:weaponOptions}
        }
        let targetQuestion = this.getTargetingQuestion();
        for(i=0; i<Math.max(primaryAttacks,offHandAttacks); i++) {
          if(i<primaryAttacks) {
            targetQuestion.name = "Main-Hand Attack " + (i+1) + " Target: ";
            myQuestions["primary" + i] = {...targetQuestion};
          }
          if(i<offHandAttacks) {
          targetQuestion.name = "Off-Hand Attack " + (i+1) + " Target: ";
          myQuestions["offHand" + i] = {...targetQuestion};
          }
        }
        this.props.newDialogue({
          title: "Melee Attack Options",
          questions: myQuestions,
          resultFunction:(result) => {
            let abort = false;
            let notes = [];
            let bra = this.getAspect("brawn");
            let currentStaminaCost = 0; 
            if(!result.type) result.type = "standardAttack";
            let attackType = cap(result.type);
            let pdPen = 0; //pdPen = physical defense penalty
            if(result.charge) {
              notes.push("Charge");
              pdPen += 2;
              attackType = null;
            }
            if(result.power) {
              notes.push("Power Attack");
              pdPen++;
              attackType = null;
            }
            if(pdPen > 0) notes.push("(-" + pdPen + " to your Phys Def)");
            if(result.type === "knockout") currentStaminaCost = 1;
            if(result.power) currentStaminaCost++;
            let offHandWeapon = null;
            if(usesTwoWeapons) offHandWeapon = this.state.resources.meleeWeapons[result.offHandWeapon];
            if(primaryAttacks === 0) offHandWeapon = weapon;
            let weaponWeight = 1;
            let offHandWeaponWeight = 1;
            if(weapon.weightRank === "medium") weaponWeight = 2;
            if(weapon.weightRank === "heavy") weaponWeight = 3;
            if(offHandWeapon) {
              if(offHandWeapon.weightRank === "medium") offHandWeaponWeight = 2;
              if(offHandWeapon.weightRank === "heavy") offHandWeaponWeight = 3;
            }
            if(primaryAttacks === 0) {
              currentStaminaCost += (offHandAttacks - 1) * weaponWeight; // first attack is free
            } else {
              currentStaminaCost += (primaryAttacks - 1) * weaponWeight;
              if(usesTwoWeapons) currentStaminaCost += offHandAttacks * offHandWeaponWeight;
            }
            if(currentStaminaCost > bra) {
              if(!abort){
                notes.push("Aborting Attack:");
                abort = true;
              }
              notes.push("Insufficient Brawn");
            } 
            if(currentStaminaCost > this.state.status[this.currentGroupMember].stamina + 1) {
              if(!abort) {
                notes.push("Aborting Attack:");
                abort = true;
              }
              notes.push("Insufficient Stamina");
            }
            let base = this.getAspect("agility") + this.getSkill("fighter"); // get base attack result
            let roll = this.roll();
            let crit = null;
            if(roll === 1) crit = "Possible Critical Fail";
            if(roll === 8) crit = "Possible Critical Success";
            let tpp = this.state.status[this.currentGroupMember].totalPainPenalty;
            let baseDamage = Number(weapon.damage); // start calculating damage
            let offHandBaseDamage = 0;
            if(result.power) { // apply power attack effects if applicable
              base += -1;
              baseDamage += 4;
              offHandBaseDamage += 4;
            }
            let offHandBase = base;
            if(offHandAttacks > 0) {
              offHandBaseDamage = Number(offHandWeapon.damage);
              offHandBase += -4;
              if(this.state.traits.natural.ambidextrous) offHandBase += 4;
            }
            baseDamage += 2 * bra;
            offHandBaseDamage += 2 * bra;
            if(result.twoHanded) baseDamage += 2;
            let brute = 0
            if(this.hasTrait("natural","brute")) {
              brute = 3;
              if(this.hasTrait("epic","epicBrute")) brute+= bra;
              if(this.hasTrait("epic","supremeBrute")) brute+= bra;
            }
            baseDamage += brute;
            offHandBaseDamage += brute;
            let dmgRoll = this.roll();
            let dmg = baseDamage + dmgRoll;
            let offHandDmg = offHandBaseDamage + dmgRoll;
            if(this.hasTrait("natural","martialArtist")) {
              if(weapon.type === "unarmed") dmg += 5;
              if(offHandAttacks > 0 && offHandWeapon.type === "unarmed") offHandDmg += 5;
            }
            let ap = weapon.ap;
            let offHandAP = ap;
            if(offHandAttacks > 0) offHandAP = offHandWeapon.ap;
            let effect = null;
            if(result.type === "debilitatingAttack") {
              dmg = null;
              effect = "causes TAP";
            }else if(result.type === "grab") {
              dmg = null;
              effect = "free Grapple";
            } else if(result.type === "disarm") {
              effect = "possible disarm"
            } else if(result.type === "knockout") {
              effect = "possible knockout"
            }
            let newResources = this.state.resources;
            let newStatus = this.state.status;
            newStatus[this.currentGroupMember].stamina = newStatus[this.currentGroupMember].stamina - currentStaminaCost;
            let mainHandPrefix = usesTwoWeapons ? "(MH) " : "";
            let offHandPrefix = usesTwoWeapons ? "(OH) " : "";
            let hands = "Main-Hand";
            if(result.twoHanded) hands = "Two-Handed";
            else if(usesTwoWeapons) hands = "Paired Attack";
            else if(primaryAttacks === 0) hands = "Off-Hand";
            let attackResult = [];
            let targetingPenalty = 0;
            let channel = "";
            if(this.channelAmountForAspect("agility")>0) {
              channel = " (+" + this.channelAmountForAspect("agility") + ")";
            }
            for(i = 0; i < Math.max(primaryAttacks,offHandAttacks); i++) {
              if(i<primaryAttacks) {
                targetingPenalty = (result["primary"+i] === "torso" ? 0 : 2);
                attackResult.push(mainHandPrefix + cap(result["primary"+i]) + ": " + 
                  (base + roll - tpp - i*weaponWeight - targetingPenalty) + channel);
                attackResult.push("(" + (dmg + targetingPenalty) + " Dmg, AP:" + ap + ")");
              }
              if(i<offHandAttacks) {
                targetingPenalty = (result["offHand"+i] === "torso" ? 0 : 2);
                attackResult.push(offHandPrefix + cap(result["offHand"+i]) + ": " + 
                  (offHandBase + roll - tpp - i*offHandWeaponWeight - targetingPenalty) + channel);
                attackResult.push("(" + (offHandDmg + targetingPenalty) + " Dmg, AP:" + offHandAP + ")");
              }
            }
            newStatus = this.validateStatus(newStatus,this.state.dependents,this.state.traits);
            if(abort) {
              this.props.sendChat({
                type: "messages",
                messages: notes
              },{},this.state.id);  // nothing to update if we aborted
            } else {
              this.props.sendChat({
                type: "attack",
                charID: this.state.id,
                name: this.getPublicName(this.currentGroupMember),
                action: "Strike",
                attackType: attackType,
                hands: hands,
                weapon: primaryAttacks > 0 ? mainHandPrefix + weapon.name : null,
                offHandWeapon: offHandAttacks > 0 ? offHandPrefix + offHandWeapon.name : null,
                resultArray: attackResult,
                critical:crit,
                base:base,
                roll:roll,
                tpp:tpp,
                effect: effect,
                note: notes
              },{
                lastTest:"agility", // update these as part of sendChat to avoid double changes
                status:newStatus,
                resources:newResources,
                lastRoll:roll
              },this.state.id);  
            }
          } //end inner resultFunction
        }); //end inner newDialogue
      } //end outer resultFunction
    }); //end outer newDialogue
  }

  drawRitualCircle = (spell,check) => { // handles the first part of ritual spellcasting, posting results for GM only
    if(spell === "disenchant") {
      let title = "Casting the Ritual Version of the " + cap(spell) + " Spell...";
      let questions = {};
      questions.targetSpell = {
        name:"Ritual to Disenchant: ",
        inputType:"dropdown",
        default: "animate",
        options:[]
      }
      Object.keys(ritualDetails).forEach(key => {
        questions.targetSpell.options.push({name: cap(key), value: key});
      });
      //query user for qi type
      this.props.newDialogue({
        title: title,
        questions: questions,
        resultFunction:(result) => {this.drawRitualCircleCORE(result.targetSpell,check,true)}
      });
    } else {
      this.drawRitualCircleCORE(spell,check,false);
    }
  }

  drawRitualCircleCORE = (spell,check,disenchant) => { // helper function for drawRitualCircle(spell,check)

    // send public chat message so player knows button click worked
    let time = "";
    let ritualName = disenchant ? "disenchant" : spell;
    if(check) time = (ritualDetails[spell].time * 15).toString() + " minutes"; // checking a circle takes less time than initial drawing
    else time = ritualDetails[spell].time.toString() + " hours";
    this.props.sendChat({
      type: "messages",
      messages: [this.getPublicName(this.currentGroupMember) + " " + (check ? "checks" : "draws") + " a Ritual Circle for the " + cap(ritualName) + " spell",
        " Time: " + time,
        " Difficulty: " + ritualDetails[spell].difficulty]
    },{},this.state.id);

    // calculate test result
    let base = this.getAspect("intelligence") + this.getSkill("mage");
    let roll = this.roll();
    let crit = "";
    if(roll === 1) crit = "Possible Critical Fail";
    if(roll === 8) crit = "Possible Critical Success";
    let tpp = this.state.status[this.currentGroupMember].totalPainPenalty;
    let res = base + roll - tpp;
    if(this.channelAmountForAspect("intelligence") > 0) {
      res = res.toString() + " (+" + this.channelAmountForAspect("intelligence").toString() + ")"; 
    }
          
    // send private message to GM
    setTimeout(() => this.props.sendGMChat({
      type:"test",
      name:this.getPublicName(this.currentGroupMember),
      charID: this.state.id,
      skill:"Mage",
      aspect:"Intelligence",
      result:res,
      critical:crit,
      base:base,
      roll:roll,
      tpp:tpp
    },{
      lastTest:"intelligence",lastRoll:roll // update the lastTest as part of sendChat to avoid double changes
    },this.state.id),500);
  }

  castRitual = (spell) => { // handles the second part of ritual spellcasting and posts results to chat

    //prep dialog, special for disenchant
    let title = "Casting the Ritual Version of the " + cap(spell) + " Spell...";
    let questions = {
        qiType: {
          name:"Qi Type: ",
          inputType:"dropdown",
          default: this.usesPositiveQi() ? "positiveQi" : "negativeQi",
          options:[{name:"Positive",value:"positiveQi"},{name:"Negative",value:"negativeQi"}]
        }
      };
    if(spell === "disenchant") {
      questions.targetSpell = {
        name:"Ritual to Disenchant: ",
        inputType:"dropdown",
        default: "animate",
        options:[]
      }
      Object.keys(ritualDetails).forEach(key => {
        questions.targetSpell.options.push({name: cap(key), value: key});
      });
    }

    //query user for qi type
    this.props.newDialogue({
      title: title,
      questions: questions,
      resultFunction:(result) => {
        let availableQi = (result.qiType === "positiveQi") ? this.state.status[this.currentGroupMember].positiveQi : this.state.status[this.currentGroupMember].negativeQi;
        let qi = (spell === "disenchant") ? ritualDetails[result.targetSpell].qi : ritualDetails[spell].qi;
        if(availableQi < qi) {
          this.props.sendChat({
            type: "messages",
            messages: ["Ritual Spellcasting Aborted: Insufficient Qi"]
          },{},this.state.id);
        } else {

          //pay stamina and qi costs
          let newStatus = this.state.status;
          if(result.qiType === "positiveQi") newStatus[this.currentGroupMember].positiveQi -= qi;
          else newStatus[this.currentGroupMember].negativeQi -= qi;
          let availableStamina = this.state.status[this.currentGroupMember].stamina;
          let time = (spell === "disenchant") ? ritualDetails[result.targetSpell].time : ritualDetails[spell].time;
          let staminaCost = time * 2;
          let staminaFactor = 1;
          if(this.hasTrait("epic","supremeEndurance")) staminaFactor = 100;
          else if(this.hasTrait("epic","epicEndurance")) staminaFactor = 20;
          else if(this.hasTrait("natural","greatEndurance")) staminaFactor = 5;
          else if(this.hasTrait("natural","endurance")) staminaFactor = 2;
          staminaCost = Math.round(staminaCost / staminaFactor);
          let exhaustionPenalty = Math.min(0,availableStamina - staminaCost);
          newStatus[this.currentGroupMember].stamina = Math.max(0,this.state.status[this.currentGroupMember].stamina - staminaCost);
          newStatus[this.currentGroupMember].exhaustion = this.state.status[this.currentGroupMember].exhaustion - exhaustionPenalty;

          // fill in details
          let difficulty = (spell === "disenchant") ? ritualDetails[result.targetSpell].difficulty : ritualDetails[spell].difficulty;
          let notes = ["Time: " + time.toString() + " hours"];

          // calculate test result
          let base = this.getAspect("intelligence") + this.getSkill("mage") + exhaustionPenalty;
          let roll = this.roll();
          let crit = "";
          if(roll === 1) crit = "Possible Critical Fail";
          if(roll === 8) crit = "Possible Critical Success";
          let tpp = this.state.status[this.currentGroupMember].totalPainPenalty;
          let res = base + roll - tpp;
          let netres = res - difficulty;
          if(this.channelAmountForAspect("intelligence") > 0) {
            res = res.toString() + " (+" + this.channelAmountForAspect("intelligence").toString() + ")"; 
            netres = netres.toString() + " (+" + this.channelAmountForAspect("intelligence").toString() + ")"; 
          }
                
          // post to chat
          this.props.sendChat({
            type:"cast",
            name:this.getPublicName(this.currentGroupMember),
            spell:cap(spell) + " Ritual",
            qi:qi,
            qiType:cap(result.qiType),
            result: res,
            base: base,
            roll: roll,
            tpp: tpp,
            critical: crit,
            difficulty: difficulty,
            netResult: netres,
            note: notes
            },{
              lastTest:"intelligence", // update these as part of sendChat to avoid double changes
              status:newStatus,
              lastRoll:roll
            },this.state.id
          );
        }
      }
    });
  }

  
  castSpell = (spell,category) => { // casts a standard version of a spell and posts results to chat
    let signatureSpellBonus = this.state.signatureSpell === spell && this.hasTrait("qi","signatureSpell") ? 2 : 0;
    let signatureSpellPower = this.state.signatureSpell === spell && this.hasTrait("qi","greaterSignatureSpell") ? 1 : 0;

    //build dialog with spell options
    let title = "Casting " + cap(spell) + "...";
    let questions = {finesseOptionsLabel: {name: "----Finesse Options----", inputType: "label"}};
    spellData[category][spell].finesseOptions.forEach(opt => {
      questions[opt] = {};
      questions[opt].name = cap(opt) + " (+" + finesseOptions[opt].cost + "): ";
      if(finesseOptions[opt].multiple) {
        questions[opt].inputType = "number";
        questions[opt].default = 0;
      } else {
        questions[opt].inputType = "checkbox";
      }
    });
    questions.spacer = {name: "", inputType: "spacer"};
    questions.powerOptionsLabel = {name: "----Power Options----", inputType: "label"};
    questions.qiType = {name:"Qi Type: ", inputType:"dropdown", options:[
        {name:"Positive",value:"positiveQi"},
        {name:"Negative",value:"negativeQi"}
      ]};
    this.usesPositiveQi() ? questions.qiType.default = "positiveQi" : questions.qiType.default = "negativeQi";
    spellData[category][spell].powerOptions.forEach(opt => {
      let maxPower = Math.min(this.powerLimit(),powerOptionsMax[opt]);
      let myPowerOptions = [];
      for(let i=0;i<maxPower+1;i++) {
        myPowerOptions.push({
          name: i.toString(),
          value: i
        })
      };
      questions[opt] = {
        name: cap(opt) + ": ",
        inputType: "dropdown",
        default: "0",
        options: myPowerOptions
      };
    });
    
    //launch dialog
    this.props.newDialogue({
      title: title,
      questions: questions,
      resultFunction:(result) => {
        let abort = false;
        let notes = ["Spellcasting Aborted:"];
        let qi = 1;
        spellData[category][spell].powerOptions.forEach(opt => {
          qi += Number(result[opt]);
        })
        qi = Math.max(1,qi - signatureSpellPower);
        if(qi > 1 + Math.min(this.powerLimit())) {
          abort = true;
          notes.push("Insufficient Power");
        }
        if(qi > this.state.status[this.currentGroupMember][result.qiType]) {
          abort = true;
          notes.push("Insufficient Qi");
        }
        if(abort) {
          this.props.sendChat({
            type: "messages",
            messages: notes
          },{},this.state.id);
        } else {
          
          // calculate difficulty
          let difficulty = spellData[category][spell].difficulty;
          spellData[category][spell].finesseOptions.forEach(opt => {
            if(finesseOptions[opt].multiple) difficulty += result[opt] * finesseOptions[opt].cost;
            else difficulty += (result[opt] ? finesseOptions[opt].cost : 0);
          });
          
          // calculate range
          let range = spellData[category][spell].range;
          if(range === "touch") {
            if(result.distantTarget) range = this.state.dependents.activeQiRange;
            else range = 0;
          } else if(range === "AQR") range = this.state.dependents.activeQiRange;
          else if(range === "AQRx100") range = this.state.dependents.activeQiRange * 100;
          else range = NaN;
          if(range > 0) {
            if(result.multipliedRange) {
              range = range * Math.pow(10,result.multipliedRange);
            }
            else if(result.addedRange) {
              range = range * (Number(result.addedRange) + 1);
            }
          }
          
          // format range
          if(range === 0) range = "Touch";
          else if(range >= 1000000) range = (range/1000000).toString() + "M Paces";
          else if(range >= 10000) range = (range/1000).toString() + "k Paces";
          else if(range > 0) range = range.toString() + " Paces";
          else range = "NaN";
          
          // calculate duration
          let duration = spellData[category][spell].duration;
          if(duration === "instant") duration = "Instant";
          else {
            let unitsIndex = spellDurations.indexOf(duration);
            if(result.increasedDuration) unitsIndex += Number(result.increasedDuration);
            else if(result.increasedAgeLimit) unitsIndex += Number(result.increasedAgeLimit);
            duration = this.getAspect("impression");
            if(spellDurations[unitsIndex] === "10minutes") duration = (duration*10).toString() + " Minutes";
            else duration = duration.toString() + " " + cap(spellDurations[unitsIndex]);
          }
          
          // calculate damage
          let damage = spellData[category][spell].damage;
          if(damage > 0) {
            let multiplier = 2;
            if(result.increasedDamage && result.increasedDamage > 0) {
              multiplier += Number(result.increasedDamage);
              if(this.hasTrait("qi","combatMage")) {
                let bonusMult = 0.5;
                let stb = (result.areaOfEffect < 1) && this.hasTrait("epic","supremeCombatMage"); // Single Target Bonus from Supreme Power Mage trait
                if(this.hasTrait("epic","epicCombatMage") && result.increasedDamage > 1) bonusMult = 1;
                multiplier += stb ? 2 * bonusMult : bonusMult;
              }
            }
            damage += Math.floor(this.getAspect("impression") * multiplier) + this.roll();
          } else damage = null;
          
          // pay qi cost
          let newStatus = this.state.status;
          if(result.qiType === "positiveQi") newStatus[this.currentGroupMember].positiveQi -= qi;
          else newStatus[this.currentGroupMember].negativeQi -= qi;
          
          // calculate test result
          let base = this.getAspect("intelligence") + this.getSkill("mage") + signatureSpellBonus;
          let roll = this.roll();
          let crit = "";
          if(roll === 1) crit = "Possible Critical Fail";
          if(roll === 8) crit = "Possible Critical Success";
          let tpp = this.state.status[this.currentGroupMember].totalPainPenalty;
          let res = base + roll - tpp;
          let netres = res - difficulty;
          if(this.channelAmountForAspect("intelligence") > 0) {
            res = res.toString() + " (+" + this.channelAmountForAspect("intelligence").toString() + ")"; 
            netres = netres.toString() + " (+" + this.channelAmountForAspect("intelligence").toString() + ")"; 
          }
                
          // list applied options
          let notes = [];
          spellData[category][spell].finesseOptions.forEach(opt => {
            if(finesseOptions[opt].multiple) {
              if(result[opt] > 0) notes.push(cap(opt) + ": " + result[opt]);
            }
            else {
              if(result[opt]) notes.push(cap(opt));
            }
          });
          spellData[category][spell].powerOptions.forEach(opt => {
            if(result[opt] > 0) notes.push(cap(opt) + ": " + result[opt]);
          });
          
          // post to chat
          this.props.sendChat({
            type:"cast",
            name:this.getPublicName(this.currentGroupMember),
            spell:cap(spell),
            qi:qi,
            qiType:cap(result.qiType),
            result: res,
            base: base,
            roll: roll,
            tpp: tpp,
            critical: crit,
            difficulty: difficulty,
            netResult: netres,
            note: notes,
            range: range,
            duration: duration,
            damage: damage
            },{
              lastTest:"intelligence", // update these as part of sendChat to avoid double changes
              status:newStatus,
              lastRoll:roll
            },this.state.id
          );
        }
      }
    });
  }

  powerLimit = () => { // returns the max amount of qi you can spend on spells
    let result = 0;
    if(this.isCaster()) {
      result++;
      if(this.hasTrait("qi","powerMage")) result++;
      if(this.hasTrait("qi","greaterPowerMage")) result++;
      if(this.hasTrait("epic","epicPowerMage")) result++;
      if(this.hasTrait("epic","supremePowerMage")) result++;
    }
    return result;
  }

  numPhilosophiesForAspect = (aspect) => { // how many philosophies apply to the specified aspect
    let act = this.state.traits.qi.philosophyOfAction ? 1 : 0;
    let bal = this.state.traits.qi.philosophyOfBalance ? 1 : 0;
    let bod = this.state.traits.qi.philosophyOfBody ? 1 : 0;
    let fin = this.state.traits.qi.philosophyOfFinesse ? 1 : 0;
    let min = this.state.traits.qi.philosophyOfMind ? 1 : 0;
    let str = this.state.traits.qi.philosophyOfStrength ? 1 : 0;
    let result = 0;
    if(aspect === "brawn") result = act + bod + str;
    else if(aspect === "toughness") result = bal + bod + str;
    else if(aspect === "agility") result = act + bod + fin;
    else if(aspect === "reflex") result = bal + bod + fin;
    else if(aspect === "impression") result = act + min + str;
    else if(aspect === "serenity") result = bal + min + str;
    else if(aspect === "intelligence") result = act + min + fin;
    else if(aspect === "awareness") result = bal + min + fin;
    return result;
  }

  channelAmountForAspect = (aspect) => { // what bonus would be given for channeling qi for last test'
    if(this.state.traits.qi.qiSensitivity) {
      return 1 + Math.min(2,this.numPhilosophiesForAspect(aspect))
    }
    return 0;
  }

  channelAmount = () => { // what bonus would be given for channeling qi for last test
    return this.channelAmountForAspect(this.state.lastTest);
  }

  channel = (type) => { // channels qi for the previous test and posts results to chat
    if((type === "positive" && this.state.status[this.currentGroupMember].positiveQi > 0) || (type === "negative" && this.state.status[this.currentGroupMember].negativeQi > 0)) {
      let newStatus = this.state.status;
      if(type === "positive") newStatus[this.currentGroupMember].positiveQi--;
      if(type === "negative") newStatus[this.currentGroupMember].negativeQi--;
      newStatus = this.validateStatus(newStatus,this.state.dependents,this.state.traits); // send newStatus as part of sendChat to avoid double changes
      let message = this.getPublicName(this.currentGroupMember) + " channels " + type + " qi for a +" + this.channelAmount() + " bonus"
      this.props.sendChat(message,{status:newStatus,lastTest:""},this.state.id);
    }
  }

  //IMPORTANT: do NOT set sendToChat to TRUE if you are doing any other updating (including sendChat and passInitiative);
  rollLuck = (sendToChat) => { // luck rolls are basic 1d8, sometimes used alone
    let result = 1 + Math.floor(Math.random() * 8);
    if(sendToChat) {
      let message = this.getPublicName(this.currentGroupMember) + " rolls luck and gets " + (result === 8 ? "an " : "a ") + result; 
      this.props.sendChat(message,{},this.state.id);
    }
    return result;
  }

  //IMPORTANT: do NOT set sendToChat to TRUE if you are doing any other updating (including sendChat and passInitiative);
  roll = (sendToChat, advantage) => { // standard dice roll is middle die in 3d8
    let index = 1;
    let advantageText = "";
    let differenceText = "";
    if(advantage==="advantage") {
      index = 2;
      advantageText = " with Advantage ";
    }
    if(advantage==="disadvantage") {
      index = 0;
      advantageText = " with Disadvantage ";
    }
    let dice=[];
    for(let i=0;i<3;i++) dice.push(this.rollLuck());
    dice.sort();
    let result = dice[index];
    if(this.state.lastRoll > 0) {
      if(advantage==="advantage") differenceText = " (+" + Math.max(0,result - this.state.lastRoll)+ ")";
      if(advantage==="disadvantage") differenceText = " (-" + Math.max(0,this.state.lastRoll - result)+ ")";
    }
    if(sendToChat) {
      let message = this.getPublicName(this.currentGroupMember) + " rolls" + advantageText + " and gets " + (result === 8 ? "an " : "a ") + result + differenceText; 
      this.props.sendChat(message,{},this.state.id);
    }
    return result;
  }

  rollInitiative = () => { // roll initiative, this includes fractional values which establish order for ties
    this.updateElements({currentInitiative:Number(this.state.dependents.baseInitiative) * 1.01 + 
      this.roll() + Math.random()/1000 - this.state.status[this.currentGroupMember].totalPainPenalty});
  }

  endTurn = () => { // handle standard end of turn effects and pass initiative to next in list
    let newStatus = this.state.status;
    newStatus[this.currentGroupMember].tap--;
    if(this.state.traits.epic.supremeFortitude) newStatus[this.currentGroupMember].tap--;
    newStatus = this.validateStatus(newStatus,this.state.dependents,this.state.traits);
    this.props.passInitiative({status:newStatus}); // send newStatus along with passInitiative to avoid double changes
  }

  meditate = (mode) => { // increase current qi and post to chat
    let newStatus = this.state.status;
    if(this.getSkill("mage") > 0) {
      let qi = this.getAspect("serenity") + this.getSkill("mage");
      if(this.hasTrait("qi","deepMeditation")) qi += this.getAspect("serenity");
      let capacity = mode === "positive" ? this.state.dependents.positiveQiCapacity : this.state.dependents.negativeQiCapacity;
      qi = Math.min(qi,capacity - (mode === "positive" ? newStatus[this.currentGroupMember].positiveQi : newStatus[this.currentGroupMember].negativeQi));
      if(mode==="positive") newStatus[this.currentGroupMember].positiveQi += qi;
      if(mode==="negative") newStatus[this.currentGroupMember].negativeQi += qi;
      newStatus = this.validateStatus(newStatus,this.state.dependents,this.state.traits); // send newStatus along with sendChat to avoid double changes
      let message = this.getPublicName(this.currentGroupMember) + " meditated and recovered " + qi + " " + mode + " qi";
      this.props.sendChat(message,{status:newStatus},this.state.id);
    }
  }

  zenArt = (mode) => { // increase current qi and post to chat
    let newStatus = this.state.status;
    if(this.getSkill("artist") > 0) {
      let qi = this.getAspect("serenity") + this.getSkill("artist");
      if(mode==="positive") {
        qi = Math.min(qi,this.state.dependents.positiveQiCapacity - this.state.status[this.currentGroupMember].positiveQi);
        newStatus[this.currentGroupMember].positiveQi += qi;
      }
      if(mode==="negative") {
        qi = Math.min(qi,this.state.dependents.negativeQiCapacity - this.state.status[this.currentGroupMember].negativeQi);
        newStatus[this.currentGroupMember].negativeQi += qi;
      }
      newStatus = this.validateStatus(newStatus,this.state.dependents,this.state.traits); // send newStatus along with sendChat to avoid double changes
      let message = this.getPublicName(this.currentGroupMember) + " used Zen Art and recovered " + qi + " " + mode + " qi";
      this.props.sendChat(message,{status:newStatus},this.state.id);
    }
  }

  zenPerformance = (mode) => { // increase current qi and post to chat
    let newStatus = this.state.status;
    if(this.getSkill("performer") > 0) {
      let qi = this.getAspect("serenity") + this.getSkill("performer");
      if(mode==="positive") {
        qi = Math.min(qi,this.state.dependents.positiveQiCapacity - this.state.status[this.currentGroupMember].positiveQi);
        newStatus[this.currentGroupMember].positiveQi += qi;
      }
      if(mode==="negative") {
        qi = Math.min(qi,this.state.dependents.negativeQiCapacity - this.state.status[this.currentGroupMember].negativeQi);
        newStatus[this.currentGroupMember].negativeQi += qi;
      }
      newStatus = this.validateStatus(newStatus,this.state.dependents,this.state.traits); // send newStatus along with sendChat to avoid double changes
      let message = this.getPublicName(this.currentGroupMember) + " used Zen Art and recovered " + qi + " " + mode + " qi";
      this.props.sendChat(message,{status:newStatus},this.state.id);
    }
  }

  rest = () => { // recover stamina and/or exhaustion
    //create dialog to allow user to specify time
    this.props.newDialogue({
      title: "Resting",
      questions: {
        hours: {name:"Hours: ",inputType:"number",default:0},
        minutes: {name:"Minutes: ",inputType:"number",default:0}
      },
      resultFunction:(result) => { //process dialog results
        let newStatus = this.state.status;
        let hours = Math.max(0,result.hours ? result.hours : 0);
        let minutes = Math.max(0,result.minutes ? result.minutes : 0);
        let totalMinutes = hours * 60 + minutes;
        let stamina = Math.floor(this.getAspect("toughness") * Math.floor(2 * totalMinutes / this.state.dependents.staminaRecoveryTime) / 2);
        let eWounds = Math.floor(totalMinutes / 240);
        let totalTAP = totalMinutes * 20;
        if(this.state.traits.epic.supremeFortitude) totalTAP = 2 * totalTAP;
        totalTAP = Math.min(totalTAP, this.state.status[this.currentGroupMember].tap);
        stamina = Math.min(stamina,this.state.dependents.staminaCapacity - this.state.status[this.currentGroupMember].stamina);
        eWounds = Math.min(eWounds,this.state.status[this.currentGroupMember].exhaustion);
        newStatus[this.currentGroupMember].stamina = newStatus[this.currentGroupMember].stamina + stamina;
        newStatus[this.currentGroupMember].exhaustion = newStatus[this.currentGroupMember].exhaustion - eWounds;
        newStatus[this.currentGroupMember].tap = newStatus[this.currentGroupMember].tap - totalTAP;
        newStatus = this.validateStatus(newStatus,this.state.dependents,this.state.traits);
        if(this.isTimeWalkerInitiate() && hours >= 4) newStatus[this.currentGroupMember].foresight = Math.max(1,newStatus[this.currentGroupMember].foresight);
        let message = this.getPublicName(this.currentGroupMember) + " rested for " + hours + " hours and " + minutes + 
          " minutes and recovered " + stamina + " stamina, " + eWounds + " exhaustion, and " + totalTAP + " TAP";
        this.props.sendChat(message,{status:newStatus},this.state.id);
      }
    });
  }

  usesPositiveQi = () => { //helper function provides result without access to all trait data
    return this.state.traits.qi.qiSensitivity && !this.state.traits.qi.negativeQi;
  }

  usesNegativeQi = () => { //helper function provides result without access to all trait data
    return this.state.traits.qi.qiSensitivity && !this.state.traits.qi.positiveQi;
  }

  getRankLimit = () => {
    if(this.state.traits.epic.supremeAdvancement) return 16;
    if(this.state.traits.epic.epicAdvancement) return 12;
    return 8;
  }

  getArmor = (location,netResult) => { //returns armor values at specified location and given netResult of attack test
    let result = 0;
    this.state.resources.armor.forEach(armor => {
      if(armor.equipped && armor.location === location){
        if(armor.cover > netResult) result += armor.value;
      }
    });
    return result;
  }

  takeDamage = () => { // dialog to cause character to take damage and apply results
    this.props.newDialogue({ //launch dialog to get inputs from user
      title: "Taking Damage: ",
      questions: {
        damage: {name:"Damage: ",inputType:"number",default:0},
        armorPenetration: {name:"Armor Penetration: ",inputType:"number",default:0},
        location: {name:"Location: ",inputType:"dropdown", default:"torso", options:[{name:"Head",value:"head"},
          {name:"Torso",value:"torso"},{name:"Left Arm",value:"leftArm"},{name:"Right Arm",value:"rightArm"},
          {name:"Left Leg",value:"leftLeg"},{name:"Right Leg",value:"rightLeg"}]},
        netResult: {name:"Attack Net Result: ",inputType:"number",default:0},
        bonusDR: {name:"Bonus DR: ",inputType:"number",default:0},
        bleed: {name:"Bleed: ",inputType:"dropdown", default:"standard", options:[{name:"None",value:"none"},
          {name:"Standard",value:"standard"},{name:"Heavy",value:"heavy"}]}
      },
      resultFunction:(result) => {
        let newStatus = this.state.status;
        let netArmor = Math.max(0,this.getArmor(result.location,result.netResult) - result.armorPenetration);
        let netDamage = result.damage + result.netResult - netArmor - this.state.dependents.damageResistance - result.bonusDR;
        if(netDamage >0) {
          let woundRank = Math.floor(netDamage / 5);
          let tap = woundRank + 1;
          if(result.location === "head") tap = 2*tap;
          newStatus[this.currentGroupMember].tap += tap;
          if(woundRank > 0) {
            let bleedRank = 0;
            if(result.bleed === "standard") bleedRank = woundRank;
            if(result.bleed === "heavy") bleedRank = woundRank + 1;
            newStatus[this.currentGroupMember].wounds.push({
              location:result.location,
              rank:woundRank,
              bleed:bleedRank
            });
          }
          newStatus = this.validateStatus(newStatus,this.state.dependents,this.state.traits);
          let message = this.getPublicName(this.currentGroupMember) + " suffers a -" + tap + " TAP";
          if(woundRank > 0) message += " and a rank " + woundRank + " wound to the " + result.location;
          this.props.sendChat(message,{status:newStatus},this.state.id);
        } else {
          this.props.sendChat(this.getPublicName(this.currentGroupMember) + " is unaffected by the attack",{},this.state.id);
        }
      }
    });
  }

  updateTimeWalker = (isTimeWalker) => {
    this.updateElements({timeWalker:isTimeWalker});
  }

  addTrait = (type,name) => { // add a new trait to list of known traits
    let newTraits = this.state.traits;
    newTraits[type][name] = true;
    let newGifts = this.validateGifts(this.state.gifts);
    this.updateTraits(newTraits,this.state.aspects,newGifts);
  }

  removeTrait = (type,name) => { //remove from list of known traits
    let newAspects = this.state.aspects;
    let newGifts = this.state.gifts;
    newGifts = this.validateGifts(newGifts);
    if(type === "qi" && name.substr(0,12) === "philosophyOf") {
      Object.keys(newAspects).forEach((aspect) => {
        if(this.philosophyBonus(aspect) > 0) {
          newAspects[aspect].bonus -= this.philosophyBonus(aspect);
        }
      });
    }
    if(type === "bestowed") {
      let giftNumber = 0;
      let aspect = "none";
      if(name.substr(0,8) === "avatarOf") giftNumber = 4;
      else if(name.substr(0,8) === "chosenOf") giftNumber = 2;
      else if(name.substr(0,10) === "initiateOf" && name !== "initiateOfTheTimeWalkers") giftNumber = 1;
      else if(["angel","tyrant","liberator","master","keeper","defiler"].indexOf(name) >= 0) giftNumber = 3;
      if(giftNumber > 0) {
        if(giftNumber === 4) {
          newGifts[1].greater = false;
          aspect = newGifts[1].aspect;
        } else if(giftNumber === 3) {
          if(newGifts[0].greater) {
            newGifts[0].greater = false;
            aspect = newGifts[0].aspect;
          } else {
            newGifts[1].greater = false;
            aspect = newGifts[1].aspect;
          }
        } else if(giftNumber === 2) {
          aspect = newGifts[1].aspect;
          newGifts[1].aspect = "none";
        } else if(giftNumber === 1) {
          aspect = newGifts[0].aspect;
          newGifts[0].aspect = "none";
        }
        if(aspect !== "none") newAspects[aspect].bonus--;
      }
    }
    let newTraits = this.state.traits;
    delete newTraits[type][name];
    this.updateTraits(newTraits,newAspects,newGifts);
  }

  addSpell = (category,name) => { // add to list of known spells
    let newSpells = this.state.spells;
    newSpells[category][name] = {
      ritual: false
    };
    this.updateSpells(newSpells);
  }

  removeSpell = (category,name) => { // remove from list of known spells
    let newSpells = this.state.spells;
    delete newSpells[category][name];
    this.updateSpells(newSpells);
  }

  isAligned = (type) => { // check if you are aligned in the specified way
    if(type === "positive") return this.hasTrait("qi","positiveQi");
    if(type === "negative") return this.hasTrait("qi","negativeQi");
    return true;
  }

  knowsAlignedSpells = () => { // does character know any positive or negative spells
    return this.state.spells.basic.darkness ||
      this.state.spells.basic.light ||
      this.state.spells.basic.disruption ||
      this.state.spells.basic.locate ||
      this.state.spells.advanced.disenchant ||
      this.state.spells.advanced.preservation ||
      this.state.spells.advanced.memoryBlock ||
      this.state.spells.advanced.noticeNot ||
      this.state.spells.advanced.wither ||
      this.state.spells.advanced.scrye ||
      this.state.spells.advanced.heal ||
      this.state.spells.advanced.objectHistory ||
      this.state.spells.epic.nightmare ||
      this.state.spells.epic.disintegrate ||
      this.state.spells.epic.portal ||
      this.state.spells.epic.resurrection;
  }

  knowsSpells = (category) => { // does character know any spells
    return Object.keys(this.state.spells[category]).length > 0;
  }

  pcText = () => {
    return this.state.isPC ? "PC" : "NPC";
  }

  updateAllegiance = (newAllegiance) => {
    this.updateElements({allegiance:newAllegiance});
  }

  updateSignatureSpell = (newSignatureSpell) => {
    this.updateElements({signatureSpell:newSignatureSpell});
  }

  updateNotes = (newNotes) => {
    this.updateElements({notes:newNotes});
  }

  setGiftType = (index,greater) => {
    let newGifts = this.state.gifts;
    newGifts[index].greater = greater;
    newGifts = this.validateGifts(newGifts);
    let aspect = newGifts[index].aspect;
    if(aspect === "none") {
      this.updateElements({gifts:newGifts});
    } else {
      let newAspects = this.state.aspects;
      newAspects[aspect].bonus += greater ? 1 : -1;
      newAspects = this.validateAspects(newAspects);
      let newDependents = this.validateDependents(newAspects,this.state.skills,this.state.traits);
      let newStatus = this.validateStatus(this.state.status,newDependents,this.state.traits);
      this.updateElements({gifts:newGifts,aspects:newAspects,dependents:newDependents,status:newStatus});
    }
  }

  setGiftAspect = (index,aspect) => {
    let oldAspect = this.state.gifts[index].aspect;
    let newGifts = this.state.gifts;
    newGifts[index].aspect = aspect;
    newGifts = this.validateGifts(newGifts);
    let giftSize = newGifts[index].greater ? 2 : 1;
    let newAspects = this.state.aspects;
    if(oldAspect !== "none") {
      newAspects[oldAspect].bonus -= giftSize;
    }
    if(aspect !== "none") {
      newAspects[aspect].bonus += giftSize;
    }
    newAspects = this.validateAspects(newAspects);
    let newDependents = this.validateDependents(newAspects,this.state.skills,this.state.traits);
    let newStatus = this.validateStatus(this.state.status,newDependents,this.state.traits);
    this.updateElements({gifts:newGifts,aspects:newAspects,dependents:newDependents,status:newStatus});
  }

  numGifts = () => { //helper function to return the number of gift granting traits the character has
    return this.numGiftsCORE(this.state.traits);
  }

  numGiftsCORE = (newTraits) => {
    switch(this.state.allegiance) {
      case "creation":
        if(newTraits.bestowed["avatarOfTheGuardiansOfCreation"]) return 4;
        if(newTraits.bestowed["keeper"]) return 3;
        if(newTraits.bestowed["chosenOfTheGuardiansOfCreation"]) return 2;
        if(newTraits.bestowed["initiateOfTheGuardiansOfCreation"]) return 1;
        return 0;
      case "destruction":
        if(newTraits.bestowed["avatarOfTheCorruptedHeart"]) return 4;
        if(newTraits.bestowed["defiler"]) return 3;
        if(newTraits.bestowed["chosenOfTheCorruptedHeart"]) return 2;
        if(newTraits.bestowed["initiateOfTheCorruptedHeart"]) return 1;
        return 0;
      case "mercy":
        if(newTraits.bestowed["avatarOfTheArmOfMercy"]) return 4;
        if(newTraits.bestowed["angel"]) return 3;
        if(newTraits.bestowed["chosenOfTheArmOfMercy"]) return 2;
        if(newTraits.bestowed["initiateOfTheArmOfMercy"]) return 1;
        return 0;
      case "oppression":
        if(newTraits.bestowed["avatarOfTheFistOfOppression"]) return 4;
        if(newTraits.bestowed["tyrant"]) return 3;
        if(newTraits.bestowed["chosenOfTheFistOfOppression"]) return 2;
        if(newTraits.bestowed["initiateOfTheFistOfOppression"]) return 1;
        return 0;
      case "nature":
        if(newTraits.bestowed["avatarOfTheChampionsOfNature"]) return 4;
        if(newTraits.bestowed["liberator"]) return 3;
        if(newTraits.bestowed["chosenOfTheChampionsOfNature"]) return 2;
        if(newTraits.bestowed["initiateOfTheChampionsOfNature"]) return 1;
        return 0;
      case "manipulation":
        if(newTraits.bestowed["avatarOfThePuppeteers"]) return 4;
        if(newTraits.bestowed["master"]) return 3;
        if(newTraits.bestowed["chosenOfThePuppeteers"]) return 2;
        if(newTraits.bestowed["initiateOfThePuppeteers"]) return 1;
        return 0;
      default:
        return 0;
    }
  }

  giftBonus = (aspect) => {
    for(let i=0;i<2;i++) {
      if(this.state.gifts[i].aspect === aspect) return this.state.gifts[i].greater ? 2 : 1;
    }
    return 0;
  }

  validateGifts = (newGifts) => {
    if(this.numGifts() === 3) {
      if(!newGifts[0].greater && !newGifts[1].greater) newGifts[0].greater = true;
    } else if(this.numGifts() === 4) {
      newGifts[0].greater = true;
      newGifts[1].greater = true;
    }  
    return newGifts;
  }

  philosophyBonus = (aspect) => {
    return this.numPhilosophiesForAspect(aspect) === 3 ? 1 : 0;
  }

  postPublicDescription = () => {
    this.props.sendChat(this.getPublicName() + ": " + this.state.NPCData.publicDescription,{},this.state.id);
  }

  togglePC = () => {
    this.currentGroupMember = 0;
    this.updateElements({isPC:!this.state.isPC})
  }

  render() {
    let playerSelectHTML = []; // for GM's dropdown to assign character to a player
    Object.values(this.props.players).forEach((player) => {
        playerSelectHTML.push(<option key={player.id} value={player.id}>{player.name}</option>);
    });

    // allows for coupled tabs for character creation and decoupled for play mode
    let mySelectedTab = (this.props.isGM) ? this.localSelectedTab : this.state.selectedTab;
    if(this.props.myCharacterState.collapsed) mySelectedTab = "compact";

    let settingsHTML = []
    this.props.settingsList.forEach((setting) => {
      if(setting !== "All") settingsHTML.push(<option key={setting} value={setting}>{setting}</option>);
    });

    return(
      <div className={"character container" + (this.state.isCurrentInitiative ? " my-turn" : "") + (this.state.isPC && this.getRemainingIP() < 0 ? " over-budget" : "")}>

        {/* top row with identifiers and management tools */}
        <button className="minimizer" onClick={() => this.toggleMinimized()}>{this.props.myCharacterState.collapsed ? ">" : "v"}</button>
        <input className="characterName" value={this.state.name}
          onChange={(e)=> this.updateElements({name:e.target.value})}
          disabled={!this.state.editMode}/>
        {this.props.isGM && <select className="settingName" value={this.state.settingName}
          onChange={(e)=> this.updateElements({settingName:e.target.value})}>
            {settingsHTML}
          </select>}
        {(!this.props.forcePlayMode || this.props.isGM) && (<button className="editMode" onClick={()=>  this.updateElements({editMode:!this.state.editMode})}>{this.getEditString()}</button>)}
        {(this.props.isGM && (<button className="duplicate" onClick={()=> this.props.duplicateCharacter()}>Duplicate</button>))}
        {(this.props.isGM && (<button className="reset warning" onClick={()=> this.resetCharacterStatus()}>RESET</button>))}
        {(this.props.isGM && (<button className="delete warning" onClick={()=> this.props.deleteCharacter()}>DELETE</button>))}
        {(this.props.isGM && (<select value={this.state.owner} onChange={(e) => {this.updateElements({owner:e.target.value})}}>{playerSelectHTML}</select>))}
        {this.props.isGM && <button className="pc-toggle" onClick={()=> this.togglePC()}>{this.state.isPC?"PC":"NPC"}</button>}
        {this.state.isPC && <span>IP: {this.getRemainingIP()} / {this.state.ip.total}</span>}
        {!this.state.isPC && <span>IP: {this.getSpentIP()}</span>}

        {!this.props.myCharacterState.collapsed &&
        <Status status={this.state.status} updateStatus={this.updateStatus} editMode={this.state.editMode} isNPC={!this.state.isPC}
          dependents={this.state.dependents} currentGroupMember={this.currentGroupMember} setCurrentGroupMember={this.setCurrentGroupMember}
          pos={this.usesPositiveQi} neg={this.usesNegativeQi}/>}

        {!this.props.myCharacterState.collapsed && <div className="selectors">
          <div className="tab-selector compact" onClick={()=> this.selectTab("compact")}>Status Only</div>
          <div className={"tab-selector main" + (mySelectedTab === "main" ? " selected" : "")} onClick={()=> this.selectTab("main")}>Main</div>
          <div className={"tab-selector traits" + (mySelectedTab === "traits" ? " selected" : "")} onClick={()=> this.selectTab("traits")}>Traits</div>
          <div className={"tab-selector spells" + (mySelectedTab === "spells" ? " selected" : "")} onClick={()=> this.selectTab("spells")}>Spells</div>
          <div className={"tab-selector resources" + (mySelectedTab === "resources" ? " selected" : "")} onClick={()=> this.selectTab("resources")}>Resources</div>
          <div className={"tab-selector notes" + (mySelectedTab === "notes" ? " selected" : "")} onClick={()=> this.selectTab("notes")}>Notes</div>
          {!this.state.isPC && <div className={"tab-selector npc-tools" + (mySelectedTab === "npc-tools" ? " selected" : "")} onClick={()=> this.selectTab("npc-tools")}>NPC Tools</div>}
        </div>}

        {/* individual tab contents */}
        {mySelectedTab === "main" && <div className="main-tab container">
          <div className="aspect-col">
            <Aspects aspects={this.state.aspects} updateAspects={this.updateAspects} editMode={this.state.editMode}
              getAspect={this.getAspect} testAspect={this.testAspect} getRankLimit={this.getRankLimit()}
              channelAmountForAspect={this.channelAmountForAspect} />
            <Dependents dependents={this.state.dependents} isQiSensitive={this.isQiSensitive}
              isCaster={this.isCaster} />
          </div>
          <Skills skills={this.state.skills} updateSkills={this.updateSkills} editMode={this.state.editMode}
            getSkill={this.getSkill} testSkill={this.testSkill} aspects={this.state.aspects}
            getRankLimit={this.getRankLimit()}/>
          {this.state.editMode && <IP ip={this.state.ip} updateIP={this.updateIP} isGM={this.props.isGM} isPC={this.state.isPC}/>}
          {!this.state.editMode && <Actions rollInitiative={this.rollInitiative} endTurn={this.endTurn}
            meditate={this.meditate} usesPositiveQi={this.usesPositiveQi} usesNegativeQi={this.usesNegativeQi}
            zenArt={this.zenArt} zenPerformance={this.zenPerformance} isCurrentInitiative={this.props.data.isCurrentInitiative}
            channel={this.channel} channelAmount={this.channelAmount} rest={this.rest} roll={this.roll}
            rollLuck={this.rollLuck} takeDamage={this.takeDamage} />}
        </div>}

        {mySelectedTab === "traits" && <Traits traits={this.state.traits} addTrait={this.addTrait}
          removeTrait={this.removeTrait} editMode={this.state.editMode}
          getTraitList={this.getTraitList} getAspect={this.getAspect} 
          timeWalker={this.state.timeWalker}
          knowsAlignedSpells={this.knowsAlignedSpells} knowsSpells={this.knowsSpells} allegiance={this.state.allegiance}/>}

        {mySelectedTab === "spells" && <Spells spells={this.state.spells} isCaster={this.isCaster}
          addSpell={this.addSpell} removeSpell={this.removeSpell} getSpellList={this.getSpellList}
          isAligned={this.isAligned} editMode={this.state.editMode} castSpell={this.castSpell}
          updateRitual={this.updateRitual} castRitual={this.castRitual}
          drawRitualCircle={this.drawRitualCircle} />}

        {mySelectedTab === "resources" && <Resources resources={this.state.resources} 
          updateResources={this.updateResources} strike={this.strike} shoot={this.shoot} throw={this.throw} editMode={this.state.editMode}/>}

        {mySelectedTab === "notes" && <Notes allegiance={this.state.allegiance} notes={this.state.notes} editMode={this.state.editMode}
          isGM={this.props.isGM} setGiftType={this.setGiftType} isBound={this.isBound} updateAllegiance={this.updateAllegiance}
          timeWalker={this.state.timeWalker} updateTimeWalker={this.updateTimeWalker} isTimeWalkerInitiate={this.isTimeWalkerInitiate}
          updateSignatureSpell={this.updateSignatureSpell} signatureSpell={this.state.signatureSpell} knownSpells={this.state.spells}
          setGiftAspect={this.setGiftAspect} updateNotes={this.updateNotes} numGifts={this.numGifts} gifts={this.state.gifts}
          hasSignatureSpell={this.hasTrait("qi","signatureSpell")}/>}

        {mySelectedTab === "npc-tools" && <NPCTools NPCData={this.state.NPCData}
          updateNPCData={this.updateNPCData} postPublicDescription={this.postPublicDescription}/>}
      </div>
    );
  }
}

export function cap(myString) { // capitalize first word, add spaces between words
  return myString?.replace(/([A-Z])/g, ' $1').replace(/^./, function(str){ return str.toUpperCase(); });
}
import React from "react";

export default class Chat extends React.Component {
    constructor(props){
        super(props);
        this.state = {}
    }

    lockedAtBottom = true
    componentDidMount = this.scrollToBottom
    componentDidUpdate = () => {
        if (this.lockedAtBottom) {
            this.scrollToBottom()
        }
    }
    scrollToBottom = () => {
        let panel = document.querySelector(".chat-panel")
        if (panel) {
            panel.scrollTop = panel.scrollHeight
        }
    }

    lastScrollTop = 0
    handleScroll = (e) => {
        if (e.target.scrollTop > this.lastScrollTop) {
            // scrolling down
            if (e.target.scrollTop >= e.target.scrollHeight - e.target.getBoundingClientRect().height) {
                // at bottom
                this.lockedAtBottom = true
            }
        } else {
            // scrolling up
            this.lockedAtBottom = false
        }
        this.lastScrollTop = e.target.scrollTop
    }

    handleKeyDown = (e) => {
        if (e.key === "Enter" && !e.shiftKey) {
            // Send message when Enter is pressed
            e.preventDefault()
            if (e.target.value[0] === "/") {
                // Special command
                if (e.target.value.includes("/roll")) {
                    this.props.sendChat(roll(e.target.value))
                }
            } else {
                this.props.sendChat(e.target.value)
            }
            e.target.value = ""
        }
    }

    render () {
        let messages = []
        this.props.chatHistory.forEach(message=>{
            messages.push(<Message data={message} key={`${message.from}_${message.time}`} isGM={this.props.isGM} players={this.props.players} myId={this.props.myId} />)
        })
        return (
            <div className="chat-panel" onScroll={(e)=>this.handleScroll(e)}>
                {messages}
                <textarea rows="4" className="chat-compose" placeholder="type here..." onKeyDown={(e)=>this.handleKeyDown(e)} />
            </div>
        )
    }
}

function Message (props) {
    let content = props.data.content;
    if(typeof props.data.content === "object") {
        if(props.data.content.type === "test") {
            let testName = "";
            if(props.data.content.skill) {
                testName = props.data.content.skill + "'s " + props.data.content.aspect;
            } else {
                testName = "Pure " + props.data.content.aspect;
            }
            content = (<div className="test">
                <div className="name">{props.data.content.name}</div>
                <div className="action">{testName} Test</div>
                <div className="result tooltip-container">Final Result: {props.data.content.result}
                  <div className="tooltip top">
                    <div className="base">Base: {props.data.content.base}</div>
                    <div className="roll">Roll: {props.data.content.roll}</div>
                    <div className="tpp">TPP: {Number(props.data.content.tpp) === 0 ? "" : "-"}{props.data.content.tpp}</div>
                  </div>
                </div>
                {props.data.content.critical && <div className="critical">{props.data.content.critical}</div>}
            </div>);
        } else if(props.data.content.type === "cast") {
          content = (<div className="test">
            <div className="name">{props.data.content.name}</div>
            <div className="action">Casts {props.data.content.spell}</div>
            <div className="qi">Spending {props.data.content.qi} {props.data.content.qiType}</div>
            <div className="result tooltip-container">Test Result: {props.data.content.result}
              <div className="tooltip top">
                <div className="base">Base: {props.data.content.base}</div>
                <div className="roll">Roll: {props.data.content.roll}</div>
                <div className="tpp">TPP: {Number(props.data.content.tpp) === 0 ? "" : "-"}{props.data.content.tpp}</div>
              </div>
            </div>
            {props.data.content.critical && <div className="critical">{props.data.content.critical}</div>}
            <div className="difficulty">Total Difficulty: {props.data.content.difficulty}</div>
            <div className="net-result">Net Result: {props.data.content.netResult}</div>
            {props.data.content.note && <div className="spell-options">{props.data.content.note.map((myLine,lineNum)=>{return (<div key={lineNum}>{myLine}</div>)})}</div>}
            {props.data.content.range && <div className="range">Range: {props.data.content.range}</div>}
            {props.data.content.duration && <div className="duration">Duration: {props.data.content.duration}</div>}
            {props.data.content.damage && <div className="damage">Damage: {props.data.content.damage}</div>}
          </div>);
        } else if(props.data.content.type === "attack") {
            let attacksHTML = [];
            props.data.content.resultArray?.forEach((att,idx) => {
              attacksHTML.push(<div key={idx} className="result-line">{att}</div>);
            });
            content = (<div className="test">
                <div className="name">{props.data.content.name}</div>
                <div className="action">{props.data.content.action} Action</div>
                {props.data.content.attackType && <div className="type">{props.data.content.attackType}</div>}
                {props.data.content.hands && <div className="hand">{props.data.content.hands}</div>}
                {props.data.content.paired && <div className="hand-header weapon-block">Main-Hand</div>}
                {props.data.content.weapon && <div className="weapon weapon-block">{props.data.content.weapon}</div>}
                {props.data.content.firingMode && <div className="firingMode weapon-block">{props.data.content.firingMode}</div>}
                {props.data.content.ammo && <div className="ammo weapon-block">Ammo: {props.data.content.ammo}</div>}
                {props.data.content.paired && <div className="hand-header weapon-block divider">Off-Hand</div>}
                {props.data.content.offHandWeapon && <div className="weapon weapon-block">{props.data.content.offHandWeapon}</div>}
                {props.data.content.offHandFiringMode && <div className="firingMode weapon-block">{props.data.content.offHandFiringMode}</div>}
                {props.data.content.offHandAmmo && <div className="ammo weapon-block">Ammo: {props.data.content.offHandAmmo}</div>}
                <div className="result tooltip-container">
                  <div className="attacks">{attacksHTML}</div>
                  <div className="tooltip top">
                    <div className="base">Base: {props.data.content.base}</div>
                    <div className="roll">Roll: {props.data.content.roll}</div>
                    <div className="tpp">TPP: {Number(props.data.content.tpp) === 0 ? "" : "-"}{props.data.content.tpp}</div>
                  </div>
                </div>
                {props.data.content.critical && <div className="critical">{props.data.content.critical}</div>}
                {props.data.content.note && <div className="notes">{props.data.content.note.map((myLine,lineNum)=>{return (<div key={lineNum}>{myLine}</div>)})}</div>}
                {props.data.content.effect && <div className="effect">Effect: {props.data.content.effect}</div>}
            </div>);
        } else if(props.data.content.type === "messages") {
          content = (<div className="messages">
              {props.data.content.messages.map((myLine,lineNum)=>{return (<div key={lineNum}>{myLine}</div>)})}
            </div>);
        }
    }
    if(props.isGM || !props.data.secret || (props.data.from === props.myId && !props.data.gmOnly)) {
      return (
        <div className="chat-entry">
            <b>{props.players[props.data.from]?.name}: </b>
            <i>{new Date(props.data.time).toLocaleTimeString().replace(/(?<=\d+:\d+):\d+/, "")} </i>
            {props.data.secret && <i>WHISPER</i>}
            {content}
        </div>
    ) } else return null;
}

function roll (command) {
    try {
        let numDice = parseInt(/\d+(?=d)/.exec(command)[0])
        let diceSize = parseInt(/(?<=d)\d+/.exec(command)[0])
        let result = `[rolling ${numDice}d${diceSize}]:`
        for (let i = 0; i < numDice; i++) {
            result += " " + Math.ceil(Math.random() * diceSize)
            if (i + 1 < numDice) {
                result += ","
            }
        }
        return result
    } catch (e) {
        console.error(e)
        return "ERROR when rolling dice"
    }
}
import './Dashboard.css';
import React from 'react';
import { Helmet } from 'react-helmet';

export default class LogIn extends React.Component  {

  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      requestStatus: "",
    }
  }

  componentDidMount = () => {
    document.body.classList.add("dashboard")
    let darkMode = localStorage.getItem('darkMode');
    if(darkMode && darkMode !== 'false') {
      document.body.classList.add("darkmode");
    }
  }
  
  logIn = () => {
    this.setState({requestStatus: "Connecting to server"})
    this.requestPending = true
    this.connectToSocket()
  }

  requestPending = false

  connectToSocket = () => {
    if(!this.socket) {
      // fix using this answer: https://stackoverflow.com/questions/58432076/websockets-with-functional-components
      this.socket = new WebSocket('wss://warm-reef-77238.herokuapp.com/:35883');

      this.socket.addEventListener('open', (event) => {
        if (this.requestPending) {
          this.requestPending = false
          this.setState({requestStatus: "Sending request"})
          const { email, password } = this.state
          this.sendSocketMessage({type: "logIn", content: {email, password}})
        }
      });

      this.socket.addEventListener('message', (event) => {
        let message = JSON.parse(event.data);
        this.handleSocketMessage(message);
      });

      // this.socket.addEventListener('close', (event) => {
      //   this.socket = null;
      //   setTimeout(this.connectToSocket, 1000);
      // });
    }
  }

  handleSocketMessage = (message) => {
    if (message.message) {
      message = JSON.parse(message.message);
    }  
    if (message.type==="login") {
      if (message.content.status==="success") {
        this.setState({requestStatus: "Login successful! Redirecting to dashboard page."})
        localStorage.setItem("splinteredSymmetryToken", message.content.token);
        window.location.replace("/dashboard?test=1")
      } else { // message.content.status==="failure"
        this.setState({requestStatus: "Error. "+message.content.message})
      }
      this.closeSocket();
    }
  }

  sendSocketMessage = (message) => {
    //console.log("SENDING", message)
    this.socket?.send(JSON.stringify({
      gameName: 'splintered_symmetry',
      password: "0jf,Jg39*%gD9k",
      message: JSON.stringify(message)
    }));
  }

  closeSocket = () => {
    this.socket?.close();
    this.socket = null;
  }

  render() {
    const emailValid = this.state.email?.length >= 1
    const passwordValid = this.state.password?.length >= 1
    const valid = emailValid && passwordValid

    return(
      <div className="container">
        <Helmet>
          <title>Log In</title>
        </Helmet>
        <h1>Log In</h1>
        {/* <div className="settings">
          <h3>Settings</h3>
          <div className="darkmode">
            <label htmlFor='darkMode'>Dark Mode</label>
            <input type='checkbox' name='darkMode' checked={!!this.state.darkMode} onChange={(e)=>{
              this.setState({darkMode:e.target.checked})
              document.body.classList.toggle("darkmode",e.target.checked)
              localStorage.setItem('darkMode',e.target.checked);
            }} />
          </div>
        </div> */}

        <div className="account">
          <h3>Credentials</h3>
          <div className="email">
            Email Address: <input type="text" value={this.state.email} onChange={(e) => this.setState({email: e.target.value.toLowerCase()})} />
          </div>
          <div className="password">
            Password: <input type="password" value={this.state.password} onChange={(e) => this.setState({password: e.target.value})} />
          </div>
          <button className="" onClick={valid ? this.logIn : null} disabled={!valid}>Log In</button>
        </div>
        {this.state.requestStatus}
        <br/>
        <br/>
        Don't have an account? <a href="/signup?test=1">Sign up</a>
      </div>  
    );
  }
}
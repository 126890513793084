import {aspectOrder} from './Aspects';
import {cap} from './Character';

export function Notes(props) {

  let aspectChoicesHTML = [<option key="none" value="none">None</option>];
  aspectOrder.forEach((aspect) => {
    aspectChoicesHTML.push(
      <option key={aspect} value={aspect}>{cap(aspect)}</option>
    );
  });

  let giftsHTML = [];
  for(let i=0; i<Math.min(2,props.numGifts()); i++) {
    let label = "Gift (" + (props.gifts[i].greater ? "greater" : "lesser") + "): ";
    if(props.editMode) {
      giftsHTML.push(<div key={i} className="gift">
        {label}
        <select value={props.gifts[i].aspect} onChange={(e) => props.setGiftAspect(i,e.target.value)}>
          {aspectChoicesHTML}
        </select>
        {props.numGifts() === 3 && !props.gifts[i].greater && <button onClick={(e) => {
          props.setGiftType(i,true);
          props.setGiftType((i+1)%2,false)}}>
          Make Greater
          </button>}
      </div>);
    } else {
      giftsHTML.push(<div key={i} className="gift">
        {label} {cap(props.gifts[i].aspect)}
      </div>);
    }
  }

  let signatureSpellOptionsHTML = [<option key="none" value="none">None</option>];
  let spellTypes = ["basic","advanced","epic"];
  spellTypes.forEach((spellType) => {
    Object.keys(props.knownSpells[spellType]).forEach((spellName) => {
      signatureSpellOptionsHTML.push(
      <option key={spellName} value={spellName}>{cap(spellName)}</option>
      );
    });
  });

  let signatureSpellHTML = <div className="signatureSpell">
    <label htmlFor="signatureSpellSelect">Signature Spell:</label>
    <select value={props.signatureSpell} onChange={(e) => props.updateSignatureSpell(e.target.value)}>
      {signatureSpellOptionsHTML}
    </select>
  </div>;

  if(!props.editMode) signatureSpellHTML = <div className="signatureSpell">
    <label htmlFor="signatureSpellSelect">Signature Spell:</label>
    {cap(props.signatureSpell)}
  </div>;

  return(
    <div className="notes container">
      {props.isGM && <label htmlFor="allegianceSelect">Allegiance:</label>}
      {props.isGM && <select name="allegianceSelect" value={props.allegiance} 
        onChange={(e) => props.updateAllegiance(e.target.value)}
        disabled={props.isBound()}>
          <option value="none">None</option>
          <option value="creation">Creation</option>
          <option value="destruction">Destruction</option>
          <option value="mercy">Mercy</option>
          <option value="oppression">Oppression</option>
          <option value="nature">Nature</option>
          <option value="manipulation">Manipulation</option>
      </select>}
      {props.isGM && <div><input type="checkbox" checked={props.timeWalker} onChange={(e) => props.updateTimeWalker(e.target.checked)} 
        disabled={props.isTimeWalkerInitiate()}/>Time Walker</div>}
      {props.hasSignatureSpell && signatureSpellHTML}
      {giftsHTML}
      <textarea value={props.notes} onChange={(e) => props.updateNotes(e.target.value)} />
    </div> 
  );
}

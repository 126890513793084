export function Actions(props) {
  return(
    <div className="actions container">
      <div className="sub-header">---Actions---</div>
      <button onClick={() => props.rollInitiative()}>Roll Initiative</button>
      <button className={props.isCurrentInitiative ? "" : "hidden"} onClick={() => props.endTurn()}>End Turn</button>
      <button onClick={() => props.rest()}>Rest</button>
      <div className={"positive qi-recovery" + (props.usesPositiveQi() ? "" : " hidden")}>
        <button onClick={() => props.meditate("positive")}>Meditate (Pos)</button>
        <button onClick={() => props.zenArt("positive")}>Zen Art (Pos)</button>
        <button onClick={() => props.zenPerformance("positive")}>Zen Perf (Pos)</button>
        <button className={props.channelAmount() > 0 ? "" : "hidden"} onClick={() => props.channel("positive")}>Channel Pos Qi</button>
      </div>
      <div className={"negative qi-recovery" + (props.usesNegativeQi() ? "" : " hidden")}>
        <button onClick={() => props.meditate("negative")}>Meditate (Neg)</button>
        <button onClick={() => props.zenArt("negative")}>Zen Art (Neg)</button>
        <button onClick={() => props.zenPerformance("negative")}>Zen Perf (Neg)</button>
        <button className={props.channelAmount() > 0 ? "" : "hidden"} onClick={() => props.channel("negative")}>Channel Neg Qi</button>
      </div>
      <button onClick={() => props.roll(true)}>Standard Roll</button>
      <button onClick={() => props.roll(true,"advantage")}>Roll wih Adv</button>
      <button onClick={() => props.roll(true,"disadvantage")}>Roll with Disadv</button>
      <button onClick={() => props.rollLuck(true)}>Luck Roll</button>
      <button onClick={() => props.takeDamage()}>Take Damage</button>
    </div>
  );
}
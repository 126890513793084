import React from 'react';
import logoWide from './images/SS Wide.svg';

export default class Footer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render () {
    return (
      <footer>
        <div className="footer-divider"></div>
        <div className="footer-content">
          <div className="footer-logo">
            <a href="./">
              <img src={logoWide} alt="Splintered Symmetry Logo" />
            </a>
          </div>
          <p className="footer-links">
            <a href="./">Home</a>
            <a href="./events">Events</a>
            <a href="./gameplay">Gameplay</a>
            <a href="./world">World</a>
            <a href="./privacy-policy.pdf">Privacy Policy</a>
            <a href="./terms-of-service.pdf">Terms of Service</a>
          </p>
        </div>
        <div className="footer-copyright">
          <p>Copyright &copy; <span id="date">{new Date().getFullYear()}</span> Elder Chicken</p>
        </div>
      </footer>
    )
  }
}
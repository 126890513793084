import React from 'react';
import Game from './Game';
import Home from './static-pages/Home';
import Events from './static-pages/Events';
import World from './static-pages/World';
import Gameplay from './static-pages/Gameplay';
import Dashboard from './Dashboard';
import SignUp from './SignUp';
import LogIn from './LogIn';

const isValidToken = (token) => {
  const userIdLength = 10
  const tokenTime = parseInt(token?.slice?.(userIdLength) || "0")
  const isValid = 
    typeof token === "string" &&
    token.length > userIdLength &&
    tokenTime > Date.now();
  if (!isValid) {
    localStorage.removeItem("splinteredSymmetryToken");
  }
  return isValid
}

export default class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  urlParams = urlParamsAsObj();
  urlPath = window.location.pathname;

  render () {
    // https://splinteredsymmetry.com/play?test=1
    const { urlParams, urlPath } =  this;
    const loggedIn = isValidToken(localStorage.getItem("splinteredSymmetryToken"))
    // const room = urlPath.match(/^\/room\/([0-9]+)/i);
    
    if(urlPath.toLowerCase() === "/") {
      return (<Home />)
    } else if(urlPath.toLowerCase() === "/events") {
      return (<Events />)
    } else if(urlPath.toLowerCase() === "/gameplay") {
      return (<Gameplay />)
    } else if(urlPath.toLowerCase() === "/world") {
      return (<World />)
    } else if(urlPath.toLowerCase() === "/signup" && urlParams.test && !loggedIn) {
      return (<SignUp />)
    } else if(urlPath.toLowerCase() === "/login" && urlParams.test && !loggedIn) {
      return (<LogIn />)
    } else if(urlParams.test && !loggedIn) {
      return (<LogIn />)
    } else if(urlPath.toLowerCase() === "/play" && urlParams.test && loggedIn) {
      return (<Game />)
    } else if(urlPath.toLowerCase() === "/dashboard" && urlParams.test && loggedIn) {
      return (<Dashboard />)
    } else {
      return (<Home />)
    }
  }
}
  
function urlParamsAsObj() {
  const urlParamsString = window.location.search.substr(1);
  const keyValueStrings = urlParamsString.split("&");
  const urlParams = keyValueStrings.reduce((acc, cur)=>{
      const [ key, value ] = cur.split('=');
      acc[key] = value;
      return acc;
  }, {})
  return urlParams;
}
import {copyObject} from './Game';

export function NPCTools(props) {

  function updateDisguiseName(newName) {
    let newNPCData = copyObject(props.NPCData);
    newNPCData.disguiseName = newName;
    props.updateNPCData(newNPCData);
  }

  function toggleDisguise() {
    let newNPCData = copyObject(props.NPCData);
    newNPCData.isDisguised = !props.NPCData.isDisguised;
    props.updateNPCData(newNPCData);
  }

  function toggleHide() {
    let newNPCData = copyObject(props.NPCData);
    newNPCData.hide = !props.NPCData.hide;
    props.updateNPCData(newNPCData);
  }

  function updateGroupSize(newSize) {
    newSize = Math.min(8,Math.max(1,newSize));
    let sizeChange = newSize - props.NPCData.groupSize;
    let newNPCData = copyObject(props.NPCData);
    newNPCData.groupSize = newSize;
    props.updateNPCData(newNPCData,sizeChange);
  }

/*  function toggleActTogether() {
    let newNPCData = copyObject(props.NPCData);
    newNPCData.actTogether = !props.NPCData.actTogether;
    props.updateNPCData(newNPCData);
  }
*/
  function updatePublicDescription(newDescription) {
    let newNPCData = copyObject(props.NPCData);
    newNPCData.publicDescription = newDescription;
    props.updateNPCData(newNPCData);
  }

  return <div className="npc-tools container">
    <div>
      <label htmlFor="disguise">Disguise</label>
      <input type="checkbox" name="disguise" checked={props.NPCData.isDisguised} onChange={() => toggleDisguise()}/>
    </div>
    <div>
      <label htmlFor="disguiseName">Disguise Name</label>
      <input type="text" name="disguiseName" value={props.NPCData.disguiseName} onChange={(e) => updateDisguiseName(e.target.value)} />
    </div>
    <div>
      <label htmlFor="hideNPC">Hide from Players</label>
      <input type="checkbox" name="hideNPC" checked={props.NPCData.hide} onChange={() => toggleHide()}/>
    </div>
    <div>
      <label htmlFor="groupSize">Group Size:</label>
      <input type="number" name="groupSize" value={props.NPCData.groupSize} onChange={(e) => updateGroupSize(e.target.value)}/>
      {/* <label htmlFor="actTogether">Act Together</label>
      <input type="checkbox" name="actTogether" checked={props.NPCData.actTogether} onChange={() => toggleActTogether()}/> */}
    </div>
    <div>
      <label htmlFor="publicDescription">Public Description</label>
      <button className="post description" onClick={() => props.postPublicDescription()}>Post</button>
      <textarea value={props.NPCData.publicDescription} onChange={(e) => updatePublicDescription(e.target.value)} />
    </div>
  </div>
}
import {cap} from './Character';
import {aspectOrder} from './Aspects';

export function Skills(props) {

    let mySkillsHTML = [];
    skillOrder.forEach(skill => {
        let myTestsHTML = [];
        if(!props.editMode && props.getSkill(skill) > 0) {
            aspectOrder.forEach(aspect => {
                myTestsHTML.push(<button key={aspect} onClick={() => props.testSkill(skill,aspect)}
                  className={aspect + " aspect"}></button>);
            });
        }
        mySkillsHTML.push(<div key={skill} className="skill-container">
            <label className="skill">{cap(skill)}</label>
            <div className="skill-total">{props.getSkill(skill)}</div> = 
            <input type="number" disabled={!props.editMode} value={props.skills[skill].base}
                onChange={(e) => updateBaseSkill(e,skill)}/> + 
            <input type="number" value={props.skills[skill].bonus}
                onChange={(e) => updateBonusSkill(e,skill)}/>
            {myTestsHTML}
            </div>);
    });

    function updateBaseSkill(e,skill) {
        let newState = props.skills;
        newState[skill].base = Math.max(0,Math.min(props.getRankLimit,Number(e.target.value)));
        props.updateSkills(newState);
    }

    function updateBonusSkill(e,skill) {
        let newState = props.skills;
        newState[skill].bonus = Math.max(0,Math.min(props.getRankLimit,Number(e.target.value)));
        props.updateSkills(newState);
    }

    return(
        <div className="skills container">
            <div className="sub-header">---Skills---</div>
            <label className="skill"></label>
            <label className="current">Current</label> = 
            <label className="base">Base</label> + 
            <label className="bonus">Bonus</label>
            {mySkillsHTML}
        </div>
    );
}

const skillOrder = ["artist","athlete","burglar","diplomat","engineer","fighter","investigator","mage",
  "medic","performer","pilot","programmer","scholar","sharpshooter","survivalist","trickster"];

import {cap} from './Character';

export function Dependents(props) {

    let displayItems = ["staminaRecoveryTime","baseInitiative","damageResistance","woundCapacity","liftingLimit"];
    if(props.isQiSensitive()) displayItems.push(...["activeQiRange","passiveQiRange"]);
    if(props.isCaster("basic")) displayItems.push(...["spellResistanceThreshold","illusionDetectionThreshold"]);

    let myHTML = [];
    displayItems.forEach(d => {
        myHTML.push(<div key={d}>
            {cap(d)}: {props.dependents[d]}
        </div>);
    });

    return(
        <div className="dependents containter">
            <div className="sub-header">---Dependent Attributes---</div>
            {myHTML}
        </div>
    );

}


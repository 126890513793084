import './Dashboard.css';
import React from 'react';
import { Helmet } from 'react-helmet';

function generateRandomId () {
  const characters = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ!@#$%^&*()_-+=[]{}|;:,.<>?';
  const idLength = 10;
  let randomId = '';

  for (let i = 0; i < idLength; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    randomId += characters[randomIndex];
  }

  return randomId;
}
export default class SignUp extends React.Component  {

  constructor(props) {
    super(props);
    this.state = {
      id: localStorage.getItem("myRandomId") || generateRandomId(), // hidden field
      name: localStorage.getItem("myName") || "",
      email: "",
      password: "",
      confirmPassword: "", // not sent to backend
      requestStatus: "",
    }
  }

  componentDidMount = () => {
    document.body.classList.add("dashboard")
    let darkMode = localStorage.getItem('darkMode');
    if(darkMode && darkMode !== 'false') {
      document.body.classList.add("darkmode");
    }
  }
  
  createAccount = () => {
    this.setState({requestStatus: "Connecting to server"})
    this.requestPending = true
    this.connectToSocket()
  }

  requestPending = false

  connectToSocket = () => {
    if(!this.socket) {
      // fix using this answer: https://stackoverflow.com/questions/58432076/websockets-with-functional-components
      this.socket = new WebSocket('wss://warm-reef-77238.herokuapp.com/:35883');

      this.socket.addEventListener('open', (event) => {
        if (this.requestPending) {
          this.requestPending = false
          this.setState({requestStatus: "Sending request"})
          const { id, name, email, password } = this.state
          this.sendSocketMessage({type: "createUser", content: {id, name, email, password}})
        }
      });

      this.socket.addEventListener('message', (event) => {
        let message = JSON.parse(event.data);
        this.handleSocketMessage(message);
      });

      // this.socket.addEventListener('close', (event) => {
      //   this.socket = null;
      //   setTimeout(this.connectToSocket, 1000);
      // });
    }
  }

  handleSocketMessage = (message) => {
    if (message.message) {
      message = JSON.parse(message.message);
    }  
    if (message.type==="result") {
      if (message.content.status==="success") {
        this.setState({requestStatus: "Account created! Redirecting to login page."})
        window.location.replace("/login?test=1")
      } else { // message.content.status==="failure"
        this.setState({requestStatus: "Error. "+message.content.message})
      }
      this.closeSocket();
    }
  }

  sendSocketMessage = (message) => {
    //console.log("SENDING", message)
    this.socket?.send(JSON.stringify({
      gameName: 'splintered_symmetry',
      password: "0jf,Jg39*%gD9k",
      message: JSON.stringify(message)
    }));
  }

  closeSocket = () => {
    this.socket?.close();
    this.socket = null;
  }

  render() {
    const nameValid = this.state.name?.length >= 1
    const emailValid = this.state.email?.length >= 1 && /^[^@]+@[^@]+\.[^@]+$/.test(this.state.email)
    const passwordValid = this.state.password?.length >= 1
    const confirmPasswordValid = this.state.confirmPassword===this.state.password
    const valid = nameValid && emailValid && passwordValid && confirmPasswordValid

    return(
      <div className="container">
        <Helmet>
          <title>Sign Up</title>
        </Helmet>
        <h1>Sign Up</h1>
        {/* <div className="settings">
          <h3>Settings</h3>
          <div className="darkmode">
            <label htmlFor='darkMode'>Dark Mode</label>
            <input type='checkbox' name='darkMode' checked={!!this.state.darkMode} onChange={(e)=>{
              this.setState({darkMode:e.target.checked})
              document.body.classList.toggle("darkmode",e.target.checked)
              localStorage.setItem('darkMode',e.target.checked);
            }} />
          </div>
        </div> */}

        <div className="account">
          <h3>Account</h3>
          <div className={"displayName" + (nameValid ? "" : " invalid")}>
            Display Name: <input type="text" value={this.state.name} onChange={(e) => this.setState({name: e.target.value})} />
            {!nameValid && (
              <>
                <br/>
                Invalid. Name must meet these requirements: at least one character
              </>
            )}
          </div>
          <div className={"email" + (emailValid ? "" : " invalid")}>
            Email Address: <input type="text" value={this.state.email} onChange={(e) => this.setState({email: e.target.value})} />
            {!emailValid && (
              <>
                <br/>
                Invalid. Email must meet these requirements: valid email
              </>
            )}
          </div>
          <div className={"password" + (passwordValid ? "" : " invalid")}>
            Password: <input type="password" value={this.state.password} onChange={(e) => this.setState({password: e.target.value})} />
            {!passwordValid && (
              <>
                <br/>
                Invalid. Password must meet these requirements: at least one character
              </>
            )}
          </div>
          <div className={"password" + (confirmPasswordValid ? "" : " invalid")}>
            Confirm Password: <input type="password" value={this.state.confirmPassword} onChange={(e) => this.setState({confirmPassword: e.target.value})} />
            {!confirmPasswordValid && (
              <>
                <br/>
                Invalid. Confirm Password must meet these requirements: matches password
              </>
            )}
          </div>
          <button className="" onClick={valid ? this.createAccount : null} disabled={!valid}>Create Account</button>
        </div>
        {this.state.requestStatus}
        <br/>
        <br/>
        Already have an account? <a href="/login?test=1">Log in</a>
      </div>  
    );
  }
}
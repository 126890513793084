import React from 'react';

export default class Dialogue extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
        response: {}
    }
  }

  submit = () => {
    let defaults = {}
    for (const questionName in this.props.questions) {
        defaults[questionName] = this.props.questions[questionName].default
    }
    this.props.resultFunction({...defaults, ...this.state.response})
    this.setState({response: {}})
  }

  cancel = () => {
    this.props.cancelDialogue()
    this.setState({response: {}})
  }

  updateResponse = (key, value) => {
    let response = this.state.response
    response[key] = value
    this.setState({response})
  }

  render () {
    if (this.props.questions) {
        let questionsHTML = []
        for (const key in this.props.questions) {
            let question = this.props.questions[key]
            if (!question.conditions || satisfiesConditions(question.conditions, this.state.response, this.props.questions)) {
                questionsHTML.push(
                    <div className='row' key={key}>
                        <label htmlFor={key}>{question.name}</label>
                        {question.inputType === "text" ? (
                            <input type="text" name={key} defaultValue={question.default} onChange={(e)=>this.updateResponse(key, e.target.value)} />
                        ) : question.inputType === "dropdown" ? (
                            <select name={key} defaultValue={question.default} onChange={(e)=>this.updateResponse(key, e.target.value)}>
                                {question.options.map(option=><option key={option.value} value={option.value}>{option.name}</option>)}
                            </select>
                        ) : question.inputType === "checkbox" ? (
                            <input type="checkbox" name={key} defaultChecked={question.default} onChange={(e)=>this.updateResponse(key, e.target.checked)} />
                        ) : question.inputType === "number" ? (
                            <input type="number" name={key} defaultValue={question.default} onChange={(e)=>this.updateResponse(key, Number(e.target.value))} min={question.min} max={question.max} />
                        ) : question.inputType === "wideNumber" ? (
                            <input type="number" name={key} className="wide" defaultValue={question.default} onChange={(e)=>this.updateResponse(key, Number(e.target.value))} min={question.min} max={question.max} />
                        ) : null}
                    </div>
                )
            }
        }
        let textHTML = []
        if(this.props.text) {
          this.props.text.forEach((txt,idx) => {
            textHTML.push(<div key={idx} className="text">{txt}</div>);
          });
        }
        return (
            <div className='dialogue-container column'>
                <h4>{this.props.title}</h4>
                {textHTML}
                {questionsHTML}
                <button onClick={this.submit}>OK</button>
                <button className='close-button' onClick={this.cancel}>✕</button>
            </div>
        );
    } else {
        return null;
    }
  }
}

function satisfiesConditions (conditions, response, questions) {
    let defaults = {}
    for (const questionName in questions) {
        defaults[questionName] = questions[questionName].default
    }
    let allResponses = {...defaults, ...response}
    for (const key in conditions) {
        // Make sure they are all strings
        let condition = typeof conditions[key] === "number" ? Number(conditions[key]).toString() : conditions[key]
        let response = typeof allResponses[key] === "number" ? Number(allResponses[key]).toString() : allResponses[key]
        if (condition !== response) {
            return false
        }
    }
    return true
}
import React from 'react';
import Footer from './Footer';
import Nav from './Nav';
import './main.css';
import scorpion from './images/PW0003_Scorpion.png';
import meditation from './images/0008_Meditation.png';
import knight from './images/0020_Knight-cropped.png';
import { Helmet } from 'react-helmet';


export default class World extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render () {
    return (
        <>
            <Nav/>
            <Helmet>
                <title>World | Splintered Symmetry</title>
                <link rel="shortcut icon" href="./images/SS Icon.png" type="image/x-icon" />
                <meta name="description" content="Splintered Symmetry offers both a detailed and compelling setting in the main campaign and the freedom to create your own settings in a variety
                of genres. A key concept in the game is qi (pronounced 'CHEE'), which comes in both positive and negative varieties." />
            </Helmet>
            <main>
              <div className="rotating-banner">
                <div className="banner-panel">
                  <div className="banner-image" style={{backgroundImage: `url(${scorpion})`}}></div>
                  <div className="banner-text">World</div>
                </div>
              </div>
              <div className="img-text-wrap">
                <p>Splintered Symmetry offers both a detailed and compelling setting in the main campaign and the freedom to create your own settings in a variety
                of genres. A key concept in the game is qi (pronounced "CHEE"), which comes in both positive and negative varieties. Positive qi is the power of
                life and light and creation and is naturally generated by healthy and flourishing living things. Negative qi is the power of death and darkness
                and is naturally generated by suffering and the decay of mortal life. In a cataclysmic event, half the Embodiments of Creation became divided
                and reality shattered into the splinters that make up the Reality Tree.</p>
              
                <img className="paragraph-img half-width" src={meditation} alt="a monk meditates in a garden" />
              </div>
              <div className="img-text-wrap reverse-on-big">
                <p className="half-width">Each Splinter of reality has its own separate timeline, and each progresses along a unique path. This means there are an incredible variety of
                  settings in which the game can take place, from medieval fantasy to alternative histories, from modern to cyberpunk to space opera. The main
                  campaign takes place in an alternate version of our world in 2019, where the Shaolin monks introduced the world to magic twenty-two years ago. All
                  was well until January 2010, when monsters started emerging during solar eclipses. The PCs are members of the elite MSWAT alpha team responding to magical threats.</p>
                <img className="paragraph-img half-width" src={knight} alt="a medieval knight" />
              </div>
            </main>
            <Footer />
        </>
    )
  }
}
import {cap} from './Character';

export function Status(props) {

    function updateElement(e,element,groupMember) {
        let newStatus = props.status;
        newStatus[groupMember][element] = e.target.value;
        props.updateStatus(newStatus);
    }

    function addWound(groupMember) {
        let newStatus = props.status;
        newStatus[groupMember].wounds.push({location:"torso",rank:0,bleed:0});
        props.updateStatus(newStatus);
    }

    function deleteWound(index,groupMember) {
        let newStatus = props.status;
        newStatus[groupMember].wounds.splice(index,1);
        props.updateStatus(newStatus);
    }

    function updateWound(e,index,element,groupMember) {
        let newStatus = props.status;
        newStatus[groupMember].wounds[index][element] = e.target.value;
        props.updateStatus(newStatus);
    }

    let statusHTML = [];
    let isGroup = props.status.length > 1;
    props.status.forEach((member,memberIndex) => {
        if(props.isNPC || memberIndex === 0) {
            let woundsHTML = [];
            member.wounds.forEach((wound,i) => {
                woundsHTML.push(<div key={i} className={"wound"}>
                    <select value={member.wounds[i].location} onChange={(e) => updateWound(e,i,"location",memberIndex)}>
                        <option value="head">Head</option>
                        <option value="torso">Torso</option>
                        <option value="leftArm">Left Arm</option>
                        <option value="rightArm">Right Arm</option>
                        <option value="leftLeg">Left Leg</option>
                        <option value="rightLeg">Right Leg</option>
                    </select>
                    <input type="number" value={member.wounds[i].rank} onChange={(e) => updateWound(e,i,"rank",memberIndex)}/>
                    <input type="number" value={member.wounds[i].bleed} onChange={(e) => updateWound(e,i,"bleed",memberIndex)}/>
                    <button onClick={() => deleteWound(i,memberIndex)}>-</button>
                </div>);
            });
            statusHTML.push(
              <div key={memberIndex} className={"wide status container " + member.overallStatus}>
                  <div className="status-col-1">
                      <div><label>Status: </label>
                          <span>{cap(member.overallStatus)}</span>
                      </div>
                      <div><label>Total Pain Penalty: </label>
                          <span>{member.totalPainPenalty} / {props.dependents.painCapacity}</span>
                      </div>
                      <div><label>Physical Defense: </label>
                          <span>{member.physicalDefense} / {props.dependents.basePhysicalDefense}</span>
                      </div>
                      <div><label>Movement: </label>
                          <span>{member.movement} / {props.dependents.movementRange}</span>
                      </div>
                  </div>
                  <div className="status-col-2">
                      <div>
                          <label>Stamina:</label>
                          <input type="number" value={member.stamina} 
                              onChange={(e) => updateElement(e,"stamina",memberIndex)}/> / {props.dependents.staminaCapacity}
                      </div>
                      <div>
                          <label>Foresight:</label>
                          <input type="number" value={member.foresight} 
                              onChange={(e) => updateElement(e,"foresight",memberIndex)}/> / 3
                      </div>
                      <div className={props.pos() ? "" : "faded"}>
                          <label>Positive Qi:</label>
                          <input type="number" value={member.positiveQi}
                              onChange={(e) => updateElement(e,"positiveQi",memberIndex)}/> / {props.dependents.positiveQiCapacity}
                      </div>
                      <div className={props.neg() ? "" : "faded"}>
                          <label>Negative Qi:</label>
                          <input type="number" value={member.negativeQi}
                              onChange={(e) => updateElement(e,"negativeQi",memberIndex)}/> / {props.dependents.negativeQiCapacity}
                      </div>
                  </div>
                  <div className="status-col-3">
                      <div>
                        <label>Bleed Rate: </label>
                        <span>{member.bleedRate}</span>
                      </div>
                      <div>
                          <label>Blood Loss:</label>
                          <input type="number" value={member.bloodLoss}
                              onChange={(e) => updateElement(e,"bloodLoss",memberIndex)}/> / {props.dependents.bloodCapacity}
                      </div>
                      <div>
                          <label>TAP:</label>
                          <input type="number" value={member.tap}
                              onChange={(e) => updateElement(e,"tap",memberIndex)}/>
                      </div>
                      <div>
                          <label>Exhaustion:</label>
                          <input type="number" value={member.exhaustion}
                              onChange={(e) => updateElement(e,"exhaustion",memberIndex)}/> / {props.dependents.woundCapacity - 1}
                      </div>
                  </div>
                  <div className="status-col-4">
                      <div>
                          <label className="location">Wound</label>
                          <label className="rank">Rank</label>
                          <label className="bleed">Bleed</label>
                          <button onClick={() => addWound(memberIndex)}>+</button>
                      </div>
                      {woundsHTML}
                  </div>
                  {props.isNPC && isGroup &&
                      <div className="status-col-5">
                          {memberIndex === props.currentGroupMember && 
                            <div className="active indicator">***Active***</div>}
                          {memberIndex !== props.currentGroupMember && member.overallStatus !== "dead" && 
                            <button className="make-active" onClick={() => props.setCurrentGroupMember(memberIndex)}>Make Active</button>}
                      </div>}
              </div>
            );
        }
    });

    return(statusHTML);
}
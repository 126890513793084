import React from 'react';
import logoWide from './images/SS Wide.svg';

export default class Nav extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  
  componentDidMount = () => {
    document.body.classList.remove("game")
  }


  render () {
    return (
        <>
            <div className="main-logo">
                <a href="/">
                    <img src={logoWide} alt="Splintered Symmetry Logo" />
                </a>
            </div>
            <div className="navigation">
                <a href="./">Home</a>
                <div id="nav-dropdown">
                <div className="just-a-spacer"></div>
                <a href="./events">Events</a>
                <a href="./gameplay">Gameplay</a>
                <a href="./world">World</a>
                </div>
            </div>
        </>
    )
  }
}
import React from "react";

export function Initiative (props) {
    if (!props.characters) return
    let characters = []
    let sortedCharacters = props.characters.filter(c=>isFinite(c.currentInitiative)).sort((a,b) => b.currentInitiative - a.currentInitiative)
    sortedCharacters.forEach((character,index)=>{
      let first = index === 0;
      let last = index === sortedCharacters.length - 1;
        characters.push(<InitiativeRow 
            data={character} 
            isGM={props.isGM}
            setCurrentInitiative={props.setCurrentInitiative} 
            removeFromInitiative={props.removeFromInitiative} 
            key={character.id}
            isOwner={character.owner === props.myID}
            changeInitiative={props.changeInitiative}
            bumpInitiative={props.bumpInitiative}
            first={first}
            last={last}
        />)
    })
    return (
        <div className="initiative-panel">
            {characters}
        </div>
    )
}

function InitiativeRow (props) {
    return (
        <div className={"initiative-row"+
                        (props.isGM ? " cursor-pointer" : "")+
                        (props.data.isCurrentInitiative ? " current" : "")+
                        (!props.isGM && !props.data.isPC && props.data.NPCData.hide && !props.isOwner ? " hidden" : "")}>                         
            {props.data.isCurrentInitiative && <div className="pointer">{'>'}</div>}
            {props.isGM && <div className='initiative remove-button'>
              <button onClick={(e)=>{e.stopPropagation();props.removeFromInitiative(props.data.name);}}>-</button>
            </div>}
            {props.isGM && <div className='initiative change-button'>
              <button onClick={()=>props.changeInitiative(props.data.id)}>?</button>
              {!props.last && <button onClick={()=>props.bumpInitiative(props.data.id,false)}>v</button>}
              {props.last && <div className="button-spacer"></div>}
              {!props.first && <button onClick={()=>props.bumpInitiative(props.data.id,true)}>^</button>}
              {props.first && <div className="button-spacer"></div>}
            </div>}
            <div className="initiative-subrow" onClick={props.isGM ? ()=>props.setCurrentInitiative(props.data.id) : null}>
              <div className='initiative value'>{Math.floor(props.data.currentInitiative)}</div>
              <div className='name'>{(!props.data.isPC && props.data.NPCData.isDisguised) ? props.data.NPCData.disguiseName : props.data.name}</div>
            </div>
        </div>
    )
}

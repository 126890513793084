import React from 'react';
import Footer from './Footer';
import Nav from './Nav';
import './main.css';
import soldierKneeling from './images/PW0006_Soldier_Kneeling.png';
import fistsOfQi from './images/0073_Fists_of_Qi.png';
import dice from './images/3d8.jpg';
import karate from './images/0061_Karate.png';
import { Helmet } from 'react-helmet';


export default class Gameplay extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render () {
    return (
        <>
            <Nav/>
            <Helmet>
                <title>Gameplay | Splintered Symmetry</title>
                <link rel="shortcut icon" href="./images/SS Icon.png" type="image/x-icon" />
                <meta name="description" content="Splintered Symmetry's game mechanics are built around a simple yet powerful system. Characters are described by 8 core Aspects which exist on
                three axes of symmetry: half are physical and the other half are mental, half are aspects of strength and the other half are aspects of finesse,
                half are active and the other half are reactive (which includes the concept of resilience)." />
            </Helmet>
            <main>
              <div className="rotating-banner">
                <div className="banner-panel">
                  <div className="banner-image" style={{backgroundImage: `url(${soldierKneeling})`, backgroundPosition: "center 30%"}}></div>
                  <div className="banner-text">Gameplay</div>
                </div>
              </div>
              

              <p>Splintered Symmetry's game mechanics are built around a simple yet powerful system. Characters are described by 8 core Aspects which exist on
                three axes of symmetry: half are physical and the other half are mental, half are aspects of strength and the other half are aspects of finesse,
                half are active and the other half are reactive (which includes the concept of resilience). So, for example, a character's Brawn is their active
                physical strength, while a character's Awareness is their reactive mental finesse. The 8 aspects are naturally and intuitively balanced. Combined
                with an open, classless system this creates meaningful choices in character design. For example, Brawn doesn't just impact your carrying capacity
                and melee fighting. It also affects your stamina and movement speed, making it harder to ignore.</p>

                <div className="img-text-wrap reverse-on-big">
                  <img className="paragraph-img half-width" src={fistsOfQi} alt="thugs attack a man with glowing fists" />
        
                <p className="half-width">There are 15 skills, which come in three groups of five: physical, intellectual, and social. These represent broad roles that a character trains
                  in, such as Fighter, Medic, or Diplomat. Most tests that a character makes pair an aspect with a skill, and the pairings are flexible, giving GMs
                  considerable freedom in the kinds of tests that they call for. Where most systems use the same perception or notice test for everything, Splintered
                  Symmetry adds a welcome nuance to each situation: you might use a Burglar's Awareness test to catch someone trying to sneak past you, an Engineer's
                  Awareness test to find a construction flaw, or a Mage's Awareness test to notice a faint magical residue.</p>
                </div>

              <div className="img-text-wrap">
                <img className="paragraph-img half-width" src={dice} alt="three eight-sided dice" />

                <p className="half-width">The dice system is quick and balanced. Every roll in the game uses three 8 sided dice and picks out just the middle number. This gives a result
                  from 1 to 8, with high and low values being more rare, but without requiring extra addition. The range of dice results are balanced with the
                  aspect and skill values (which also range from 1 to 8), making for a less luck-heavy system that feels more natural than some other games. No,
                  an inept novice martial artist doesn't land a punch on a third-degree black belt 5% of the time. The amount of luck is carefully calibrated to
                  make the story feel more realistic while still keeping the tension high.</p>
              </div>

              <div className="img-text-wrap">
                <p>While the core system is simple, there is a rich layer of detail that provides great variety and tactical depth. Specialties and traits let
                you fine-tune your character. Qi abilities let you tap into your inner strength to perform amazing feats, and the rich magic system allows for a
                variety of spellcaster styles. Combat is brutally realistic and gives players interesting options with immediate consequences. But with all of
                that, the golden rule means that GMs are never forced to put the game on hold to look up an obscure game mechanic. You can always default back
                to the simple but powerful system of basic tests.</p>

                <img className="paragraph-img half-width" src={karate} alt="a woman performs a karate kick" />
              </div>

            </main>
            <Footer />
        </>
    )
  }
}
import './Dashboard.css';
import React from 'react';
import Dialogue from './Dialogue';
import { Helmet } from 'react-helmet';

export default class Dashboard extends React.Component  {

  constructor(props) {
    super(props);
    this.state = {
      myPlayerData: {
        id: localStorage.getItem("splinteredSymmetryToken").slice(0,10),
        name: "",
        email: "",
      },
      updatedPlayerData: {
        name: "",
        email: "",
        password: "",
        confirmPassword: "", // not sent to the backend
      },
      addFriendEmail: "",
      createGameId: "",
      darkMode: true,
      friends: {},
      games: {},
      requestStatus: "",
      queuedDialogues: [],
    }
  }

  componentDidMount = () => {
    document.body.classList.add("dashboard")
    let darkMode = localStorage.getItem('darkMode');
    if(darkMode && darkMode !== 'false') {
      document.body.classList.add("darkmode");
    }

    this.getDashboardInfo()
  }

  dialogueResultFunction
  componentDidUpdate = () => {
    //console.log("this.dialogueResultFunction", this.dialogueResultFunction)
    if (this.dialogueResultFunction) {
      this.dialogueResultFunction()
      this.dialogueResultFunction = undefined
    }
  }

  queueSocketMessage = (socketMessage, responseType = undefined) => {
    this.queuedSocketMessages.push(socketMessage)
    responseType && this.awaitingSocketResponses.push(responseType)
    this.connectToSocket()
  }

  queuedSocketMessages = []
  awaitingSocketResponses = []
  
  getDashboardInfo = () => {
    this.queueSocketMessage({type: "getOwnDashboardInfo", content: this.state.myPlayerData.id}, "getOwnDashboardInfoResponse");
  }
  
  updatePlayerInfo = () => {
    let updatedInfo = {}
    const {name, email, password} = this.state.updatedPlayerData
    const {id} = this.state.myPlayerData

    if (name.length>=1) {
      updatedInfo.name = name
    }
    if (email.length>=1) {
      updatedInfo.email = email
    }
    if (password.length>=1) {
      updatedInfo.password = password
    }

    this.queueSocketMessage({type: "updateOwnUser", content: {
      id,
      ...updatedInfo
    }});
  }

  connectToSocket = () => {
    if(!this.socket) {
      // fix using this answer: https://stackoverflow.com/questions/58432076/websockets-with-functional-components
      this.socket = new WebSocket('wss://warm-reef-77238.herokuapp.com/:35883');

      this.socket.addEventListener('open', (event) => {
        if (this.queuedSocketMessages.length) {
          this.setState({requestStatus: "Sending request"})
          while (this.queuedSocketMessages.length) {
            this.sendSocketMessage(this.queuedSocketMessages[0])
            this.queuedSocketMessages.splice(0, 1)
          }
        }
      });

      this.socket.addEventListener('message', (event) => {
        let message = JSON.parse(event.data);
        this.handleSocketMessage(message);
      });

      this.socket.addEventListener('close', (event) => {
        this.socket = undefined;
        // setTimeout(this.connectToSocket, 1000);
      });
    }
  }

  handleSocketMessage = (message) => {
    if (message.message) {
      message = JSON.parse(message.message);
    }  
    if (message.content?.status==="success") {
      this.setState({requestStatus: ""})
    } else if (message.content.status==="failure") {
      this.setState({requestStatus: "Error. "+message.content.message})
    }
    if (message.type==="getOwnDashboardInfoResponse" && message.content?.status==="success") {
      this.setState({
        myPlayerData: {...this.state.myPlayerData, ...message.content.user},
        games: {...this.state.games, ...message.content.games},
        friends: {...this.state.friends, ...message.content.friends},
      })
    }
    if (message.type==="updateOwnUserResponse" && message.content?.status==="success") {
      // set myPlayerData to updatedPlayerData and set updatedPlayerData to empty strings
      this.setState(prevState=>{
        return {
          myPlayerData: {
            ...prevState.myPlayerData,
            name: prevState.updatedPlayerData.name,
            email: prevState.updatedPlayerData.email,
          },
          updatedPlayerData: {
            name: "",
            email: "",
            password: "",
            confirmPassword: "",
          }
        }
      })
    }
    if (message.type==="gmInviteUserResponse" && message.content?.status==="success") {
      // add player to list
      const {gameId, userId, user} = message.content
      this.setState({games: {
        ...this.state.games, [gameId]: {
          ...this.state.games[gameId], users: {
            ...this.state.games[gameId].users, [userId]: user
          }
        }
      }})
    }
    if (message.type==="gmKickUserResponse" && message.content?.status==="success") {
      // remove player from list
      const {gameId, userId} = message.content
      const users = {...this.state.games[gameId].users}
      delete users[userId]
      this.setState({games: {
        ...this.state.games, [gameId]: {
          ...this.state.games[gameId], users
        }
      }})
    }
    if (message.type==="addFriendResponse" && message.content?.status==="success") {
      // add player to list
      const {friendId, friend} = message.content
      this.setState({friends: {
        ...this.state.friends, [friendId]: friend
      }, addFriendEmail: ""})
    }
    if (message.type==="removeFriendResponse" && message.content?.status==="success") {
      // remove player from list
      const {friendId} = message.content
      const friends = {...this.state.friends}
      delete friends[friendId]
      this.setState({friends})
    }
    if (message.type==="createGameResponse" && message.content?.status==="success") {
      // add game to list
      const {gameId, game} = message.content
      this.setState({games: {
        ...this.state.games, [gameId]: game
      }, createGameId: ""})
    }
    if (message.type==="deleteGameResponse" && message.content?.status==="success") {
      // remove game from list
      const {gameId} = message.content
      const games = {...this.state.games}
      delete games[gameId]
      this.setState({games})
    }
    if (message.type==="copyGameResponse" && message.content?.status==="success") {
      // add game to list
      const {newGameId, newGame} = message.content
      this.setState({games: {
        ...this.state.games, [newGameId]: newGame
      }})
    }

    let responseIndex = this.awaitingSocketResponses.indexOf(message.type)
    if (responseIndex !== -1) {
      this.awaitingSocketResponses.splice(responseIndex, 1)
      if (this.awaitingSocketResponses.length === 0) {
        this.closeSocket();
      }
    }
  }

  sendSocketMessage = (message) => {
    //console.log("SENDING", message)
    this.socket?.send(JSON.stringify({
      gameName: 'splintered_symmetry',
      password: "0jf,Jg39*%gD9k",
      message: JSON.stringify(message)
    }));
  }

  closeSocket = () => {
    this.socket?.close();
    this.socket = undefined;
  }

  newDialogue = (dialogue) => {
    this.setState({queuedDialogues: [...this.state.queuedDialogues, dialogue]})
  }

  dialogueResult = (result) => {
    let resultFunction = this.state.queuedDialogues[0]?.resultFunction
    this.dialogueResultFunction = ()=>resultFunction(result) // this gets called on the next state update
    this.setState({queuedDialogues: this.state.queuedDialogues.slice(1)})
  }

  cancelDialogue = (result) => {
    this.setState({queuedDialogues: this.state.queuedDialogues.slice(1)})
  }
  
  addFriend = (friendEmail) => {
    this.queueSocketMessage({type: "addFriend", content: {frienderId: this.state.myPlayerData.id, friendEmail}}, "addFriendResponse")
  }

  removeFriend = (friendId) => {
    this.queueSocketMessage({type: "removeFriend", content: {frienderId: this.state.myPlayerData.id, friendId}}, "removeFriendResponse")
  }

  createGame = (gameId) => {
    this.queueSocketMessage({type: "createGame", content: {gmId: this.state.myPlayerData.id, gameId}}, "createGameResponse")
  }

  inviteFriendToGame = (gameId,friendId) => {
    this.queueSocketMessage({type: "gmInviteUser", content: {gameId, userId: friendId}}, "gmInviteUserResponse")
  }

  kickPlayerFromGame = (gameId,playerId) => {
    this.queueSocketMessage({type: "gmKickUser", content: {gameId, userId: playerId}}, "gmKickUserResponse")
  }

  launchGame = (gameId) => {
    window.location.replace(`/play?game=${gameId}&test=1`)
  }

  copyGame = (gameId) => {
    this.queueSocketMessage({type: "copyGame", content: {oldGameId: gameId, gmId: this.state.myPlayerData.id, gameId}}, "copyGameResponse")
  }

  deleteGameDialogue = (gameId) => {
    this.newDialogue({
      title: "Confirm Game Delete: " + gameId,
      questions: {
        confirm: {
          name:"Are You Sure?",
          inputType:"dropdown",
          default:false,
          options:[{name:"No",value:false},{name:"Yes",value:true}]
        }
      },
      resultFunction: (result) => {
        if(result.confirm) this.confirmDeleteGame(gameId);
      }
    });
  }

  confirmDeleteGame = (gameId) => {
    this.queueSocketMessage({type: "deleteGame", content: {gameId}}, "deleteGameResponse")
  }

  render() {
    const nameValid = true
    const emailValid = this.state.updatedPlayerData.email?.length ===0 || /^[^@]+@[^@]+\.[^@]+$/.test(this.state.updatedPlayerData.email)
    const passwordValid = true
    const confirmPasswordValid = this.state.updatedPlayerData.confirmPassword===this.state.updatedPlayerData.password
    const valid = nameValid && emailValid && passwordValid && confirmPasswordValid
    const numberOfUpdatedFields = Object.entries(this.state.updatedPlayerData).filter(([key, value])=>key!=="confirmPassword"&&value.length>=1).length

    let friendsHTML = [];
    Object.values(this.state.friends).forEach((friend) => {
      friendsHTML.push(<div className="friend" key={friend.id}>
        <div className="displayName">{friend.name}</div>
        <button className="removeFriend warning" onClick={(e) => this.removeFriend(friend.id)}>Remove</button>
      </div>
      );
    });

    let gamesHTML = [];
    Object.values(this.state.games).forEach((game) => {
      let isGM = (this.state.myPlayerData.id === game.gmId);
      let playersHTML = [];
      Object.values(game.users).forEach((player) => {
        playersHTML.push(<div className="player" key={player.id}>
            <div className="playerName">{player.name}</div>
            {isGM && player.id !== this.state.myPlayerData.id && <button className="kickPlayer warning" onClick={(e) => this.kickPlayerFromGame(game.id,player.id)}>Kick</button>}
          </div>);
      });
      if(isGM) playersHTML.push(<div className="invite" key="inviteButton">
        <select className="inviteButton" value={false} onChange={(e) => this.inviteFriendToGame(game.id, e.target.value)}>
          <option value={false}>Invite Friend to Game</option>
          {Object.values(this.state.friends).filter(friend=>(
            !Object.values(game.users).find(player=>player.id===friend.id)
          )).map(friend=>(
            <option key={friend.id} value={friend.id}>{friend.name}</option>
          ))}
        </select>
      </div>)
      gamesHTML.push(<div className="gameContainer" key={game.id}>
        <div className="game">
          <div className="gameName">{game.id}</div>
          <div className="gm">{game.users[game.gmId]?.name}</div>
          <div className="players">{playersHTML}</div>
          <div className="creationDate">{game.createdAt.replace("T", " ").replace(":00.000Z", "")}</div>
          <div className="lastAccess">{game.accessedAt.replace("T", " ").replace(":00.000Z", "")}</div>
        </div>
        <div className="gameButtonContainer">
          <button className="gameButton" onClick={(e) => this.launchGame(game.id)}>Launch Game</button>
          {isGM && <button className="gameButton" onClick={(e) => this.copyGame(game.id)}>Copy Game</button>}
          {isGM && <button className="gameButton warning" onClick={(e) => this.deleteGameDialogue(game.id)}>Delete Game</button>}
        </div>
      </div>);
    });

    return(
      <div className="container">
        <Helmet>
          <title>Dashboard</title>
        </Helmet>
        Welcome, {this.state.myPlayerData.name}, to the Dashboard!
        <div className="settings">
          <h3>Settings</h3>
          <div className="darkmode">
            <label htmlFor='darkMode'>Dark Mode</label>
            <input type='checkbox' name='darkMode' checked={!!this.state.darkMode} onChange={(e)=>{
              this.setState({darkMode:e.target.checked})
              document.body.classList.toggle("darkmode",e.target.checked)
              localStorage.setItem('darkMode',e.target.checked);
            }} />
          </div>
        </div>
        <div className="friendsList">
          <h3>My Friends</h3>
          {friendsHTML}
          <div className="addFriendButton">
            <input type="text" value={this.state.addFriendEmail} placeholder="Friend's email" onChange={(e)=>this.setState({addFriendEmail: e.target.value.toLowerCase()})} />
            <button className="addFriend" onClick={(e) => this.addFriend(this.state.addFriendEmail)}>Add Friend</button>
          </div>
        </div>
        <div className="gameList">
          <h3>My Games</h3>
          <div>
            <input type="text" value={this.state.createGameId} placeholder="Game id" onChange={(e)=>this.setState({createGameId: e.target.value})} />
            <button className="createGame" onClick={(e) => this.createGame(this.state.createGameId)}>Create New Game</button>
          </div>
          <div className="headers">
            <div className="gameName">Game Id</div>
            <div className="gm">GM</div>
            <div className="players">Players</div>
            <div className="creationDate">Created</div>
            <div className="lastAccess">Last Access</div>
          </div>
          {gamesHTML}
        </div>
        <div className="account">
          <h3>My Account</h3>
          <div>Name: {this.state.myPlayerData.name}</div>
          <div>Email Address: {this.state.myPlayerData.email}</div>

          <h3>Update Fields</h3>
          <div className="displayName">
            New Display Name: <input type="text" value={this.state.updatedPlayerData.name} onChange={(e) => this.setState({updatedPlayerData: {...this.state.updatedPlayerData, name:e.target.value}})} />
          </div>
          <div className={"email" + (emailValid ? "" : " invalid")}>
            New Email Address: <input type="text" value={this.state.updatedPlayerData.email} onChange={(e) => this.setState({updatedPlayerData: {...this.state.updatedPlayerData, email:e.target.value.toLowerCase()}})} />
            {!emailValid && (
              <>
                <br/>
                Invalid. Email must meet these requirements: valid email
              </>
            )}
          </div>
          <div className="password">
            New Password: <input type="password" value={this.state.updatedPlayerData.password} onChange={(e) => this.setState({updatedPlayerData: {...this.state.updatedPlayerData, password:e.target.value}})} />
          </div>
          <div className={"password" + (confirmPasswordValid ? "" : " invalid")}>
            Confirm New Password: <input type="password" value={this.state.updatedPlayerData.confirmPassword} onChange={(e) => this.setState({updatedPlayerData: {...this.state.updatedPlayerData, confirmPassword:e.target.value}})} />
            {!confirmPasswordValid && (
              <>
                <br/>
                Invalid. Confirm Password must meet these requirements: matches password
              </>
            )}
          </div>
          <button className="" onClick={valid ? this.updatePlayerInfo : null} disabled={!valid}>Update {numberOfUpdatedFields} Fields</button>
        </div>
        {this.state.requestStatus}
        <Dialogue 
          title={this.state.queuedDialogues[0]?.title || ""} 
          questions={this.state.queuedDialogues[0]?.questions} 
          resultFunction={this.dialogueResult} 
          cancelDialogue={this.cancelDialogue} 
        />
      </div>  
    );
  }
}
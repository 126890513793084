import React from 'react';
import Footer from './Footer';
import Nav from './Nav';
import './main.css';
import solarEclipse from './images/Solar_Eclipse.jpg';
import studentOfMagic from './images/K0007_Student_of_Magic.png';
import wasp from './images/PW0002_Wasp.png';
import soldierKneeling from './images/PW0006_Soldier_Kneeling.png';
import scorpion from './images/PW0003_Scorpion.png';
import { Helmet } from 'react-helmet';


export default class Home extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount = () => {
      if (window.innerWidth > 600) {
        this.zoom = 80
      } else {
        this.zoom = 100
      }
      
      this.margin = 50 - (this.zoom /2)
      var newStyleSheet = document.createElement('style')
      document.head.appendChild(newStyleSheet);
      var sheet = newStyleSheet.sheet;
      sheet.insertRule(`
        .banner-panel:not(:only-child) {
        width: ${this.zoom}%;
        }
      `, 0);
      sheet.insertRule(`
        .banner-panel:nth-last-child(1):not(:only-child){
        left: ${this.margin - this.zoom}%;
        }
      `, 0);
      sheet.insertRule(`
        .banner-panel:nth-child(1):not(:only-child){
        left: ${this.margin}%;
        }
      `, 0);
      sheet.insertRule(`
        .banner-panel:nth-child(2):not(:only-child){
        left: ${this.margin + this.zoom}%;
        }
      `, 0);
      sheet.insertRule(`
        .banner-panel:not(:nth-last-child(1)):not(:nth-child(1)):not(:nth-child(2)){
        left: 100%;
        }
      `, 0);
      this.panels = []
      this.position = this.margin
      this.time = 60 * 2
      this.speed = 0
      this.backwards = false
      this.slowing = false
      this.lastPosition = this.position
      this.pause = 0
    document.querySelectorAll(`.banner-panel`).forEach(panel=>{
        this.panels.push(panel)
    })
    if (this.panels.length > 1) {
        this.position -= this.zoom * this.panels.length
        this.drawPanels()
        this.mainInterval = setInterval(()=>{
            if (this.pause <= 0) {
                this.time++
            }
            // Every 10 seconds it repeats
            if (this.time < 60 * 10) {
                if (this.pause > 0) {
                this.pause--
                }
                return
            } else if (Math.abs(this.position - this.lastPosition) <= this.zoom / 2) {
                this.speed += 0.1
            } else {
                if (!this.slowing) {
                this.slowing = true
                if (this.zoom < 100) {
                    this.speed += 0.05
                } else {
                    this.speed -= 0.1
                }
                }
                this.speed -= 0.1
                if (this.speed <= 0) {
                this.speed = 0
                this.time = 0
                this.backwards = false
                this.slowing = false
                this.position = (Math.round((this.position - this.margin) / this.zoom) * this.zoom) + this.margin
                this.lastPosition = this.position
                this.drawPanels()
                }
            }
            if (this.backwards) {
                this.position += this.speed
            } else {
                this.position -= this.speed
            }
            if (this.speed !== 0) {
                this.drawPanels()
            }
        }, 1000 / 60)
      }
      
    }

    drawPanels = () => {
      var currentPanel = 0
      this.panels.forEach(panel=>{
          var panelPosition = this.position + (currentPanel * this.zoom)
          while (panelPosition + (this.zoom * 2) > 0) {
          panelPosition -= this.zoom * this.panels.length
          }
          while (panelPosition - (this.zoom * 2) < -this.zoom * this.panels.length) {
          panelPosition += this.zoom * this.panels.length
          }
          panel.style.left = `${panelPosition}%`
          currentPanel++
      })
    }
    
    bannerLeft = () => {
      if (this.time < 60 * 10) {
          this.time = 60 * 10
          this.backwards = true
          this.pause = 60 * 100
      }
    }
    
    bannerRight = () => {
      if (this.time < 60 * 10) {
          this.time = 60 * 10
          this.backwards = false
          this.pause = 60 * 100
      }
    }

  render () {
    return (
        <>
            <Nav/>
            <Helmet>
                <title>Home | Splintered Symmetry</title>
                <link rel="shortcut icon" href="./images/SS Icon.png" type="image/x-icon" />
                <meta name="description" content="Splintered Symmetry is a tabletop Indie RPG in development since 2017. The main campaign is set in an alternative Earth in 2019, but takes the
                players on an epic cross-genre journey as they connect with other Splinters of reality. With unique gameplay and story elements, it promises to
                provide a refreshing experience for new and veteran gamers." />
            </Helmet>
            <main>
                <div className="rotating-banner">
                    <a className="banner-panel" href="./events/">
                    <div className="banner-image" style={{backgroundImage: `url(${wasp})`}}></div>
                    <div className="banner-text">Events</div>
                    </a>
                    <a className="banner-panel" href="./gameplay/">
                    <div className="banner-image" style={{backgroundImage: `url(${soldierKneeling})`, backgroundPosition: "center 30%"}}></div>
                    <div className="banner-text">Gameplay</div>
                    </a>
                    <a className="banner-panel" href="./world/">
                    <div className="banner-image" style={{backgroundImage: `url(${scorpion})`}}></div>
                    <div className="banner-text">World</div>
                    </a>
                    <button className="banner-arrow" id="left-banner-arrow" onClick={this.bannerLeft}>&#10147;</button>
                    <button className="banner-arrow" id="right-banner-arrow" onClick={this.bannerRight}>&#10147;</button>
                </div>
                    <div className="img-text-wrap reverse-on-big">    
                    <p className="half-width">Splintered Symmetry is a tabletop Indie RPG in development since 2017. The main campaign is set in an alternative Earth in 2019, but takes the
                        players on an epic cross-genre journey as they connect with other Splinters of reality. With unique gameplay and story elements, it promises to
                        provide a refreshing experience for new and veteran gamers.</p>
                        
                    <img className="paragraph-img half-width" src={solarEclipse} alt="A Solar Eclipse" />
                    </div>

                    <div className="img-text-wrap">
                    <p className="half-width">Splintered Symmetry is written by Thomas Henage, a physicist, engineer, and lifelong gamer. We are currently in the beta-testing stage,
                    with an official public released anticipated in 2025. We are also developing a custom site for a gaming aid and to facilitate remote gaming sessions.</p>
                        
                        <img className="paragraph-img half-width" src={studentOfMagic} alt="A Man Studying at a Desk" />
                    </div>

                <p>Thomas and his son started Elder Chicken Games in 2021. Elder Chicken Games is a small family run operation established to showcase their
                    creations, including some casual browser games, which you can find <a href="https://elderchicken.com/games/">here</a>.</p>

                </main>
            <Footer />
        </>
    )
  }
}
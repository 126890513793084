import {cap} from './Character';
import {copyObject} from './Game';

// this is necessary for legacy characters or if the names or order of aspects ever change (again)
export const aspectOrder = ["brawn","toughness","agility","reflex","awareness","intelligence","serenity","impression"];

export function Aspects(props) {

    let myAspectsHTML = [];
    aspectOrder.forEach(aspect => {
        let myPipsHTML = [];
        for(let i=0; i<props.channelAmountForAspect(aspect); i++) {
          myPipsHTML.push(<div className="pip" key={i}></div>);
        }
        myAspectsHTML.push(<div key={aspect} className="aspect-container">
            <label className="aspect">{cap(aspect)}</label>
            <div className="pip-container">{myPipsHTML}</div>
            <div className="aspect-total">{props.getAspect(aspect)}</div> =
            <input type="number" disabled={!props.editMode} value={props.aspects[aspect].base}
                onChange={(e) => updateBaseAspect(e,aspect)}/> + 
            <input type="number" value={props.aspects[aspect].bonus}
                onChange={(e) => updateBonusAspect(e,aspect)}/>
            {!props.editMode && (<button onClick={() => props.testAspect(aspect)} className={aspect + " aspect"}></button>)}
            </div>);
    });

    function updateBaseAspect(e,aspect) {
        let newState = copyObject(props.aspects);
        newState[aspect].base = Math.min(props.getRankLimit,Math.max(0,Number(e.target.value)));
        props.updateAspects(newState);
    }

    function updateBonusAspect(e,aspect) {
        let newState = copyObject(props.aspects);
        newState[aspect].bonus = Math.min(props.getRankLimit,Math.max(0,Number(e.target.value)));
        props.updateAspects(newState);
    }

    return(
        <div className="aspects container">
            <div className="sub-header">---Aspects---</div>
            <label className="aspect"></label>
            <label className="current">Current</label> = 
            <label className="base">Base</label> + 
            <label className="bonus">Bonus</label>
            {myAspectsHTML}
        </div>
    );
}